import flatten from 'lodash/flatten';
import { getColumnFilteredValue, getColumnSortOrder } from '../../../components/table/columnFormatHelpers';
import { ColumnsType } from 'antd/lib/table';
import { ITrackingAlert, ITrackingUser } from 'src/domain/event/interface';
import { compareWithLocale } from 'src/domain/compareWithLocale';

export const getUtilityFilterValue = (state: any) => {
    let { filteredValue } = getColumnFilteredValue('user_ids', state?.filter || {});
    if (Array.isArray(filteredValue)) {
        filteredValue = flatten(filteredValue).map(utilityName => utilityName.toString());
    }
    return filteredValue;
};

export function buildTrackingUsersTableFilter(state: any, users: ITrackingUser[]) {
    const column: ColumnsType<ITrackingAlert>[number] = {
        title: 'User',
        dataIndex: 'user_ids',
        sorter: (a, b) => compareWithLocale(a.user?.fname?.concat(' ').concat(a.user?.lname), b.user?.fname?.concat(' ').concat(b.user?.lname)),
        ...getColumnSortOrder('user_ids', state.sorter),
        filters: users.map(user => ({ text: user.text, value: user.value })),
        filteredValue: getUtilityFilterValue(state),
        filterSearch: (input, record) => !!record?.text?.toString()?.toLowerCase().includes(input.toLowerCase()),
        render: (text, record) => `${record.user?.fname} ${record.user?.lname}`,
    };

    return column;
}
