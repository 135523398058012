import Table from 'antd/lib/table';
import { ColumnsType } from 'antd/lib/table/interface';

interface IRecord {
    field: string;
    required: string;
    description?: string;
}

export function ProgramToProductRelationImportFormatDescription() {
    const records: IRecord[] = [
        {
            field: 'Salesforce Product ID*',
            required: 'Yes',
            description: 'Utility identifier of the Product in Salesforce',
        },
        {
            field: 'Program ID*',
            required: 'Yes',
            description: 'Utility identifier of the Program in DRMS',
        },
        {
            field: 'Program Name',
            required: 'Optional',
            description: 'Name of the Program in DRMS',
        },
        {
            field: 'Salesforce Product Name',
            required: 'Optional',
            description: 'Name of the Product in Salesforce',
        },
    ];

    const columns: ColumnsType<IRecord> = [
        {
            title: 'Field Name',
            dataIndex: 'field',
            width: 220,
            ellipsis: true,
        },
        {
            title: 'Required',
            dataIndex: 'required',
            width: 150,
            ellipsis: true,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            ellipsis: true,
        },
    ];
    return (
        <Table
            rowKey="field"
            size="small"
            scroll={{ x: 'scroll' }}
            sticky
            title={() => 'CSV format description'}
            columns={columns}
            dataSource={records}
            pagination={false}
        ></Table>
    );
}
