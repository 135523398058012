import { ICreateSite, ISite, IUpdateSite } from '../../domain/site/interface';
import { minutesToHours, timeToMinutes } from '../../domain/common/timeHelpers';
import { Actor } from '../../domain/auth/actor';

export interface ISiteFormData {
    site_id?: number;
    company_id: number;
    site_name: string;
    site_address: string;
    site_city: string;
    site_state: string;
    site_zip: string;
    site_country: string;
    site_county: string | null;
    site_lat: number;
    site_long: number;
    site_timezone: string | null;
    google_place_id: string | null;
    site_store_number: string | null;
    site_market_and_zone: string[] | undefined;
    site_utility: string | null;
    site_network: string | null;
    site_resource_id: string | null;
    partner_id: string | null;
    control_provider_id: number | null;
    utility_customer_id: number | null;
    site_estimated_kw: number | null;
    event_max_duration: number | null;
    event_offset: number | null;
    site_highlight: boolean;
    email_only_integration: boolean;
    site_label: string[];
    meter_ids: string[];
}

export class SiteFormData {
    static fromEntity(site: ISite | Partial<ISite>): ISiteFormData {
        return {
            site_id: site.site_id,
            company_id: site.company_id!,
            site_name: site.site_name!,
            site_address: site.site_address!,
            site_city: site.site_city!,
            site_state: site.site_state!,
            site_zip: site.site_zip!,
            site_country: site.site_country!,
            site_county: site.site_county || null,
            site_lat: site.site_lat!,
            site_long: site.site_long!,
            site_timezone: site.site_timezone || null,
            google_place_id: site.google_place_id || null,
            site_store_number: site.site_store_number || null,
            site_market_and_zone: getInitialSiteMarketZone(site), // ['market', 'zone']
            site_utility: site.site_utility || null,
            site_network: site.site_network || null,
            site_resource_id: site.site_resource_id || null,
            partner_id: site.partner_id || null,
            control_provider_id: site.control_provider_id || null,
            utility_customer_id: site.utility_customer_id || null,
            site_estimated_kw: site.site_estimated_kw || 0,
            event_max_duration: site.event_max_duration ? minutesToHours(site.event_max_duration) : 0,
            event_offset: site.event_offset || 0,
            site_highlight: site.site_highlight || false,
            email_only_integration: site.email_only_integration || false,
            site_label: site.site_label || [],
            meter_ids: site.meters?.map(m => m.salesforce_id) || [],
        };
    }

    static toEntity(siteFormData: ISiteFormData, user: Actor): ICreateSite | IUpdateSite {
        return {
            site_id: siteFormData.site_id,
            ...(user.isCustomer() ? { company_id: user?.company_id } : { company_id: siteFormData.company_id }),
            site_name: siteFormData.site_name,
            site_address: siteFormData.site_address,
            site_city: siteFormData.site_city,
            site_state: siteFormData.site_state,
            site_zip: siteFormData.site_zip,
            site_country: siteFormData.site_country,
            site_county: siteFormData.site_county,
            site_lat: siteFormData.site_lat,
            site_long: siteFormData.site_long,
            site_timezone: siteFormData.site_timezone,
            google_place_id: siteFormData.google_place_id,
            site_store_number: siteFormData.site_store_number,
            lmp_market: siteFormData.site_market_and_zone?.[0] || undefined,
            site_load_zone: siteFormData.site_market_and_zone?.[1] || undefined,
            event_max_duration: siteFormData.event_max_duration ? timeToMinutes(siteFormData.event_max_duration) : undefined,
            site_utility: siteFormData.site_utility || undefined,
            site_network: siteFormData.site_network || undefined,
            site_resource_id: siteFormData.site_resource_id || undefined,
            partner_id: siteFormData.partner_id,
            control_provider_id: siteFormData.control_provider_id,
            utility_customer_id: siteFormData.utility_customer_id,
            site_estimated_kw: siteFormData.site_estimated_kw || undefined,
            event_offset: siteFormData.event_offset || 0,
            site_highlight: siteFormData.site_highlight,
            email_only_integration: siteFormData.email_only_integration,
            site_label: siteFormData.site_label,
            meter_ids: siteFormData.meter_ids || [],
        };
    }
}

function getInitialSiteMarketZone(site: ISite | Partial<ISite>) {
    if (!site?.lmp_market || !site?.site_load_zone) {
        return undefined;
    }

    return [site.lmp_market.toUpperCase(), site.site_load_zone.toUpperCase()];
}
