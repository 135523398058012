import React from 'react';
import { Checkbox } from 'antd';
import { DndContext, closestCenter, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, arrayMove, useSortable, horizontalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ISidebarFilterAndOptionsProps } from '../context/SidebarContext';
import { useColumns } from '../../../layouts/private/context/ColumnsContext';
import { HolderOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import './sidebarGridFilter.css';

const SortableItem: React.FC<{ id: string; children: React.ReactNode }> = ({ id, children }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id,
    });
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        display: 'flex',
        alignItems: 'center',
        marginBottom: 8,
    };
    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
            {children}
        </div>
    );
};
interface ISidebarGridFilter {
    rootPage: string;
    filters: ISidebarFilterAndOptionsProps[];
    options: ISidebarFilterAndOptionsProps[];
    clearGridSettings: () => void;
}
const SidebarGridFilter: React.FC<ISidebarGridFilter> = ({ rootPage, filters, options, clearGridSettings }: ISidebarGridFilter) => {
    const { columns, setColumns } = useColumns();
    const toggleColumnVisibility = (key: string) => {
        setColumns(prev => prev.map(col => (col.key === key ? { ...col, hidden: !col.hidden } : col)));
    };
    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;
        if (over && active.id !== over.id) {
            const oldIndex = columns.findIndex(col => col.key === active.id);
            const newIndex = columns.findIndex(col => col.key === over.id);
            setColumns(arrayMove(columns, oldIndex, newIndex));
        }
    };
    const filteredColumns = columns.filter(col => col.key !== 'action');
    return (
        <div className="sidebar-grid-filter">
            <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                <SortableContext
                    items={filteredColumns.map(col => col.key).filter((key): key is string => key !== undefined)}
                    strategy={horizontalListSortingStrategy}
                >
                    {filteredColumns.map(col => (
                        <div key={col.key} className="sortable-container">
                            <SortableItem id={col.key!.toString()}>
                                <>
                                    <HolderOutlined />
                                    <span style={{ color: col.hidden ? '#BBC3C3' : 'inherit' }}>
                                        {typeof col.title === 'function' ? col.title({}) : col.title}
                                    </span>
                                </>
                            </SortableItem>
                            <Checkbox checked={!col.hidden} onChange={() => toggleColumnVisibility(col.key!.toString())} className="custom-checkbox">
                                {col.hidden ? <EyeInvisibleOutlined style={{ color: '#BBC3C3' }} /> : <EyeOutlined />}
                            </Checkbox>
                        </div>
                    ))}
                </SortableContext>
            </DndContext>
            <button onClick={clearGridSettings} className="grid-reset-btn">
                Reset
            </button>
        </div>
    );
};

export default SidebarGridFilter;
