import { TableColumnsType, TableColumnType } from 'antd/lib';
import App from 'antd/lib/app';
import Button from 'antd/lib/button';
import Table from 'antd/lib/table';
import Typography from 'antd/lib/typography';
import { format } from 'date-fns';
import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReadingsAvailabilityStatsByCompanyTableTitle } from '../../components/readings-availability-stats/ReadingsAvailabilityStatsByCompanyTableTitle';
import { buildCompanyFilter, getColumnFilteredValue, getColumnSortOrder } from '../../components/table/columnFormatHelpers';
import { WithEmptyDataTable } from '../../components/table/withEmptyDataTable';
import { DEFAULT_PAGINATION } from '../../domain/commonConst';
import { useCompanyListQuery } from '../../domain/company/queries';
import {
    buildMonthlyReadingsAvailabilityFilter,
    TKeyofDataAvailabilityPercentagesMap,
} from '../../domain/readings-availability-stats/buildMonthlyReadingsAvailabilityFilter';
import { getReadingsStatsCellColor } from '../../domain/readings-availability-stats/getReadingsStatsCellColor';
import { DataQualityReportPeriod, IGroupedReadingsAvailabilityStat } from '../../domain/readings-availability-stats/interface';
import { useGroupedReadingsAvailabilityStatListQuery } from '../../domain/readings-availability-stats/queries';
import { useReadingsAvailabilityStatsContext } from '../../domain/readings-availability-stats/ReadingsAvailabilityStatsContext';
import {
    filterReadingsAvailabilityStatsByCompany,
    filterReadingsAvailabilityStatsByMonthPercentRange,
} from '../../domain/readings-availability-stats/tableHelpers';
import { toUsefulParams } from '../toUsefulParams';
import { usePageLocation } from '../usePageState';

interface IPageState {
    period?: DataQualityReportPeriod;
    pagination?: {
        pageSize: number;
        current: number;
    };
    filter?: {
        companyId?: string | string[];
        stats?: {
            [key: string]: string | string[];
        };
    };
    sorter?: {
        field: string;
        order: 'ascend' | 'descend';
    };
}

export const ReadingsAvailabilityStatsByCompany = () => {
    const { notification } = App.useApp();
    const location = useLocation();
    const { setPageQuery, queryToState } = usePageLocation({ parseNumbers: false });
    const pageState: IPageState = queryToState(location.search);
    const period = pageState.period || DataQualityReportPeriod.fullday;
    const { startDate, endDate } = useReadingsAvailabilityStatsContext();

    const {
        data = [],
        isLoading,
        isFetching,
        isError,
        error,
    } = useGroupedReadingsAvailabilityStatListQuery({
        startDate,
        endDate,
        period,
        groupedByCompany: true,
    });

    if (isError) {
        notification.error({
            key: 'fetch-readings-availability-stats',
            message: error.message || 'Cannot fetch Data Quality Report!',
        });
    }

    const updatedAt = data?.[0]?.updatedAt || null;

    const columns = useGetTableColumns(pageState);

    const readingsAvailabilityStats = useMemo(() => {
        let filteredData = data;
        let filter = pageState.filter || {};

        if (filter.companyId) {
            filteredData = filterReadingsAvailabilityStatsByCompany(filteredData, filter.companyId);
        }

        if (filter.stats) {
            Object.entries(filter.stats).forEach(([monthKey, rangeKey]) => {
                filteredData = filterReadingsAvailabilityStatsByMonthPercentRange(
                    filteredData,
                    monthKey,
                    rangeKey as TKeyofDataAvailabilityPercentagesMap | TKeyofDataAvailabilityPercentagesMap[]
                );
            });
        }

        return filteredData;
    }, [data, pageState]);

    async function onTableChange(pagination: any, filter: any, sorter: any) {
        const preparedParams = toUsefulParams({ pagination, filter, sorter });

        setPageQuery({ ...pageState, ...preparedParams });
    }

    return (
        <>
            <ReadingsAvailabilityStatsByCompanyTableTitle period={period} updatedAt={updatedAt} />

            <WithEmptyDataTable condition={isLoading}>
                <Table<IGroupedReadingsAvailabilityStat>
                    loading={isLoading || isFetching}
                    columns={columns}
                    rowKey={record => record.companyId}
                    scroll={{ x: 'max-content' }}
                    tableLayout="fixed"
                    onChange={onTableChange}
                    pagination={{
                        pageSize: pageState.pagination?.pageSize || DEFAULT_PAGINATION.pageSize,
                        current: pageState.pagination?.current || DEFAULT_PAGINATION.current,
                        total: readingsAvailabilityStats.length,
                        hideOnSinglePage: true,
                    }}
                    dataSource={readingsAvailabilityStats}
                />
            </WithEmptyDataTable>
        </>
    );
};

function useGetTableColumns(pageState: IPageState): TableColumnsType<IGroupedReadingsAvailabilityStat> {
    const navigate = useNavigate();
    const { months } = useReadingsAvailabilityStatsContext();
    const period = pageState.period || DataQualityReportPeriod.fullday;
    const companyListQuery = useCompanyListQuery({});
    const companies = companyListQuery.data?.data || [];

    return [
        {
            title: 'Company Name',
            dataIndex: 'companyId',
            key: 'companyId',
            fixed: 'left',
            width: 220,
            render: (value: string, record) => (
                <Typography.Text ellipsis={{ tooltip: true }} style={{ maxWidth: '220px' }}>
                    {record.companyName}
                </Typography.Text>
            ),
            filterMultiple: true,
            filterSearch: true,
            filters: buildCompanyFilter(companies),
            ...getColumnFilteredValue('companyId', pageState.filter),
            sorter: (a, b) => b.companyName.toLocaleLowerCase().localeCompare(a.companyName.toLocaleLowerCase()),
            ...getColumnSortOrder('companyId', pageState.sorter),
        },

        ...months.map<TableColumnType<IGroupedReadingsAvailabilityStat>>(month => {
            const monthKey = format(month, 'yyyy-MM-01');
            const monthTitle = format(month, 'MMM-yyyy');

            return {
                title: monthTitle,
                key: `stats.${monthKey}`,
                align: 'center',

                filters: buildMonthlyReadingsAvailabilityFilter(),
                filterMultiple: true,
                ...getColumnFilteredValue(`stats.${monthKey}`, pageState.filter),

                dataIndex: `stats.${monthKey}`,
                sorter: (a, b) => a.stats[monthKey] - b.stats[monthKey],
                ...getColumnSortOrder(`stats.${monthKey}`, pageState.sorter),

                render: (_, record) => record.stats[monthKey] || '0',
                onCell: record => ({
                    style: {
                        fontSize: '0.8rem',
                        fontWeight: 'bold',
                        background: getReadingsStatsCellColor(record.stats[monthKey]),
                    },
                }),
            };
        }),
        {
            title: null,
            dataIndex: 'actions',
            key: 'actions',
            align: 'center',
            fixed: 'right',
            width: 100,
            render: (_, record) => (
                <Button
                    size="small"
                    type="link"
                    onClick={() => navigate(`/admin/data-quality/by-enrollment?companyId=${record.companyId}&period=${period}`)}
                >
                    Details
                </Button>
            ),
        },
    ];
}
