import React from 'react';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import Space from 'antd/lib/space';
import Typography from 'antd/lib/typography';
import './pageHeader.css';

interface IPageHeader {
    pageTitle: string | React.ReactNode;
    subTitle?: string | React.ReactNode;
    actions?: React.ReactNode;
    extra?: React.ReactNode;
}

export const PageHeader = ({ pageTitle, subTitle, actions, extra }: IPageHeader) => {
    return (
        <Row justify="space-between" gutter={[16, subTitle ? 8 : 16]} style={{ paddingBottom: subTitle ? 16 : 24, minHeight: '65px' }} align="middle">
            <Col className="page-heading">
                {!!pageTitle && (
                    <Typography.Title level={4} style={{ margin: '0 8px 0 0' }}>
                        {pageTitle}
                    </Typography.Title>
                )}
                {extra}
            </Col>
            <Col>
                <Space wrap>{actions}</Space>
            </Col>
            {subTitle && (
                <Col span={24} className="page-heading">
                    {subTitle}
                </Col>
            )}
        </Row>
    );
};
