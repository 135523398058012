import omit from 'lodash/omit';
import { API } from '../api';
import { IPagination } from '../IPagination';
import { IBatchUpdatePriceResponseTrigger, IPriceResponseTrigger, PRICE_RESPONSE_MARKETS_DATA } from './interface';
import { format } from 'date-fns';
import { DATE_FORMAT_CSV } from '../commonConst';
import { exportCSVData } from '../common/exportCSVData';

interface IFetchPageQuery {
    pagination?: {
        pageSize?: number;
        current?: number;
    };
    sorter?: {
        field?: string;
        order?: string;
    };
    include?: string;
    companyId?: any;
    search?: string;
}

export const fetchPriceResponseTriggers = async (
    { pagination, sorter, companyId = null, include, search }: IFetchPageQuery,
    signal?: AbortSignal | null
): Promise<IPagination<IPriceResponseTrigger>> => {
    const limit = pagination?.pageSize || 10;
    const offset = (pagination?.current ? pagination.current - 1 : 0) * limit;

    if (sorter && (!sorter.field || !sorter.order)) {
        sorter = {};
    }

    const path = `/price-response-triggers?${new URLSearchParams({
        ...(sorter?.field && { sortField: sorter.field, sortOrder: sorter.order }),
        ...(companyId && { companyId }),
        ...(include && { include }),
        ...(search && { search }),
        offset: offset.toString(),
        limit: limit.toString(),
    })}`;
    const response = await API.fetch(path, {
        ...(signal && { signal }),
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();
    if (response.ok) {
        return respBody;
    }

    throw new Error(response.statusText);
};

export const deletePriceResponseTrigger = async (trigger: IPriceResponseTrigger) => {
    const response = await API.fetch(`/price-response-triggers/${trigger.id}`, {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (response.ok) {
        return;
    }

    const respBody: any = await response.json();
    throw new Error(respBody?.error?.message || `Cannot delete Price Response trigger!`);
};

export const deleteBatchPriceResponseTriggers = async (priceResponseTriggersId: number[]) => {
    const response = await API.fetch(`/price-response-triggers/remove/batch`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ priceResponseTriggersId }),
    });

    if (response.ok) {
        return;
    }

    const respBody: any = await response.json();
    throw new Error(respBody?.error?.message || `Cannot delete Price Response trigger list!`);
};

export const savePriceResponseTrigger = async (trigger: IPriceResponseTrigger) => {
    return trigger.id ? updateTrigger(trigger) : createTrigger(trigger);
};

const createTrigger = async (trigger: IPriceResponseTrigger) => {
    const response = await API.fetch(`/price-response-triggers`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(omit(trigger, ['id'])),
    });
    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot save Price Response trigger!`);
};

const updateTrigger = async (trigger: IPriceResponseTrigger) => {
    const response = await API.fetch(`/price-response-triggers/${trigger.id}`, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(omit(trigger, ['company', 'id'])),
    });
    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot save Price Response trigger!`);
};

export const updateBatchTriggers = async (priceResponseTriggersId: number[], update: IBatchUpdatePriceResponseTrigger): Promise<void> => {
    const body = { priceResponseTriggersId, update };

    const response = await API.fetch(`/price-response-triggers/batch`, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });

    if (response.ok) {
        return;
    }

    const respBody: any = await response.json();
    throw new Error(respBody?.error?.message || `Cannot update Price Response trigger list!`);
};

export const exportPriceResponseTriggers = async ({ companyId = null, search }: { companyId: any; search?: string }) => {
    const query = new URLSearchParams({
        format: 'csv',
        include: 'company',
        ...(companyId && { companyId }),
        ...(search && { search }),
    });

    const response = await API.fetch(`/price-response-triggers?${query}`, {
        headers: {
            'Content-Type': 'text/csv',
        },
    });

    const respBody: string = await response.text();

    if (response.ok) {
        const filename = `price_response_triggers_${format(new Date(), DATE_FORMAT_CSV)}.csv`;
        exportCSVData(respBody, filename);
    } else {
        const respBody: any = await response.json();
        throw new Error(respBody?.error?.message || `Cannot export data!`);
    }
};

export const exportPriceResponseTemplate = () => {
    const csvData =
        'Market,Zone,Price($),PriceType(real-time;day-ahead),Event duration(minutes in range [0-480]),Event signal(number:0-normal;1-moderate;2-high;3-special),Start Time(number in format hh:mm),End Time(number in format hh:mm),Cancel when price lower(true/false)  \n';
    exportCSVData(csvData, 'price_response_triggers-template.csv');
};

export const exportMarketZoneInfo = () => {
    let csvData = 'Market, Zone \n';
    Object.entries(PRICE_RESPONSE_MARKETS_DATA).forEach(([market, zones]) => {
        csvData += `${market}, ${zones.join(' | ')} \n`;
    });
    exportCSVData(csvData, 'market_zone_info.csv');
};

export const importPriceResponseTriggers = async (triggers: IPriceResponseTrigger[]) => {
    const response = await API.fetch('/price-response-triggers/import', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ triggers }),
    });

    if (response.ok) {
        return;
    }

    const respBody: any = await response.json();

    throw new Error(respBody?.error?.message || `Cannot import Price Response Triggers!`);
};
