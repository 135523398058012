import { Space } from 'antd';
import Progress from 'antd/lib/progress';
import { theme } from 'antd/lib';
import './ImportProgress.css';

export interface IImportProgressProps {
    className?: string;
    state: {
        imported: number;
        failed: number;
        total: number;
    };
}

export const ImportProgress = ({ state, className }: IImportProgressProps) => {
    const { token: themeColors } = theme.useToken();

    let cssClass = `import-progress ${className ? className : ''}`;

    let { imported, failed, total } = state;
    if (total === 0) return <div className={className ? className : ''}></div>;

    let progress = +(((imported + failed) / total) * 100).toFixed(1);
    let status: 'exception' | 'success' | 'active' = progress === 100 ? (total === failed ? 'exception' : 'success') : 'active';

    return (
        <Space className={cssClass}>
            <Progress percent={progress} size="small" strokeColor={themeColors.colorPrimary} status={status} />
        </Space>
    );
};
