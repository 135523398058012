import Alert from 'antd/lib/alert';
import App from 'antd/lib/app';
import Button from 'antd/lib/button';
import Form from 'antd/lib/form';
import Modal from 'antd/lib/modal';
import Space from 'antd/lib/space';
import Tag from 'antd/lib/tag';
import Typography from 'antd/lib/typography';
import { useContext, useState } from 'react';
import { attachProgramsToSitesBatch } from '../../domain/site';
import { IEnrollSitesBatchToPrograms, ISite } from '../../domain/site/interface';
import { ResourceCollapsedList } from '../../pages/events/components/ResourceCollapsedList';
import { transformSitesToResources } from '../../pages/events/components/transformSitesToResources';
import { AbilityContext } from '../ability/can';
import { ProgramSelector } from '../selectors/ProgramSelector/ProgramSelector';
import { getIconByKey } from './EnrollProgramModal';

interface IAttachProgramModal {
    sitesId: number[];
    onCancel: Function;
    selectedSites: ISite[];
}

export const AttachProgramBatchModal = ({ sitesId, onCancel, selectedSites }: IAttachProgramModal) => {
    const { notification } = App.useApp();
    const ability = useContext(AbilityContext);
    const [loading, setLoading] = useState(false);
    const [programIds, setProgramIds] = useState<(number | string)[]>([]);
    const handleCancel = () => onCancel();

    function prepareExtraProgramList(): { key: string; value: string; label: string }[] {
        const availablePrograms: { key: string; value: string; label: string }[] = [];

        if (ability.can('enroll', 'PriceResponse')) {
            availablePrograms.push({
                key: 'price-response',
                value: 'price-response',
                label: 'Price Response',
            });
        }

        if (ability.can('enroll', 'CleanResponse')) {
            availablePrograms.push({
                key: 'clean-response',
                value: 'clean-response',
                label: 'Clean Response',
            });
        }

        if (ability.can('enroll', 'LeapApi')) {
            availablePrograms.push({ key: 'leap-api-dispatch', value: 'leap-api-dispatch', label: 'Leap API' });
        }

        return availablePrograms;
    }

    const handleAttachProgram = async () => {
        setLoading(true);

        const price_response = programIds.includes('price-response') ? programIds.includes('price-response') : undefined;
        const clean_response = programIds.includes('clean-response') ? programIds.includes('clean-response') : undefined;
        const leap_api_dispatch = programIds.includes('leap-api-dispatch') ? programIds.includes('leap-api-dispatch') : undefined;
        const filteredProgramIds = programIds.filter(p => !['price-response', 'clean-response', 'leap-api-dispatch'].includes(p as any));
        const programs = filteredProgramIds.length ? filteredProgramIds.map(selectedProgramId => Number(selectedProgramId)) : undefined;

        const enrollments: IEnrollSitesBatchToPrograms = {
            sitesId,
            programs,
            price_response,
            clean_response,
            leap_api_dispatch,
        };

        try {
            await attachProgramsToSitesBatch(enrollments);
            onCancel(true);
        } catch (error: any) {
            notification.error({
                key: 'attach-programs-to-sites-error',
                message: error.message || 'Cannot attach programs!',
            });
        }
        setLoading(false);
    };

    return (
        <Modal
            title="Attach Programs"
            open
            destroyOnClose
            onCancel={handleCancel}
            footer={[
                <Button key="attach-programs-to-sites-modal-cancel" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button
                    key="attach-programs-to-sites-modal-attach"
                    type="primary"
                    loading={loading}
                    disabled={!programIds.length}
                    onClick={handleAttachProgram}
                >
                    Attach
                </Button>,
            ]}
        >
            <Space direction="vertical" style={{ width: '100%' }}>
                <Alert message="Next changes will be applied to all selected sites." type="warning" style={{ marginBottom: '8px' }} showIcon />
                <ResourceCollapsedList resources={transformSitesToResources(selectedSites)} />
                <Form name="attach-programs-to-sites-batch-form" preserve={false} layout="vertical" style={{ marginTop: '15px' }}>
                    <Form.Item label={<Typography.Text strong>Programs</Typography.Text>}>
                        <ProgramSelector
                            selectedProgram={programIds}
                            extraProgramList={prepareExtraProgramList()}
                            mode="multiple"
                            handleProgram={setProgramIds as any}
                            style={{ width: '100%' }}
                            allowClear={true}
                            tagRender={(props: any) => {
                                const isExtraProgram = ['price-response', 'clean-response', 'leap-api-dispatch'].includes(props.value);

                                return (
                                    <Tag
                                        style={{ display: 'initial' }}
                                        color={!isExtraProgram ? 'gold' : 'default'}
                                        icon={isExtraProgram && getIconByKey({ key: props.value })}
                                        closable
                                        onClose={props.onClose}
                                    >
                                        {props.label}
                                    </Tag>
                                );
                            }}
                        />
                    </Form.Item>
                </Form>
            </Space>
        </Modal>
    );
};
