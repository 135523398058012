import { useAuth } from 'src/domain/auth/useAuth';
import { COMPANY_TYPE } from 'src/domain/company/interface';
import { useCompanyQuery } from 'src/domain/company/queries';
import { Market } from 'src/domain/market-prices/interface';
import { useMarketZoneListQuery } from 'src/domain/market/queries';
import { IWeatherLocation } from 'src/domain/weather/interface';
import { useWeatherLocationsQuery } from 'src/domain/weather/queries';

type PeakLoadForecastDataState =
    | {
          isFetching: true;
          locations: null;
          markets: null;
          defaultMarket: null;
          defaultLoadZone: null;
      }
    | {
          isFetching: boolean;
          locations: IWeatherLocation[];
          markets: Record<Market, string[]>;
          defaultMarket: Market;
          defaultLoadZone: string;
      };

export const usePeakLoadForecastData = (): PeakLoadForecastDataState => {
    const auth = useAuth()!;
    const user = auth.user!;

    const marketZoneListReq = useMarketZoneListQuery();
    const companyReq = useCompanyQuery({ company_id: user.company_id });
    const weatherLocationsReq = useWeatherLocationsQuery({ keepPreviousData: true });

    const isFetching = companyReq.isFetching || marketZoneListReq.isFetching || weatherLocationsReq.isFetching;

    if (isFetching) {
        const state: PeakLoadForecastDataState = {
            isFetching: true,
            markets: null,
            locations: null,
            defaultMarket: null,
            defaultLoadZone: null,
        };

        return state;
    }

    const marketZoneList = marketZoneListReq.data;
    const company = companyReq.data;

    // for admin and account manager we set default market to ISONE
    let market: Market = Market.ISONE;
    let loadZone: string = 'CT';

    if (company?.type === COMPANY_TYPE.UTILITY_CUSTOMER && company.iso) {
        market = company.iso.toLowerCase() as Market;
    }

    const firstLoadZone = marketZoneList?.[market.toUpperCase()]?.[0];
    loadZone = firstLoadZone || '';

    const state: PeakLoadForecastDataState = {
        isFetching: false,
        markets: marketZoneListReq.data as Record<Market, string[]>,
        locations: weatherLocationsReq.data || [],
        defaultMarket: market,
        defaultLoadZone: loadZone,
    };

    console.info('usePeakLoadForecastData state', state);
    return state;
};
