import { useState } from 'react';
import InboxOutlined from '@ant-design/icons/InboxOutlined';
import Alert from 'antd/lib/alert';
import Button from 'antd/lib/button';
import Form from 'antd/lib/form';
import Modal from 'antd/lib/modal';
import Dragger from 'antd/lib/upload/Dragger';
import Row from 'antd/lib/row';
import { exportMarketZoneInfo, exportPriceResponseTemplate, importPriceResponseTriggers } from '../../../../domain/price-response';
import { IPriceResponseTrigger } from '../../../../domain/price-response/interface';
import { CsvParsingErrors } from '../../../../components/CsvParsingErrors/CsvParsingErrors';
import { parsePriceResponseTriggerCsvData } from './parsePriceResponseTriggerCsvData';
import { Upload } from 'antd/lib';
import App from 'antd/lib/app';

interface IPriceResponseFileUploadModal {
    onClose: Function;
    company: number | null;
}

const prepareErrors = (errors: any) => {
    const parsedError = JSON.parse(errors.message);
    return parsedError.map((error: any) => `In row ${error.rowIndex + 1}: ${error.error}.`);
};

const ALLOWED_FILE_TYPE = '.csv';

export const PriceResponseFileUploadModal = ({ onClose, company }: IPriceResponseFileUploadModal) => {
    const [form] = Form.useForm();
    const [file, setFile] = useState<File | null>(null);
    const [parsedValue, setParsedValue] = useState<IPriceResponseTrigger[]>([]);
    const [errors, setErrors] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const { notification } = App.useApp();

    const onFormFinish = async () => {
        if (!parsedValue.length) {
            return;
        }

        try {
            setLoading(true);
            await importPriceResponseTriggers(parsedValue);
            onClose(parsedValue);
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
            const preparedErrors = prepareErrors(error);
            preparedErrors && setErrors(preparedErrors);
        }
    };

    const parseCsv = (content: string) => {
        const [errors, result] = parsePriceResponseTriggerCsvData(content, company);
        errors.length ? setErrors(errors) : setParsedValue(result);
    };

    const uploadProps = {
        accept: ALLOWED_FILE_TYPE,
        multiple: false,
        onRemove: (file: any) => {
            console.info('uploadProps onRemove', file);
            setFile(null);
            setErrors([]);
        },
        beforeUpload: (file: File) => {
            if (!file.name.endsWith(ALLOWED_FILE_TYPE)) {
                notification.warning({
                    key: 'upload-file-type-notification',
                    message: `Please select "${ALLOWED_FILE_TYPE}" file.`,
                });
                return Upload.LIST_IGNORE;
            }
            setErrors([]);
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = (e: any) => {
                const content = e.target.result;
                parseCsv(content);
            };

            return false;
        },
        onChange: (info: any) => {
            if (info.file.status) {
                return;
            }
            setFile(info.file);
        },
        maxCount: 1,
    };

    const closeModal = () => {
        onClose();
    };

    return (
        <Modal
            open
            title="Import Price Response triggers"
            destroyOnClose
            onCancel={closeModal}
            footer={[
                <Button key="price-response-modal-cancel" onClick={closeModal}>
                    Cancel
                </Button>,
                <Button key="price-response-modal-submit" type="primary" disabled={!file && !errors.length} loading={loading} onClick={form.submit}>
                    Replace Triggers
                </Button>,
            ]}
            data-cy="import-price-response-triggers-modal"
        >
            <Form form={form} name="reporting-form" preserve={false} onFinish={onFormFinish}>
                <Alert message="All existing Price Response triggers will be replaced." type="warning" style={{ marginBottom: '8px' }} showIcon />

                <Row justify="space-around">
                    <Button type="link" onClick={exportPriceResponseTemplate} style={{ paddingLeft: 0 }}>
                        Download Template
                    </Button>

                    <Button type="link" style={{ marginBottom: '8px' }} onClick={exportMarketZoneInfo}>
                        Download Market&Zone info
                    </Button>
                </Row>

                <Dragger {...uploadProps}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">Allowed file extensions: .csv</p>
                    <p className="ant-upload-hint">You can download your current triggers or template.</p>
                </Dragger>

                <CsvParsingErrors errors={errors} />
            </Form>
        </Modal>
    );
};
