import React from 'react';
import Descriptions from 'antd/lib/descriptions';
import Typography from 'antd/lib/typography';
import { ISanInfo } from '../../domain/site/interface';
import { uniq } from 'lodash';

interface ISanDetailProps {
    sanInfo: ISanInfo;
}

export const SanDetail: React.FC<ISanDetailProps> = ({ sanInfo }) => {
    // we take first source
    const source = sanInfo.sources[0];

    const usagePointList = uniq(sanInfo.sources.filter(item => item.source === 'greenbutton').map(item => item.usagePoint));

    return (
        <Descriptions column={1}>
            <Descriptions.Item label="Customer name">{source.customerName}</Descriptions.Item>
            <Descriptions.Item label="Customer account">
                <Typography.Paragraph copyable>{source.customerAccount}</Typography.Paragraph>
            </Descriptions.Item>
            {source?.tariff && <Descriptions.Item label="Customer tariff">{source?.tariff}</Descriptions.Item>}
            {source?.serviceSupplier && (
                <>
                    <Typography.Text>Service Supplier:</Typography.Text>
                    <Descriptions.Item>
                        <Descriptions column={3}>
                            <Descriptions.Item label="LSE">{source.serviceSupplier.lse}</Descriptions.Item>
                            <Descriptions.Item label="MDMA">{source.serviceSupplier.mdma}</Descriptions.Item>
                            <Descriptions.Item label="MSP">{source.serviceSupplier.msp}</Descriptions.Item>
                        </Descriptions>
                    </Descriptions.Item>
                </>
            )}
            <Descriptions.Item label="Address">{source?.address.address}</Descriptions.Item>
            <Descriptions.Item label="Postal Code">{source?.address.postalCode}</Descriptions.Item>
            <Descriptions.Item label="City">{source?.address.city}</Descriptions.Item>
            <Descriptions.Item label="State">{source?.address.state}</Descriptions.Item>
            {usagePointList?.length && (
                <Descriptions.Item label="UUID">{usagePointList.reduce((string, item) => `${string}, ${item}`)}</Descriptions.Item>
            )}
        </Descriptions>
    );
};
