import { useContext, useMemo } from 'react';
import { ISite } from '../../../domain/site/interface';
import { AbilityContext } from '../../ability/can';

export function useCheckIfAllowedToMergeSites(selectedSites: ISite[]) {
    const ability = useContext(AbilityContext);

    return useMemo(() => {
        // Allow to merge 2-3 sites at a time, if they are from the same company
        const isAllowedToMergeCount = [2, 3].includes(selectedSites.length);
        const isSelectedSameCompany = selectedSites.every((site: ISite) => site.company_id === selectedSites[0].company_id);

        return ability.can('merge', 'Site') && isAllowedToMergeCount && isSelectedSameCompany;
    }, [selectedSites, ability]);
}
