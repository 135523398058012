import App from 'antd/lib/app';
import Button from 'antd/lib/button';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Modal from 'antd/lib/modal/Modal';
import Typography from 'antd/lib/typography';
import { IAccessPartner } from '../../domain/access-partner/interface';
import { useAccessPartnerCreate } from '../../domain/access-partner/queries';
import { ALPHANUMERIC_REGEX } from '../../domain/commonConst';

interface ICreateAccessPartnerFormModalProps {
    onClose: Function;
    onFormFinish: Function;
}

export const CreateAccessPartnerFormModal = ({ onClose, onFormFinish }: ICreateAccessPartnerFormModalProps) => {
    const { notification } = App.useApp();
    const [form] = Form.useForm<IAccessPartner>();
    const { mutate: createAccessPartner, isLoading, isError, error } = useAccessPartnerCreate();

    const onAccessPartnerModalFormFinish = (values: IAccessPartner) => {
        createAccessPartner(values, {
            onSuccess: () => {
                onFormFinish();
            },
        });
    };

    const closeProgramDialog = () => {
        onClose();
    };

    if (isError) {
        notification.error({
            key: 'access-partner-create-error',
            message: error.message || 'Cannot create program operator!',
        });
    }

    return (
        <Modal
            open
            destroyOnClose
            title="Create New Program Operator"
            onCancel={closeProgramDialog}
            footer={[
                <Button key="program-modal-cancel" onClick={closeProgramDialog}>
                    Cancel
                </Button>,
                <Button key="program-modal-submit" type="primary" loading={isLoading} onClick={form.submit}>
                    Submit
                </Button>,
            ]}
        >
            <Form form={form} name="access-partner-form" preserve={false} layout="vertical" onFinish={onAccessPartnerModalFormFinish}>
                <Form.Item
                    name="name"
                    label={<Typography.Text strong>Program Operator Name</Typography.Text>}
                    rules={[
                        { required: true, message: 'Please enter program operator name!' },
                        { max: 150, message: 'Number of characters should be less than 150' },
                    ]}
                >
                    <Input placeholder="Name" size="large" />
                </Form.Item>

                <Form.Item
                    name="id"
                    label={<Typography.Text strong>Program Operator Id</Typography.Text>}
                    rules={[
                        { required: true, message: 'Please enter program operator id!' },
                        { max: 32, message: 'Number of characters should be less or equal to 32' },
                        { pattern: ALPHANUMERIC_REGEX, message: 'Only alphanumeric characters and dash is allowed' },
                    ]}
                >
                    <Input placeholder="Id" size="large" />
                </Form.Item>
            </Form>
        </Modal>
    );
};
