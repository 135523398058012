import { InfoCircleOutlined } from '@ant-design/icons';
import CaretRightOutlined from '@ant-design/icons/CaretRightOutlined';
import CheckOutlined from '@ant-design/icons/CheckOutlined';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import WarningOutlined from '@ant-design/icons/WarningOutlined';
import App from 'antd/lib/app';
import Button from 'antd/lib/button';
import Col from 'antd/lib/col';
import Collapse from 'antd/lib/collapse';
import DatePicker from 'antd/lib/date-picker';
import Divider from 'antd/lib/divider';
import Form from 'antd/lib/form';
import FormItem from 'antd/lib/form/FormItem';
import Input from 'antd/lib/input';
import InputNumber from 'antd/lib/input-number';
import Modal from 'antd/lib/modal';
import Radio from 'antd/lib/radio';
import Row from 'antd/lib/row';
import Select from 'antd/lib/select';
import Space from 'antd/lib/space';
import Switch from 'antd/lib/switch';
import Tooltip from 'antd/lib/tooltip';
import Typography from 'antd/lib/typography';
import { format } from 'date-fns';
import { theme } from 'antd/lib';
import React, { useCallback, useMemo, useState } from 'react';
import { ProgramSelector } from '../../../components/selectors/ProgramSelector/ProgramSelector';
import { formatToMinutesAndHours } from '../../../domain/common/dateFormatters';
import { createEventBatchByProgram } from '../../../domain/event';

import { IEvent, SIGNAL_LEVEL_OPTIONS, SliceType, SliceTypeLabel } from '../../../domain/event/interface';
import { FORM_ACTION, toBatchRequest, ViewEvent } from '../eventInterface';
import { isPastDate } from '../utils';
import { EventConfirmationModal } from './EventConfirmationModal';
import { CalendarEventPreview } from './CalendarEventPreview';

export const EventByProgramFormModal = ({ event, onClose }: { event: Partial<IEvent>; onClose: Function }) => {
    const { token: themeColors } = theme.useToken();

    const { notification } = App.useApp();
    const [loading, setLoading] = useState<boolean>(false);
    const viewEvent = useMemo(() => new ViewEvent(event), [event]);
    const [formAction, setFormAction] = useState<FORM_ACTION | null>(null);
    const [state, setState] = useState({} as any);
    const [form] = Form.useForm();

    const isTestEvent = Form.useWatch('event_test', form);
    const isEmergencyEvent = Form.useWatch('event_emergency_generator_allowed', form);
    const isVoluntaryEvent = Form.useWatch('event_voluntary', form);

    const preEventDuration = Form.useWatch('event_pre_duration_hours', form);
    const eventDuration = Form.useWatch('event_duration_hours', form);
    const postEventDuration = Form.useWatch('event_post_duration_hours', form);
    const secondaryPreEventDuration = Form.useWatch('event_secondary_duration_hours', form);

    const handleProgramSelect = (value: number | string, option: any) => {
        setState((prevState: any) => ({
            ...prevState,
            program: { program_name: option.label, program_id: option.value },
        }));
    };

    const createEventWithConfirmation = () => setFormAction(FORM_ACTION.CREATE);

    const onEventFormFinish = useCallback(() => createEventWithConfirmation(), []);

    const createBatchAction = async () => {
        setLoading(true);

        try {
            const preparedEvent = await form.validateFields();
            const { event } = toBatchRequest(preparedEvent);

            await createEventBatchByProgram(event, [state?.program?.program_id]);

            notification.info({ key: 'event-save-info', message: 'Events saved' });
            onClose(event);
        } catch (err: any) {
            notification.error({ key: 'event-save-error', message: err.message || 'Cannot save events!' });
        }

        setLoading(false);
    };

    const closeEventConfirmationModal = () => setFormAction(null);

    const closeDialog = () => onClose();

    return (
        <Modal
            open
            destroyOnClose
            title="New event by program"
            onCancel={closeDialog}
            footer={[
                <Space wrap>
                    <Button key="event-form-cancel" onClick={closeDialog}>
                        Close
                    </Button>

                    <Button key="event-form-submit" type="primary" loading={loading} onClick={form.submit}>
                        Save
                    </Button>
                </Space>,
            ]}
            className="event-modal"
        >
            <div className="event-form-container content-background">
                <Form form={form} name="event-form" preserve={false} layout="vertical" onFinish={onEventFormFinish} initialValues={viewEvent}>
                    <Row>
                        <Col xs={24}>
                            <Form.Item
                                required
                                label={<Typography.Text strong>Program</Typography.Text>}
                                name="program_id"
                                rules={[{ required: true, message: 'Please select program!' }]}
                            >
                                <ProgramSelector
                                    allowClear
                                    handleProgram={() => null}
                                    selectedProgram={state?.program?.program_id || null}
                                    style={{ width: '100%' }}
                                    onSelect={handleProgramSelect}
                                />
                            </Form.Item>

                            <Form.Item name="event_id" hidden>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={24}>
                            <Form.Item label={<Typography.Text strong>Pre Event duration</Typography.Text>}>
                                <Space>
                                    <Form.Item name="event_pre_duration_hours" noStyle>
                                        <InputNumber min={0} max={4} step={0.25} size="large" />
                                    </Form.Item>
                                    <Typography.Text>hours</Typography.Text>
                                </Space>
                            </Form.Item>

                            <Form.Item style={{ marginBottom: 0 }}>
                                <Space>
                                    <Form.Item
                                        name="event_datetime_start_local"
                                        required
                                        rules={[{ required: true, message: 'Please select date!' }]}
                                        label={
                                            <>
                                                <Typography.Text strong>Event start&nbsp;</Typography.Text>
                                                <Tooltip title="The event will be dispatched to start at each site's local timezone">
                                                    <InfoCircleOutlined style={{ color: themeColors.colorPrimary }} />
                                                </Tooltip>
                                            </>
                                        }
                                    >
                                        <DatePicker format="YYYY-MM-DD HH:mm" showTime={{ format: 'HH:mm' }} size="large" />
                                    </Form.Item>
                                    <Form.Item dependencies={['event_datetime_start_local']} style={{ marginBottom: 0 }}>
                                        {({ getFieldValue }) => {
                                            const selectedDate = getFieldValue('event_datetime_start_local');

                                            return (
                                                selectedDate &&
                                                isPastDate(getFieldValue('event_datetime_start_local')) && (
                                                    <Typography.Text type="secondary">
                                                        <WarningOutlined style={{ color: '#faad14' }} /> Event started in the past.
                                                    </Typography.Text>
                                                )
                                            );
                                        }}
                                    </Form.Item>
                                </Space>
                            </Form.Item>

                            <Form.Item label={<Typography.Text strong>Event duration</Typography.Text>}>
                                <Space>
                                    <Form.Item name="event_duration_hours" noStyle>
                                        <InputNumber min={0} max={8} step={0.25} size="large" />
                                    </Form.Item>
                                    <Typography.Text>hours</Typography.Text>
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Collapse accordion size="small" expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
                        <Collapse.Panel key="1" header="Slice">
                            <FormItem
                                wrapperCol={{ span: 24 }}
                                label={<Typography.Text strong>Slice Type</Typography.Text>}
                                name={['event_slice', 'type']}
                            >
                                <Radio.Group>
                                    {Object.entries(SliceTypeLabel).map(([value, label]) => (
                                        <Radio key={value} value={value} disabled={value === SliceType.KW}>
                                            {label}
                                        </Radio>
                                    ))}
                                </Radio.Group>
                            </FormItem>
                            <FormItem label={<Typography.Text strong>Slice Amount</Typography.Text>} name={['event_slice', 'amount']}>
                                <InputNumber min={0} size="large" />
                            </FormItem>
                        </Collapse.Panel>
                    </Collapse>

                    <Divider plain>Advanced</Divider>

                    <Row>
                        <Col xs={24}>
                            <Form.Item label={<Typography.Text strong>OADR Signal Level</Typography.Text>} name="event_signal">
                                <Select
                                    placeholder="Please select signal level"
                                    options={SIGNAL_LEVEL_OPTIONS}
                                    style={{ width: '175px' }}
                                    size="large"
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <Form.Item label={<Typography.Text strong>Post Event</Typography.Text>}>
                                <Space>
                                    <Form.Item noStyle name="event_post_duration_hours">
                                        <InputNumber min={0} max={4} step={0.25} size="large" />
                                    </Form.Item>
                                    <Typography.Text>hours</Typography.Text>
                                </Space>
                            </Form.Item>
                        </Col>

                        <Col xs={12}>
                            <Form.Item label={<Typography.Text strong>Secondary Pre Event</Typography.Text>}>
                                <Space>
                                    <Form.Item name="event_secondary_duration_hours" noStyle>
                                        <InputNumber min={0} max={4} step={0.25} size="large" />
                                    </Form.Item>
                                    <Typography.Text>hours</Typography.Text>
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <Form.Item name="event_test" label={<Typography.Text strong>Test Event</Typography.Text>} valuePropName="checked">
                                <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                            </Form.Item>
                        </Col>

                        <Col xs={12}>
                            <Form.Item
                                name="event_emergency_generator_allowed"
                                label={<Typography.Text strong>Emergency Generators Allowed</Typography.Text>}
                                valuePropName="checked"
                            >
                                <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                            </Form.Item>
                            <Form.Item
                                name="event_voluntary"
                                label={<Typography.Text strong>Voluntary Event</Typography.Text>}
                                valuePropName="checked"
                            >
                                <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <CalendarEventPreview
                        isTest={isTestEvent}
                        isEmergency={isEmergencyEvent}
                        isVoluntary={isVoluntaryEvent}
                        preEventDurationHours={preEventDuration}
                        eventDurationHours={eventDuration}
                        postEventDurationHours={postEventDuration}
                        secondaryPreEventDurationHours={secondaryPreEventDuration}
                    />
                </Form>

                {formAction && (
                    <EventConfirmationModal
                        action={formAction}
                        handleConfirm={createBatchAction}
                        handleClose={closeEventConfirmationModal}
                        modalContent={
                            <ConfirmationModalContent
                                programName={state.program.program_name}
                                event={{
                                    ...form.getFieldsValue(),
                                    ...([FORM_ACTION.DELETE, FORM_ACTION.DELETE_BATCH].includes(formAction) && {
                                        event_datetime_start_local: event.event_datetime_start,
                                    }),
                                }}
                            />
                        }
                    />
                )}
            </div>
        </Modal>
    );
};

interface IConfirmationModalContent {
    event: ViewEvent;
    programName: string;
}

const ConfirmationModalContent = ({ event, programName }: IConfirmationModalContent) => {
    const event_duration_in_minutes = event.event_duration_hours * 60;
    const event_pre_duration_in_minutes = event.event_pre_duration_hours * 60;

    return (
        <>
            <Typography>You are going to dispatch all events connected by program: {programName}</Typography>
            <Typography>
                events starts at: <strong>{format(new Date(event.event_datetime_start_local!), 'dd-LLL-yyyy HH:mmaaa')}</strong>
            </Typography>
            {Boolean(event_pre_duration_in_minutes) && (
                <Typography>
                    events pre duration: <strong>{formatToMinutesAndHours(event_pre_duration_in_minutes)}</strong>.
                </Typography>
            )}
            <Typography>
                events duration: <strong>{formatToMinutesAndHours(event_duration_in_minutes)}</strong>.
            </Typography>
        </>
    );
};
