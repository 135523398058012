import { useState } from 'react';
import UploadOutlined from '@ant-design/icons/UploadOutlined';
import CopyOutlined from '@ant-design/icons/lib/icons/CopyOutlined';
import Modal from 'antd/lib/modal';
import Form from 'antd/lib/form';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import Select from 'antd/lib/select';
import Divider from 'antd/lib/divider';
import Upload from 'antd/lib/upload';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Switch from 'antd/lib/switch';
import Typography from 'antd/lib/typography';
import uniq from 'lodash/uniq';
import trim from 'lodash/trim';
import App from 'antd/lib/app';
import AlignLeftOutlined from '@ant-design/icons/lib/icons/AlignLeftOutlined';

import { ICloudVen, profileOptions } from '../../../domain/ven/interface';
import { saveCloudVen } from '../../../domain/ven';
import { useAuth } from '../../../domain/auth/useAuth';
import { VenResourceSelector } from '../../../components/selectors/VenResourceSelector/VenResourceSelector';
import { copyVenToClipboard } from '../../../domain/ven/copyVenToClipboard';
import { parseResourceIdString } from '../../../domain/ven/parseResourceIdString';
import { COMPANY_TYPE, ICompany } from '../../../domain/company/interface';
import { filterCompaniesByType } from '../../../components/selectors/GlobalCompanySelector/companyHelpers';
import { CloudVenDispatchBy, IDispatchBy } from './CloudVenDispatchBy/CloudVenDispatchBy';
import { CertificateFingerprints } from './CertificateFingerprints';
import { CloudVenLogsModal } from 'src/components/modals/CloudVenLogsModal/CloudVenLogsModal';

interface IVenFormProps {
    ven: ICloudVen | Partial<ICloudVen>;
    onClose: Function;
}

export function CloudVenFormModal({ ven, onClose }: IVenFormProps) {
    const { notification } = App.useApp();
    const auth = useAuth();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const [showLogs, setShowLogs] = useState<boolean>(false);
    const [cert, setCert] = useState<string | undefined>(ven.ven_cert);
    const [key, setKey] = useState<string | undefined>(ven.ven_key);
    const [venResourceIds, setVenResourceIds] = useState<string[]>(parseResourceIdString(ven.ven_resource_id));

    const isEditMode = Boolean(ven.id);

    const program_ids = ven.programs?.map(program => program.program_id) || [];
    /** currently we allow only one site */
    const company_id = ven.sites?.[0]?.company_id || undefined;
    const site_id = ven.sites?.[0]?.site_id || undefined;

    const [dispatchBy, setDispatchBy] = useState<IDispatchBy>({ program_ids, company_id, site_id });

    async function onFormFinish(formVen: ICloudVen) {
        const resourceId = uniq(((formVen.ven_resource_id as any) || []).map((resource: string) => resource.split('::')[0])).join(',');

        try {
            setLoading(true);
            const { program_ids, site_id } = dispatchBy;

            const venDto: any = {
                ...formVen,
                ...(ven?.id && { id: ven.id }),
                program_ids: program_ids,
                site_ids: site_id ? [site_id] : undefined,
                ven_cert: cert,
                ven_key: key,
                ven_resource_id: resourceId,
            };
            await saveCloudVen(venDto);
            notification.info({ key: 'ven-save-info', message: 'VEN saved' });
            onClose(ven);
        } catch (err: any) {
            notification.error({ key: 'ven-save-error', message: err.message || 'Cannot save VEN!' });
        }
        setLoading(false);
    }

    function closeModal() {
        onClose();
    }

    function pemToVirtualFile(pem: string | null | undefined, filename: string) {
        if (pem) {
            return [{ uid: '1', name: filename }];
        }
        return [];
    }

    const sslCertProps = {
        multiple: false,
        accept: '.pem',
        maxCount: 1,
        onRemove: (file: any) => {
            console.info('sslCertProps onRemove', file);
            setCert('');
        },
        beforeUpload: (file: any) => {
            console.info('sslCertProps beforeUpload', file);
            return false;
        },
        onChange: (info: any) => {
            console.log('sslCertProps onChange', info);
            if (info.file.status || !info.fileList.length) {
                return;
            }

            let reader = new FileReader();
            reader.readAsText(info.file, 'UTF-8');
            reader.onload = function () {
                setCert('');
                console.info('reader.result', reader.result);
                setCert(reader.result as any);
            };
        },
        defaultFileList: pemToVirtualFile(ven.ven_cert, 'cert.pem'),
    };

    const sslKeyProps = {
        multiple: false,
        accept: '.pem',
        maxCount: 1,
        onRemove: (file: any) => {
            console.info('sslKeyProps onRemove', file);
            setKey('');
        },
        beforeUpload: (file: any) => {
            console.info('sslKeyProps beforeUpload', file);
            return false;
        },
        onChange: (info: any) => {
            console.log('sslKeyProps onChange', info);
            if (info.file.status || !info.fileList.length) {
                return;
            }

            let reader = new FileReader();
            reader.readAsText(info.file, 'UTF-8');
            reader.onload = function () {
                console.info('reader.result', reader.result);
                setKey(reader.result as any);
            };
        },
        defaultFileList: pemToVirtualFile(ven.ven_key, 'key.pem'),
    };

    const validateVenResources = () => {
        return {
            validator(_: any, value: any) {
                if (!value.length) {
                    return Promise.resolve();
                }

                const isAllResourcesBelongCompany = value
                    .map((selectedResource: string) => selectedResource.split('::')[1])
                    .every((val: string, i: number, arr: string[]) => val === arr[0]);

                return isAllResourcesBelongCompany
                    ? Promise.resolve()
                    : Promise.reject(new Error('All selected resources should belong to the same company.'));
            },
        };
    };

    const onCopy = async () => {
        await copyVenToClipboard(form.getFieldsValue());
    };

    function onChangeDispatchBy(val: IDispatchBy) {
        setDispatchBy(val);
    }

    function showLogsModal() {
        setShowLogs(true);
    }

    const companyListTransformer = (companies: ICompany[]) => filterCompaniesByType(companies, COMPANY_TYPE.CUSTOMER_COMPANY);

    return (
        <Modal
            open
            destroyOnClose
            title={isEditMode ? 'Edit Cloud VEN' : 'New Cloud VEN'}
            onCancel={closeModal}
            footer={[
                isEditMode ? (
                    <div key="ven-modal-show-logs" style={{ width: '100%', display: 'flex' }}>
                        <Button icon={<AlignLeftOutlined />} onClick={showLogsModal}>
                            Show Logs
                        </Button>
                    </div>
                ) : (
                    ''
                ),
                <Button key="ven-modal-cancel" onClick={closeModal}>
                    Cancel
                </Button>,
                <Button key="ven-modal-submit" type="primary" loading={loading} onClick={form.submit}>
                    Save
                </Button>,
            ]}
        >
            <Form
                form={form}
                name="ven-form"
                preserve={false}
                layout="vertical"
                onFinish={onFormFinish}
                initialValues={{
                    ...ven,
                    ...(!isEditMode && { ven_enabled: true }),
                    ven_resource_id: venResourceIds,
                }}
            >
                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item
                            name="ven_username"
                            label={<Typography.Text strong>Username</Typography.Text>}
                            hasFeedback
                            rules={[
                                { required: true, message: 'Please enter username!' },
                                { max: 30, message: 'Number of characters should be less than 30' },
                            ]}
                            normalize={trim}
                        >
                            <Input placeholder="Username" size="large" autoComplete="cloud-ven-username" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="ven_password"
                            label={<Typography.Text strong>Password</Typography.Text>}
                            hasFeedback
                            rules={[
                                { required: true, message: 'Please enter password!' },
                                { max: 30, message: 'Number of characters should be less than 30' },
                            ]}
                            normalize={trim}
                        >
                            <Input.Password placeholder="Password" size="large" autoComplete="new-password" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item
                            name="ven_id"
                            label={<Typography.Text strong>VEN ID</Typography.Text>}
                            hasFeedback
                            rules={[
                                { required: true, message: 'Please enter VEN ID!' },
                                { max: 30, message: 'Number of characters should be less than 30' },
                            ]}
                            normalize={trim}
                        >
                            <Input placeholder="VEN ID" size="large" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="ven_vtn_id"
                            label={<Typography.Text strong>VTN ID</Typography.Text>}
                            hasFeedback
                            rules={[
                                { required: true, message: 'Please enter VTN ID!' },
                                { max: 30, message: 'Number of characters should be less than 30' },
                            ]}
                            normalize={trim}
                        >
                            <Input placeholder="VTN ID" size="large" />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    name="ven_uri"
                    label={<Typography.Text strong>URI</Typography.Text>}
                    hasFeedback
                    rules={[
                        { required: true, message: 'Please enter VEN URI!' },
                        { max: 200, message: 'Number of characters should be less than 200' },
                    ]}
                    normalize={trim}
                >
                    <Input placeholder="URI" size="large" />
                </Form.Item>
                <Row justify="end">
                    <Button key="copy-ven-info" type="primary" onClick={onCopy} icon={<CopyOutlined />}>
                        Copy VEN Properties
                    </Button>
                </Row>
                <Divider plain>OpenADR Options</Divider>
                <Form.Item
                    name="ven_market_context"
                    rules={[{ max: 1000, message: 'Number of characters should be less than 1000' }]}
                    label={<Typography.Text strong>Market Context</Typography.Text>}
                >
                    <Input placeholder="Market Context" size="large" />
                </Form.Item>
                <Form.Item
                    tooltip="You can select resources related to ONLY one company."
                    label={<Typography.Text strong>Resource ID</Typography.Text>}
                >
                    <Form.Item name="ven_resource_id" rules={[validateVenResources]} noStyle>
                        <VenResourceSelector ids={ven.ven_resource_id} onChange={setVenResourceIds} companyListTransformer={companyListTransformer} />
                    </Form.Item>
                </Form.Item>
                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item
                            name="ven_group_id"
                            rules={[{ max: 100, message: 'Number of characters should be less than 100' }]}
                            label={<Typography.Text strong>Group ID</Typography.Text>}
                        >
                            <Input placeholder="Group ID" size="large" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="ven_party_id"
                            rules={[{ max: 100, message: 'Number of characters should be less than 100' }]}
                            label={<Typography.Text strong>Party ID</Typography.Text>}
                        >
                            <Input placeholder="Party ID" size="large" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item labelCol={{ span: 24 }} label={<Typography.Text strong>SSL Cert (PEM)</Typography.Text>}>
                            <Upload {...sslCertProps}>
                                <Button icon={<UploadOutlined />} size="large">
                                    Upload
                                </Button>
                            </Upload>
                            {cert && <CertificateFingerprints certificate={cert} />}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item labelCol={{ span: 24 }} label={<Typography.Text strong>SSL Key (PEM)</Typography.Text>}>
                            <Upload {...sslKeyProps}>
                                <Button icon={<UploadOutlined />} size="large">
                                    Upload
                                </Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item name="ven_profile" label={<Typography.Text strong>OpenADR Profile</Typography.Text>}>
                            <Select size="large" placeholder="Please select OpenADR type" options={profileOptions}></Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="ven_registration_id" label={<Typography.Text strong>Registration ID</Typography.Text>}>
                            <Input size="large" />
                        </Form.Item>
                    </Col>
                </Row>
                {auth?.user?.isAdmin() && (
                    <>
                        <Form.Item
                            tooltip="VEN can dispatch events by site or program(s). You can choose one."
                            label={<Typography.Text strong>Dispatch By</Typography.Text>}
                        >
                            <CloudVenDispatchBy value={dispatchBy} onChange={onChangeDispatchBy} />
                        </Form.Item>
                        <Divider />
                        <Form.Item
                            name="ven_detailed_logging"
                            tooltip="If enabled, more details about communication will be logged"
                            label={<Typography.Text strong>Detailed Logging</Typography.Text>}
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>
                    </>
                )}
                <Form.Item
                    name="ven_enabled"
                    tooltip="You can temporary disable VEN, instead of deleting it"
                    label={<Typography.Text strong>Enable VEN</Typography.Text>}
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>
            </Form>

            {showLogs && <CloudVenLogsModal onClose={() => setShowLogs(false)} ven={ven} />}
        </Modal>
    );
}
