import { ReactElement, useEffect, useState } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { DrrsLocationsView, DrrsRegistrationsView } from './view';
import { CAISO_DRRS_VIEW } from 'src/pages/interface';
import { CaisoBreadcrumbNav } from 'src/components/CaisoBreadcrumb/CaisoBreadcrumbNav';

export function DrrsView() {
    const [view, setView] = useState<CAISO_DRRS_VIEW>(CAISO_DRRS_VIEW.LOCATIONS);
    const navigate = useNavigate();
    const location = useLocation();

    const handleView = (newView: CAISO_DRRS_VIEW) => {
        setView(newView);
        navigate(`/caiso/drrs/${newView}`);
    };

    useEffect(() => {
        if (location.pathname === '/caiso/drrs' || location.pathname === '/caiso/drrs/') {
            navigate('/caiso/drrs/locations');
        }
    }, [location.pathname, navigate]);

    return (
        <>
            <CaisoBreadcrumbNav />
            <Routes>
                <Route path="locations" element={<DrrsLocationsView view={view} handleView={handleView} />} />
                <Route path="registrations" element={<DrrsRegistrationsView view={view} handleView={handleView} />} />
            </Routes>
        </>
    );
}
