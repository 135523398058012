import { useState } from 'react';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import App from 'antd/lib/app';
import InboxOutlined from '@ant-design/icons/InboxOutlined';
import Dragger from 'antd/lib/upload/Dragger';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import Modal from 'antd/lib/modal';
import Form from 'antd/lib/form';
import Tooltip from 'antd/lib/tooltip';
import Upload from 'antd/lib/upload';
import { uploadEnergyFile } from '../../../../domain/energy';
import { EnergyUsageImportFormatDescription } from './EnergyUsageImportFormatDescription';

const formItemLayout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 12 },
};

interface IUploadEnergyDataModalProps {
    onClose: () => void;
}

const ALLOWED_FILE_TYPES = ['.xml', '.csv'];

export const UploadEnergyDataModal = ({ onClose }: IUploadEnergyDataModalProps) => {
    const { notification } = App.useApp();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const [file, setFile] = useState<File | null>(null);

    async function onFormFinish() {
        if (!file) {
            console.warn('File is absent!', file);
            return;
        }

        try {
            setLoading(true);

            await uploadEnergyFile(file, 'user-upload');

            setLoading(false);
            notification.info({
                key: 'energy-file-upload-info',
                message: 'Energy file uploaded',
            });
            onClose();
        } catch (err: any) {
            setLoading(false);
            notification.error({
                key: 'energy-file-upload-error',
                message: err?.message || 'Energy file upload error!',
            });
        }
    }

    const uploadProps = {
        accept: ALLOWED_FILE_TYPES.join(','),
        multiple: false,
        maxCount: 1,
        onRemove: (file: any) => {
            setFile(null);
        },
        beforeUpload: (file: File) => {
            const ALLOWED_FILE_SIZE_MB = 50;
            const fileSizeInMB = file.size! / 1024 / 1024;
            if (fileSizeInMB > ALLOWED_FILE_SIZE_MB) {
                notification.warning({
                    key: 'upload-file-size-notification',
                    message: `Please select file up to ${ALLOWED_FILE_SIZE_MB}MB.`,
                });
                return Upload.LIST_IGNORE;
            }

            if (!ALLOWED_FILE_TYPES.some(type => file.name.endsWith(type))) {
                notification.warning({
                    key: 'upload-file-type-notification',
                    message: `Please select "${ALLOWED_FILE_TYPES.join('" or "')}" file.`,
                });
                return Upload.LIST_IGNORE;
            }

            return false;
        },
        onChange: (info: any) => {
            if (info.file.status) {
                return;
            }

            setFile(info.file);
        },
    };

    return (
        <Modal
            title="Upload Greenbutton XML or CSV file"
            width="70%"
            open
            destroyOnClose
            onCancel={onClose}
            footer={[
                <Tooltip
                    title="Click to download a CSV template that you could fill in with your data to import"
                    key="upload-energy-data-modal-template-tooltip"
                >
                    <Button key="upload-energy-data-modal-template" type="text" onClick={downloadTemplate}>
                        Download Template
                    </Button>
                </Tooltip>,
                <Button key="upload-energy-data-modal-cancel" onClick={onClose}>
                    Cancel
                </Button>,
                <Button key="upload-energy-data-modal-submit" type="primary" disabled={!file} loading={loading} onClick={form.submit}>
                    Upload
                </Button>,
            ]}
        >
            <Form form={form} name="upload-energy-data-form" preserve={false} {...formItemLayout} onFinish={onFormFinish}>
                <Form.Item name="upload-energy-data-file-id" hidden>
                    <Input />
                </Form.Item>
                <Dragger {...uploadProps}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag a Greenbutton XML or CSV file to this area to upload</p>
                    <p className="ant-upload-hint">
                        The size limit is 50MB. We will upload the file <strong>without validation</strong>, because actual parsing of data happen
                        later in a separate process.
                    </p>
                    <p className="ant-upload-hint"></p>
                </Dragger>
                <p></p>
                <EnergyUsageImportFormatDescription key="import-energy-data-format" />
            </Form>
        </Modal>
    );
};

const downloadTemplate = () => {
    const csvColumns: string[] = [
        'Enrollment ID*',
        'Interval Start (UTC)*',
        'Interval End (UTC)*',
        'Energy Demand (kW)*',
        'Source',
        'Customer Name',
        'Customer Account',
        'Customer Address',
    ];

    const csvData = Papa.unparse([csvColumns], {
        quotes: true,
        quoteChar: '"',
        escapeChar: '"',
        delimiter: ',',
        header: true,
        escapeFormulae: true,
    });
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, 'import-energy-data-template.csv');
};
