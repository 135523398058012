import { Button, Col, Empty, Row, Typography } from 'antd';
import { useMemo, useState } from 'react';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import Card from 'antd/lib/card/Card';
import { theme } from 'antd/lib';

import { IEnergyData } from '../../../../domain/energy/interface';
import { IEventData } from '../../../../domain/event/getEventsDataService';
import { IDateRange } from '../../../interface';
import { EnergyHeatMapChart } from './EnergyHeatMapChart';
import { SECONDARY_COLOR } from '../../../../theme';

interface IEnergyUsageWidget {
    energyData: IEnergyData[];
    dateRange: IDateRange;
    energyBaselineData: [number, number][];
    eventData: IEventData[];
    energyPerformanceData: [number, number][];
}

export const EnergyUsageWidget = ({ energyData, dateRange, energyBaselineData, eventData, energyPerformanceData }: IEnergyUsageWidget) => {
    const { token: themeColors } = theme.useToken();

    const [showMore, setShowMore] = useState(false);
    const toggleShowMore = () => setShowMore(!showMore);
    const isDataExist = useMemo(() => {
        const filteredEnergyData = energyData.filter(
            el => el.values.filter(([timestamp, usage]) => timestamp >= dateRange.start.valueOf() && timestamp <= dateRange.end.valueOf()).length
        );

        return filteredEnergyData.length > 0;
    }, [dateRange.end, dateRange.start, energyData]);

    return (
        <Card style={{ marginBottom: '16px' }} className="energy-usage-widget">
            <Typography.Title level={4} style={{ marginTop: 0, color: SECONDARY_COLOR }}>
                Energy Usage Over Each Hour
            </Typography.Title>
            <Row gutter={[0, 0]}>
                {isDataExist ? (
                    <>
                        <Col xs={24} className={`energy-usage-chart ${showMore && 'full-energy-usage-chart'}`}>
                            <EnergyHeatMapChart
                                data={energyData}
                                dateRange={dateRange}
                                eventData={eventData}
                                energyBaselineData={energyBaselineData}
                                energyPerformanceData={energyPerformanceData}
                            />
                        </Col>
                        <Col xs={24} style={{ textAlign: 'center', paddingTop: '8px' }}>
                            <Button onClick={toggleShowMore} type="link">
                                <Typography.Text strong style={{ fontSize: '16px', color: themeColors.colorPrimary }}>
                                    {showMore ? (
                                        <>
                                            Show less <UpOutlined />
                                        </>
                                    ) : (
                                        <>
                                            Show more <DownOutlined />
                                        </>
                                    )}
                                </Typography.Text>
                            </Button>
                        </Col>
                    </>
                ) : (
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </Col>
                )}
            </Row>
        </Card>
    );
};
