import Alert from 'antd/lib/alert';
import Card from 'antd/lib/card';
import Empty from 'antd/lib/empty';
import Row from 'antd/lib/row';
import Space from 'antd/lib/space';
import { useContext } from 'react';
import { AbilityContext } from '../../../../components/ability/can';
import { IAnalyticsChartsData } from '../../../../domain/site/interface';
import { IDateRange } from '../../../interface';
import { EnergyDemandWidget } from './EnergyDemandWidget';
import { EnergyUsageWidget } from './EnergyUsageWidget';
import { SiteEnergyWidget } from './SiteEnergyWidget';

export interface IAnalyticsChartsProps {
    chartsData: IAnalyticsChartsData;
    date: IDateRange;
    company: number | null;
    selectedSitesId: number[] | null;
}

export const AnalyticsCharts = ({ chartsData, date, company, selectedSitesId }: IAnalyticsChartsProps) => {
    const { energyData, energyBaselineData, energyPerformanceData, eventData } = chartsData;

    const ability = useContext(AbilityContext);
    const isCompanyAndSiteSelected = company && selectedSitesId?.length;
    const warningMsg = ability.can('filter', 'Company') ? ' Please select a company and a site.' : ' Please select a site.';

    if (!isCompanyAndSiteSelected) {
        return (
            <Card size="small">
                <Row justify="center">
                    <Space direction="vertical">
                        <Alert showIcon message={warningMsg} type="warning" />
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </Space>
                </Row>
            </Card>
        );
    }

    return (
        <div className="energy-usage-reporting">
            <EnergyDemandWidget
                dateRange={date}
                energyData={energyData}
                energyBaselineData={energyBaselineData}
                energyPerformanceData={energyPerformanceData}
                eventData={eventData}
            />
            <EnergyUsageWidget
                dateRange={date}
                energyData={energyData}
                eventData={eventData}
                energyBaselineData={energyBaselineData}
                energyPerformanceData={energyPerformanceData}
            />
            <SiteEnergyWidget dateRange={date} energyData={energyData} />
        </div>
    );
};
