import Flex from 'antd/lib/flex';
import Tooltip from 'antd/lib/tooltip';
import Typography from 'antd/lib/typography';
import { theme } from 'antd/lib';
import orderBy from 'lodash/orderBy';
import { useMemo, useState } from 'react';
import { ISan } from '../../domain/site/interface';
import { SuggestedFormatIcon } from './SuggestedSanFormatIcon';
import { removeNonUTF8ASCII } from '../../domain/stringCleaner';

interface ISiteSANProps {
    market: string | null;
    utility: string | null;
    sans: ISan[];
    searchString?: string;
}

export const SiteSANs = ({ market, utility, sans, searchString }: ISiteSANProps) => {
    const { token } = theme.useToken();
    const [expanded, setExpanded] = useState(false);

    const sortedSans = useMemo(() => {
        if (searchString) {
            return orderBy(
                sans,
                [item => item.service_account_number.includes(searchString), 'is_active', 'service_account_number'],
                ['desc', 'desc', 'asc']
            );
        }
        return orderBy(sans, ['is_active', 'service_account_number'], ['desc', 'asc']);
    }, [sans, searchString]);

    const sanitizedSAN = useMemo(() => {
        return sortedSans.map(san => ({
            ...san,
            service_account_number: removeNonUTF8ASCII(san.service_account_number),
        }));
    }, [sortedSans]);

    const displayedSAN = expanded ? sanitizedSAN : sanitizedSAN.slice(0, 1);

    return (
        <Flex vertical>
            {displayedSAN.map(san => (
                <Flex key={san.service_account_number}>
                    <Tooltip placement="left" title={san.service_account_number}>
                        <Typography.Text ellipsis type={san.is_active ? undefined : 'secondary'}>
                            {san.service_account_number}
                        </Typography.Text>
                    </Tooltip>

                    <SuggestedFormatIcon utility={utility} market={market} san={san.service_account_number} />
                </Flex>
            ))}

            {sanitizedSAN.length > 1 && (
                <Typography.Text underline onClick={() => setExpanded(!expanded)} style={{ color: token.colorPrimary, cursor: 'pointer' }}>
                    {expanded ? 'Show less' : `Show ${sanitizedSAN.length - 1} more`}
                </Typography.Text>
            )}
        </Flex>
    );
};
