import ConfigProvider from 'antd/lib/config-provider';
import { useAuth } from '../../auth/useAuth';
import theme from '../../../theme';
import '../../../App.css';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import logoImg from '../../../logo.png';
import { useCompanyBrandingQuery } from './queries';

const CompanyLogoContext = createContext<string>(logoImg);

export const useCompanyLogo = () => {
    return useContext(CompanyLogoContext);
};

export const BrandingProvider = ({ children }: { children: React.ReactNode }) => {
    const [themeConfig, setThemeConfig] = useState(theme);
    const auth = useAuth();

    const isQueryData = !!auth?.user?.company_id && auth?.user?.isUtilityCustomer();
    const { data, isLoading: loading, isError } = useCompanyBrandingQuery({ companyId: auth?.user?.company_id || null }, { enabled: isQueryData });
    const settings = useMemo(() => data, [data]);

    useEffect(() => {
        if (settings && settings.primaryColor) {
            setThemeConfig({
                ...themeConfig,
                token: {
                    ...themeConfig.token,
                    colorPrimary: settings!.primaryColor,
                    colorLink: settings!.primaryColor,
                },
                components: {
                    ...themeConfig.components,
                    Notification: {
                        colorInfo: settings!.primaryColor,
                    },
                    ColorPicker: {
                        colorPrimaryBorderHover: settings!.primaryColor,
                        colorPrimaryHover: settings!.primaryColor,
                        colorPrimaryActive: settings!.primaryColor,
                    },
                },
            });
        }
    }, [settings]);

    if (!!auth?.user && auth.user.isUtilityCustomer() && loading) {
        return null;
    }

    return (
        <>
            <CompanyLogoContext.Provider value={settings?.logoUrl || logoImg}>
                <ConfigProvider theme={themeConfig}>{children}</ConfigProvider>
            </CompanyLogoContext.Provider>
        </>
    );
};
