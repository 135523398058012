import React, { useState, useEffect } from 'react';
import Button from 'antd/lib/button';
import Form from 'antd/lib/form';
import Radio from 'antd/lib/radio';
import Typography from 'antd/lib/typography';
import List from 'antd/lib/list';
import Modal from 'antd/lib/modal/Modal';
import { EVENT_FREQUENCY, ISettings } from '../../../domain/settings/interface';
import { fetchSettings, updateSettings } from '../../../domain/settings';
import { useAuth } from '../../../domain/auth/useAuth';
import App from 'antd/lib/app';
import { useDocumentTitle } from '../../useDocumentTitle';

const priceResponseTriggeringOptions = [
    {
        value: EVENT_FREQUENCY.HOUR,
        label: 'one event per hour',
        description: 'Price Response can trigger an event not more than once per hour',
    },
    {
        value: EVENT_FREQUENCY.DAY,
        label: 'one event per day',
        description: 'Price Response can trigger an event not more than once per day',
    },
];

const cleanResponseTriggeringOptions = [
    {
        value: EVENT_FREQUENCY.HOUR,
        label: 'one event per hour',
        description: 'Clean Response can trigger an event not more than once per hour',
    },
    {
        value: EVENT_FREQUENCY.DAY,
        label: 'one event per day',
        description: 'Clean Response can trigger an event not more than once per day',
    },
];

interface ITriggerSettingsModalProps {
    onClose: () => void;
    triggerName: 'priceResponse' | 'cleanResponse';
}

export const TriggerSettingsModal: React.FC<ITriggerSettingsModalProps> = ({ triggerName, onClose }) => {
    const { notification } = App.useApp();
    const [form] = Form.useForm();
    const { user } = useAuth()!;

    const [settings, setSettings] = useState<ISettings | undefined>();
    const [loading, setLoading] = useState(false);

    useDocumentTitle('Settings');

    useEffect(() => {
        (async () => {
            try {
                const data = await fetchSettings(user!.company_id);
                setSettings(data);
                form.setFieldsValue(data);
            } catch (error: any) {
                notification.error({
                    key: 'fetch-settings-data-error',
                    message: error.message || 'Cannot fetch settings data!',
                });
            }
        })();
    }, []);

    const onSettingsFormFinish = async (updatedSettings: ISettings) => {
        setLoading(true);
        try {
            await updateSettings(user!.company_id, { ...settings, ...updatedSettings });
            notification.info({ key: 'settings-save-info', message: 'Settings saved' });
            onClose();
        } catch (error: any) {
            notification.error({
                key: 'update-settings-data-error',
                message: error.message || 'Cannot update settings!',
            });
        }
        setLoading(false);
    };

    return (
        <Modal
            open
            destroyOnClose
            title="Trigger Settings"
            width={420}
            onCancel={onClose}
            footer={[
                <Button key="trigger-settings-modal-cancel" onClick={onClose}>
                    Cancel
                </Button>,
                <Button key="trigger-settings-submit" type="primary" loading={loading} onClick={form.submit}>
                    Save
                </Button>,
            ]}
            data-cy="trigger-settings-modal"
        >
            <Form form={form} name="settings-form" preserve={false} initialValues={settings} onFinish={onSettingsFormFinish} layout="vertical">
                <Form.Item required label={<Typography.Text strong>Triggering rule:</Typography.Text>} name={[triggerName, 'eventPer']}>
                    <Radio.Group style={{ width: '100%' }}>
                        <List
                            grid={{ column: 1 }}
                            dataSource={triggerName === 'priceResponse' ? priceResponseTriggeringOptions : cleanResponseTriggeringOptions}
                            renderItem={item => (
                                <List.Item>
                                    <Radio value={item.value} key={item.value}>
                                        {item.label}
                                    </Radio>
                                    <List.Item.Meta description={item.description} />
                                </List.Item>
                            )}
                        />
                    </Radio.Group>
                </Form.Item>
            </Form>
        </Modal>
    );
};
