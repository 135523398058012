import sum from 'lodash/sum';
import { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PlusCircleOutlined from '@ant-design/icons/PlusCircleOutlined';
import { HighlightOutlined, CalendarOutlined } from '@ant-design/icons';
import Button from 'antd/lib/button';
import Table from 'antd/lib/table';
import Typography from 'antd/lib/typography';
import { ColumnsType } from 'antd/lib/table/interface';
import App from 'antd/lib/app';
import Spin from 'antd/lib/spin';
import startCase from 'lodash/startCase';
import { AbilityContext } from '../../components/ability/can';
import { SyncSalesforceButton } from '../../components/salesforce/SyncSalesforceButton/SyncSalesforceButton';
import { ActionList, IAction } from '../../components/table/actionList/actionList';
import { WithEmptyDataTable } from '../../components/table/withEmptyDataTable';
import { JobName } from '../../domain/async-job/interface';
import { COMPANY_ACTION, COMPANY_TYPE, COMPANY_VERTICAL, ICompany, SPECIFIC_COMPANY_ACTION } from '../../domain/company/interface';
import { compareWithLocale } from '../../domain/compareWithLocale';
import { CompanyFormModal } from './components/CompanyFormModal';
import { toUsefulParams } from '../toUsefulParams';
import { usePageLocation } from '../usePageState';
import { FORM_MODE, useFormMode } from '../../components/useFormMode';
import { getColumnFilteredValue, getColumnSortOrder } from '../../components/table/columnFormatHelpers';
import { DEFAULT_PAGINATION, PAGE_ACTION } from '../../domain/commonConst';
import { useCompanies } from '../../components/selectors/GlobalCompanySelector/CompanyContext';
import { useAuth } from '../../domain/auth/useAuth';
import { SearchInput } from '../../components/searchInput/searchInput';
import { PageHeader } from '../../components/pageHeader/pageHeader';
import { CompaniesExportButton } from './components/CompaniesExportButton/CompaniesExportButton';
import { useCompanyDeleteMutation, useCompanyListQuery } from '../../domain/company/queries';
import { DeleteCompanyModal } from './components/DeleteCompanyModal';
import { useDocumentTitle } from '../../components/useDocumentTitle';
import { CompanySiteCountTag } from './components/CompanySiteCountTag';
import { getTablePaginationParams } from 'src/components/table/getTablePaginationParams';
import { CompanyBrandingModal } from './components/CompanyBrandingModal';

const COMPANY_TYPE_FILTER = [
    { text: 'Customer Company', value: COMPANY_TYPE.CUSTOMER_COMPANY },
    { text: 'Control Provider', value: COMPANY_TYPE.CONTROL_PROVIDER },
    { text: 'Utility Customer', value: COMPANY_TYPE.UTILITY_CUSTOMER },
];

const COMPANY_VERTICAL_FILTER = COMPANY_VERTICAL.sort().map(vertical => ({
    text: vertical,
    value: vertical,
}));

export const Companies = () => {
    const { notification } = App.useApp();
    const auth = useAuth();
    const ability = useContext(AbilityContext);
    const location = useLocation();
    const navigation = useNavigate();

    const { setPageQuery, queryToState } = usePageLocation();
    const pageState: any = queryToState(location.search);

    useDocumentTitle('Companies');

    const [company, setCompany] = useState<ICompany | Partial<ICompany> | null>();

    const [pageAction, setPageAction] = useState<COMPANY_ACTION | ''>();
    const { onSetFormMode, formMode } = useFormMode();

    const { setCompanyId: setActiveCompany } = useCompanies()!;

    const { mutateAsync: deleteCompany } = useCompanyDeleteMutation();

    const listQuery = {
        pagination: pageState?.pagination ?? DEFAULT_PAGINATION,
        ...(pageState?.filter && { filter: pageState?.filter }),
        ...(pageState?.sorter && { sorter: pageState?.sorter }),
        ...(pageState?.search && { search: pageState?.search }),
        include: 'sites-count,account',
    };

    const {
        isLoading,
        isError,
        error,
        data: companies,
        refetch: refetchCompanyList,
    } = useCompanyListQuery(listQuery, {
        keepPreviousData: true,
    });

    if (isError) {
        notification.error({ key: 'fetch-companies-error', message: error.message || 'Cannot fetch companies!' });
    }

    const showNewCompanyModal = () => {
        onSetFormMode(FORM_MODE.NEW);
        setPageAction(PAGE_ACTION.NEW_EDIT);
        setCompany({
            company_id: undefined,
            company_name: undefined,
            type: COMPANY_TYPE.CUSTOMER_COMPANY,
            company_vertical: 'Other',
            ...(auth?.user?.isAdmin() && { api_credentials: { copeland_key: '' } }),
        });
    };

    const showCompanyModal = (selectedCompany: ICompany, formAction: FORM_MODE, pageAction: COMPANY_ACTION) => {
        return () => {
            onSetFormMode(formAction);
            setPageAction(pageAction);
            setCompany(selectedCompany);
        };
    };

    async function deleteCompanyAction(company: ICompany) {
        try {
            await deleteCompany(company.company_id);
            const params = toUsefulParams({ ...pageState, companyId: company.company_id });
            const updatedTotal = companies!.meta.total - 1;
            const updatedPagesCount = Math.ceil(updatedTotal / companies!.meta.limit);

            if (params.pagination?.current > updatedPagesCount) {
                params.pagination.current = updatedPagesCount;
            }
            setPageQuery({ ...pageState, ...params });
            setCompany(null);

            notification.info({ key: 'company-delete-info', message: 'Company deleted' });
        } catch (error: any) {
            notification.error({ key: 'company-delete-error', message: error.message || 'Cannot delete company' });
        }
    }

    const onCloseCompanyModal = () => setCompany(null);

    async function onTableChange(pagination: any, filter: any, sorter: any) {
        const preparedParams = toUsefulParams({ pagination, filter, sorter });
        setPageQuery({ ...pageState, ...preparedParams });
    }

    const gotoEventsHistory = (company: ICompany) => {
        setActiveCompany(company.company_id);
        navigation(`/events?companyId=${company.company_id}&viewMode=history`);
    };

    const handleSearch = async (value: string) => {
        setPageQuery({ ...pageState, search: value });

        // if search value is the same as in the state, then we need to refetch data and set pagination to 1 page
        if (value === (pageState.search || '')) {
            listQuery.pagination.current = DEFAULT_PAGINATION.current;

            await refetchCompanyList(listQuery);
        }
    };

    function getActions(company: ICompany) {
        const actions: IAction[] = [
            {
                title: 'Edit',
                onClick: (record: ICompany) => showCompanyModal(record, FORM_MODE.EDIT, PAGE_ACTION.NEW_EDIT),
            },
        ];

        if (ability.can('delete', 'Company')) {
            actions.push({
                title: 'Delete',
                onClick: (record: ICompany) => showCompanyModal(record, FORM_MODE.DELETE, PAGE_ACTION.DELETE),
                dataAction: 'delete',
            });
        }

        if (ability.can('read', 'Event') && company.type === COMPANY_TYPE.CUSTOMER_COMPANY) {
            actions.push({
                title: (record: ICompany) => 'Open Events History',
                onClick: (record: ICompany) => () => gotoEventsHistory(record),
                icon: (record: ICompany) => <CalendarOutlined />,
            });
        }

        if (ability.can('update:branding', 'Company') && company.type === COMPANY_TYPE.UTILITY_CUSTOMER) {
            actions.push({
                title: 'Edit Branding',
                onClick: (record: ICompany) => showCompanyModal(record, FORM_MODE.EDIT, SPECIFIC_COMPANY_ACTION.EDIT_BRANDING),
                icon: (record: ICompany) => <HighlightOutlined />,
            });
        }

        return actions;
    }

    const columns: ColumnsType<ICompany> = [
        {
            title: 'Company',
            dataIndex: 'company_name',
            ...(auth?.user?.isAdminRoleType() && {
                sorter: (a: any, b: any) => a.company_name - b.company_name,
                ...getColumnSortOrder('company_name', pageState?.sorter),
            }),
            render: (text, record) => (
                <div style={{ maxWidth: '410px' }} data-cy="company-name-cell">
                    {record.company_name}
                </div>
            ),
        },
        {
            title: 'Vertical',
            dataIndex: 'company_vertical',
            width: 150,
            filterMultiple: false,
            filters: [...COMPANY_VERTICAL_FILTER, { text: 'Other', value: 'Other' }],
            ...getColumnFilteredValue('company_vertical', pageState?.filter),
            render: vertical => <div data-cy="company-vertical-cell">{vertical}</div>,
        },
        ...(ability.can('filter', 'ControlProvider')
            ? [
                  {
                      title: 'Type',
                      dataIndex: 'type',
                      width: 150,
                      filterMultiple: false,
                      filters: COMPANY_TYPE_FILTER,
                      ...getColumnFilteredValue('type', pageState?.filter),
                      render: (text: string) => startCase(COMPANY_TYPE[+text].toLowerCase()),
                  },
              ]
            : []),
        ...(auth?.user?.isAdminRoleType()
            ? [
                  {
                      title: 'Company Alias at Utility',
                      dataIndex: 'utility_names',
                      render: (utilityNames: string[]) =>
                          !!utilityNames?.length && (
                              <Typography.Paragraph ellipsis={{ rows: 4, tooltip: true }} style={{ marginBottom: 0 }}>
                                  {utilityNames.join(', ')}
                              </Typography.Paragraph>
                          ),
                  },
              ]
            : []),
        {
            title: 'Accounts count',
            width: 150,
            align: 'center',
            dataIndex: 'accounts_count',
            sorter: (a: ICompany, b: ICompany) => compareWithLocale(a.accounts?.length, b.accounts?.length),
            ...getColumnSortOrder('accounts_count', pageState?.sorter),
            render: (text, record) => record.accounts?.length ?? 0,
        },
        {
            title: 'Sites count',
            width: 100,
            align: 'center',
            dataIndex: 'sites_count',
            sorter: (a: ICompany, b: ICompany) =>
                compareWithLocale(sum([a?.sites_count?.opened, a?.sites_count?.closed]), sum([b?.sites_count?.opened, b?.sites_count?.closed])),
            ...getColumnSortOrder('sites_count', pageState?.sorter),
            render: (text, record) => <CompanySiteCountTag value={record.sites_count} />,
        },
        ...(ability.can('update', 'Company')
            ? [
                  {
                      key: 'action',
                      sorter: false,
                      width: 90,
                      render: (text: string, record: ICompany) => <ActionList actions={getActions(record)} item={record} />,
                  },
              ]
            : []),
    ];

    return (
        <>
            <PageHeader
                pageTitle={<>Companies {isLoading ? <Spin /> : `(${companies?.meta.total})`}</>}
                actions={[
                    <SearchInput onSearch={handleSearch} defaultValue={pageState?.search} key="search-input" />,
                    <CompaniesExportButton filter={{ search: pageState?.search, ...pageState?.filter }} key="company-export" />,
                    ability.can('create', 'AsyncJob') && <SyncSalesforceButton jobName={JobName.SYNC_SALESFORCE_ACCOUNTS} key="sync-salesforce" />,
                    ability.can('create', 'Company') && (
                        <Button
                            size="large"
                            data-cy="create-company"
                            key="new-company"
                            type="primary"
                            onClick={showNewCompanyModal}
                            icon={<PlusCircleOutlined />}
                        >
                            New Company
                        </Button>
                    ),
                ]}
            />
            <WithEmptyDataTable condition={isLoading}>
                <Table
                    size="small"
                    rowKey="company_id"
                    sticky
                    columns={columns}
                    dataSource={companies?.data}
                    loading={isLoading}
                    pagination={getTablePaginationParams(companies?.meta)}
                    onChange={onTableChange}
                />
            </WithEmptyDataTable>

            {company && (formMode === FORM_MODE.EDIT || formMode === FORM_MODE.NEW) && pageAction === PAGE_ACTION.NEW_EDIT && (
                <CompanyFormModal company={company} onClose={onCloseCompanyModal} />
            )}
            {company && formMode === FORM_MODE.DELETE && pageAction === PAGE_ACTION.DELETE && (
                <DeleteCompanyModal company={company} onSubmit={deleteCompanyAction} onClose={onCloseCompanyModal} />
            )}
            {company && formMode === FORM_MODE.EDIT && pageAction === SPECIFIC_COMPANY_ACTION.EDIT_BRANDING && (
                <CompanyBrandingModal company={company} onClose={onCloseCompanyModal} />
            )}
        </>
    );
};
