import { DataInboxFileInfoDto } from './interface';
import { API } from '../api';

export const fetchEnergyDataFileInfo = async (key: string, signal?: AbortSignal | null): Promise<DataInboxFileInfoDto> => {
    const response = await API.fetch(`/data/inbox/status?${new URLSearchParams({ key: key })}`, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
        ...(signal && { signal }),
    });

    const respBody = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot get latest energy data file info!`);
};
