import { useState } from 'react';
import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal';
import { CountdownButton } from '../../countdownButton/countdownButton';

interface IConfirmationModal {
    children: React.ReactNode;
    onCancel: any;
    onConfirm: Function;
    danger?: boolean;
    confirmCountdown?: number;
}

export const ConfirmationModal = ({ children, onCancel, onConfirm, danger = false, confirmCountdown = 5 }: IConfirmationModal) => {
    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = async () => {
        setLoading(true);
        try {
            await onConfirm();
        } catch (error: any) {
            console.error('submit error', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            open
            destroyOnClose
            title="Confirmation"
            onCancel={onCancel}
            data-cy="confirmation-modal"
            footer={[
                <Button key="modal-cancel" onClick={onCancel}>
                    Cancel
                </Button>,
                <CountdownButton
                    key="modal-confirm"
                    title="Confirm"
                    time={confirmCountdown}
                    onClick={handleSubmit}
                    danger={danger}
                    loading={loading}
                />,
            ]}
        >
            {children}
        </Modal>
    );
};
