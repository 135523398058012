import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import { omit } from 'lodash';
import { IDateRange } from '../../pages/interface';
import { API } from '../api';
import { formatToISOString } from '../common/dateFormatters';
import { IFetchPageQuery } from '../IFetchQueryOptions';
import { IPagination } from '../IPagination';
import { ICaisoResource, ResourceBidsDto } from './interface';
import { DATE_FORMAT_CSV } from '../commonConst';
import { exportCSVData } from '../common/exportCSVData';

export const fetchCaisoResources = async ({ pagination }: IFetchPageQuery, signal?: AbortSignal | null): Promise<IPagination<ICaisoResource[]>> => {
    const limit = pagination?.pageSize || 10;
    const offset = (pagination?.current ? pagination.current - 1 : 0) * limit;

    const path = `/scheduling-coordinator/caiso/resources?${new URLSearchParams({
        offset: offset.toString(),
        limit: limit.toString(),
    })}`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot get caiso resource data!`);
};

const createCaisoResource = async (params: any) => {
    const response = await API.fetch('/scheduling-coordinator/caiso/resources', {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return;
    }

    throw new Error(respBody?.error?.message || `Cannot save resource!`);
};

const updateCaisoResource = async (resource: ICaisoResource) => {
    const response = await API.fetch(`/scheduling-coordinator/caiso/resources/${resource.id}`, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(omit(resource, ['id'])),
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return;
    }

    throw new Error(respBody?.error?.message || `Cannot update resource!`);
};

export const saveCaisoResource = async (resource: ICaisoResource) => {
    resource.id ? await updateCaisoResource(resource) : await createCaisoResource(resource);
};

export const deleteCaisoResource = async (resource: ICaisoResource) => {
    const response = await API.fetch(`/scheduling-coordinator/caiso/resources/${resource.id}`, {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (response.ok) {
        return;
    }

    const respBody: any = await response.json();
    throw new Error(respBody?.error?.message || `Cannot delete resource!`);
};

export const exportXMLResources = async ({ start, end }: IDateRange) => {
    const query = new URLSearchParams({
        start: formatToISOString(start),
        end: formatToISOString(end),
    });
    const response = await API.fetch(`/scheduling-coordinator/caiso/bid?${query}`, {
        headers: {
            'Content-Type': 'application/xml',
        },
    });

    if (response.ok) {
        const respBody: string = await response.text();
        const blob = new Blob([respBody], { type: 'text/xml;charset=utf-8' });
        const filename = `resources-${format(start, 'yyyy/MM/dd')}-${format(end, 'yyyy/MM/dd')}.xml`;
        saveAs(blob, filename);
    } else {
        const respBody: any = await response.json();
        throw new Error(respBody?.error.message || `Cannot export data!`);
    }
};

export const exportCaisoResources = async () => {
    const path = `/scheduling-coordinator/caiso/resources?${new URLSearchParams({
        format: 'csv',
    })}`;

    const response = await API.fetch(path, {
        headers: {
            'Content-Type': 'text/csv',
        },
    });

    const respBody: string = await response.text();

    if (response.ok) {
        const filename = `resources-${format(new Date(), DATE_FORMAT_CSV)}.csv`;
        exportCSVData(respBody, filename);
    } else {
        const respBody: any = await response.json();
        throw new Error(respBody?.error?.message || `Cannot export data!`);
    }
};

export const fetchCaisoResourceBids = async ({ search, sorter }: IFetchPageQuery, signal?: AbortSignal | null): Promise<ResourceBidsDto[]> => {
    const params: Record<string, string> = {
        start: new Date().toISOString(),
    };

    if (search) {
        params.search = search;
    }

    if (sorter?.field && sorter?.order) {
        params.sortField = sorter.field;
        params.sortOrder = sorter.order;
    }

    const path = `/scheduling-coordinator/caiso/resource-bids?${new URLSearchParams(params)}`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot get caiso resource bids data!`);
};
