import Alert from 'antd/lib/alert';
import Button from 'antd/lib/button';
import Form from 'antd/lib/form';
import Modal from 'antd/lib/modal';
import { RequiredMFA } from './requiredMFA';

interface IRequiredConfirmationModal {
    onSubmit: Function;
    onCloseModal: any;
    required: string[];
    title?: string;
    resendSettings: {
        email: string;
        uuid: string;
        updateUuid: (uuid: string) => void;
        infoMessage?: string;
    };
    [x: string]: any;
}

export const RequiredConfirmationModal = ({
    onSubmit,
    onCloseModal,
    required,
    resendSettings,
    title = 'Submit 2-Factor Authentication',
    ...rest
}: IRequiredConfirmationModal) => {
    const { error = '' } = rest;
    const [form] = Form.useForm();

    const onFormFinish = (confirmationData: any) => {
        onSubmit(confirmationData);
    };

    return (
        <Modal
            open
            title={title}
            onCancel={onCloseModal}
            data-cy="required-confirmation-modal"
            footer={
                <Button key="required-confirmation-submit" data-cy="submit-required-confirmation-modal" type="primary" onClick={form.submit}>
                    Submit
                </Button>
            }
        >
            <Form form={form} layout="vertical" onFinish={onFormFinish}>
                {error && <Alert showIcon type="error" message={error} style={{ marginBottom: '8px' }} />}
                {required.includes('mfa') && <RequiredMFA resendSettings={resendSettings} />}
            </Form>
        </Modal>
    );
};
