import React, { useEffect, useMemo, useState } from 'react';
import { Dictionary, groupBy, isEqual, orderBy } from 'lodash';
import Radio, { RadioChangeEvent } from 'antd/lib/radio';
import Spin from 'antd/lib/spin';
import Empty from 'antd/lib/empty';
import Tooltip from 'antd/lib/tooltip';
import { theme } from 'antd/lib';
import { usePrevious } from '../../usePrevious';
import { fetchSites } from '../../../domain/site';
import { ISite } from '../../../domain/site/interface';
import { PRICE_RESPONSE_MARKETS_DATA } from '../../../domain/price-response/interface';
import { toAllowedStringLength } from '../../../domain/common/formattersToAllowedValueLength';
import './price-response-target-sites-selector.css';
import { marketLoadZoneToLabel } from '../../site/SiteMarketAndLoadZone';

interface ISelectOption {
    value: string;
    label: string | React.ReactNode;
    sites: ISite[];
    lmp_market?: string;
    site_load_zone?: string;
}

const PRICE_RESPONSE_MARKETS = Object.keys(PRICE_RESPONSE_MARKETS_DATA);
const PRICE_RESPONSE_LOAD_ZONES = Object.values(PRICE_RESPONSE_MARKETS_DATA).flat(1);

interface IPriceResponseTargetSitesSelectorProps {
    companyId: number;
    marketAndZone: string[];
    onChange: Function;
    hasError: boolean;
}

export const PriceResponseTargetSitesSelector: React.FC<IPriceResponseTargetSitesSelectorProps> = ({
    companyId,
    marketAndZone,
    onChange,
    hasError,
}) => {
    const { token: themeColors } = theme.useToken();

    const prevValue = usePrevious(marketAndZone);
    const [selectedValue, setSelectedValue] = useState<string | null>(null);

    const { options, loading } = usePriceResponseGroupedSites({ companyId });
    const sortedOptions = useMemo(
        () =>
            orderBy(
                options,
                [
                    option => option.value === selectedValue, // selected value first
                    option => option.sites.length, // then sort by number of sites
                    option => option.value, // then sort alphabetical by value
                ],
                ['desc', 'desc', 'asc']
            ),
        [selectedValue, options]
    );

    const handleChange = (e: RadioChangeEvent) => {
        const value = e.target.value;
        const selectedOption = options.find(option => option.value === value);

        setSelectedValue(value);
        if (!selectedOption) {
            onChange([]);
        }

        onChange([selectedOption!.lmp_market!.toUpperCase(), selectedOption!.site_load_zone]);
    };

    useEffect(() => {
        if (!isEqual(prevValue, marketAndZone)) {
            const newValue = marketAndZone.length === 0 ? null : marketAndZone.join(' / ');
            setSelectedValue(newValue);
        }
    }, [prevValue, marketAndZone]);

    if (loading) {
        return (
            <div className="price-response-target-sites-selector loader-wrapper">
                <Spin spinning size="large" />
            </div>
        );
    }

    if (sortedOptions.length === 0) {
        return <Empty description="No sites enrolled to Price Response" />;
    }

    return (
        <Radio.Group onChange={handleChange} value={selectedValue} className="price-response-target-sites-selector wrapper">
            <div className="price-response-target-sites-selector radio-group-options-wrapper" style={{ borderColor: hasError ? 'red' : '#d9d9d9' }}>
                {sortedOptions.map(option => (
                    <Radio
                        key={option.value}
                        value={option.value}
                        className="price-response-target-sites-selector radio-item"
                        style={{
                            border: `1px solid ${option.value === selectedValue ? themeColors.colorPrimary : '#d9d9d9'}`,
                        }}
                    >
                        {option.label}
                    </Radio>
                ))}
            </div>
        </Radio.Group>
    );
};

function usePriceResponseGroupedSites({ companyId }: { companyId?: number }): {
    options: ISelectOption[];
    loading: boolean;
} {
    const [options, setOptions] = useState<ISelectOption[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            setLoading(true);

            const { data } = await fetchSites({
                pagination: { pageSize: 10000, current: 1 },
                company_id: companyId,
            });

            // Filter out sites without market or zone
            let sites: ISite[] = data.filter(site => site.lmp_market && site.site_load_zone);

            // Filter out sites from not supported markets or zones
            sites = sites.filter(
                site =>
                    PRICE_RESPONSE_MARKETS.includes(site.lmp_market?.toUpperCase()!) &&
                    PRICE_RESPONSE_LOAD_ZONES.includes(site.site_load_zone.toUpperCase())
            );

            // Sites grouped by market/zone
            const result: Dictionary<ISite[]> = groupBy(sites, site =>
                [site.lmp_market?.toUpperCase(), marketLoadZoneToLabel(site.lmp_market, site.site_load_zone)].join(' / ')
            );
            const sortedMarketZones = orderBy(Object.keys(result), [record => result[record].length, record => record.toString()], ['desc', 'desc']);

            const newOptions = sortedMarketZones.map(groupName => transformSiteGroupToOption(groupName, result));

            setOptions(newOptions);
            setLoading(false);
        })();
    }, [companyId]);

    return {
        loading,
        options,
    };
}

function displaySiteNames(sites: ISite[]) {
    return sites.slice(0, 5).map((site, index) => (
        <span key={index} style={{ display: 'block', whiteSpace: 'nowrap' }}>
            <Tooltip title={site.site_name} placement="right">
                {index + 1}. {toAllowedStringLength(site.site_name, 18)}
            </Tooltip>
        </span>
    ));
}

function transformSiteGroupToOption(groupName: string, sitesDict: Dictionary<ISite[]>) {
    const sites: ISite[] = sitesDict[groupName];
    const site = sites[0];
    const sitesLength = sites.length;

    return {
        value: groupName,
        label: (
            <>
                <b>{groupName}</b>
                <br />
                <div>
                    {displaySiteNames(sites)}
                    <small>{sitesLength > 5 ? `And ${sitesLength - 5} more...` : ''}</small>
                </div>
            </>
        ),
        sites: sitesDict[groupName],
        lmp_market: site?.lmp_market,
        site_load_zone: site?.site_load_zone,
    };
}
