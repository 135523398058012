import uniq from 'lodash/uniq';

interface IRowData {
    product_id: string;
    program_id: number;
    program_name?: string;
    product_name?: string;
}

const validateData = (column: IRowData) => {
    const rowErrors: string[] = [];

    // length of salesforce_id === 18
    if (column.product_id?.length !== 18) {
        rowErrors.push(`A product ID should not be empty and must have 18 characters!`);
    }

    if (isNaN(column.program_id) || column.program_id <= 0 || !Number.isInteger(column.program_id)) {
        rowErrors.push('A program ID should be a positive integer!');
    }

    return rowErrors;
};

const transformParsedRowToData = (columns: string[]): IRowData => {
    const [productId, programId, programName, productName] = columns;

    return {
        product_id: productId,
        program_id: programId ? Number(programId) : NaN,
        program_name: programName,
        product_name: productName,
    };
};

export const parserProgramToProductRelationsCsvData = (content: string) => {
    const errors: string[] = [];
    const result: any = [];
    const rows = content.split('\n').filter(row => row);

    // skip header
    rows.shift();

    if (!rows.length) {
        errors.push('File should contain data!');
    }

    rows.forEach((row, rowIndex) => {
        const rowContent = row
            .replace(/[\r\n]+/gm, '')
            .replaceAll('"', '')
            .split(',');
        const rowData = transformParsedRowToData(rowContent);
        const rowErrors = validateData(rowData);

        if (rowErrors?.length) {
            errors.push(`In row ${rowIndex + 1}: ${rowErrors?.join('')} `);
            return;
        }

        result.push(rowData);
    });

    const uniqProductIds: string[] = uniq(result.map((row: IRowData) => row.product_id));

    uniqProductIds.forEach((productId: string) => {
        const rowsWithSameProductIds = result.filter(({ product_id }: IRowData) => product_id === productId);

        if (rowsWithSameProductIds.length > 1) {
            errors.push(`A product with ID - ${productId} has duplicates in file!`);
        }
    });

    return [errors, result];
};
