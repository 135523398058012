import { useQuery } from '@tanstack/react-query';

import { fetchCaisoLocationFiltersList, fetchCaisoLocations } from '.';
import { QueryKey } from 'src/query-client';
import { IFetchPageQuery } from 'src/domain/IFetchQueryOptions';

export const useCaisoLocationNamesListQuery = () => {
    return useQuery({
        queryKey: [QueryKey.DRRS_LOCATION_NAMES],
        queryFn: () => fetchCaisoLocationFiltersList('name'),
    });
};

export const useCaisoLocationsListQuery = (query: IFetchPageQuery, options = {}) => {
    return useQuery({
        queryKey: [QueryKey.DRRS_LOCATIONS, query],
        queryFn: () => fetchCaisoLocations(query),
        ...options,
    });
};

export const useCaisoLocationSanListQuery = () => {
    return useQuery({
        queryKey: [QueryKey.DRRS_LOCATION_SANS],
        queryFn: () => fetchCaisoLocationFiltersList('san'),
    });
};

export const useCaisoLocationStatusListQuery = () => {
    return useQuery({
        queryKey: [QueryKey.DRRS_LOCATION_STATUSES],
        queryFn: () => fetchCaisoLocationFiltersList('status'),
    });
};

export const useCaisoLocationUdcListQuery = () => {
    return useQuery({
        queryKey: [QueryKey.DRRS_LOCATION_UDCS],
        queryFn: () => fetchCaisoLocationFiltersList('udc'),
    });
};

export const useCaisoLocationSubLapListQuery = () => {
    return useQuery({
        queryKey: [QueryKey.DRRS_LOCATION_SUBLAPS],
        queryFn: () => fetchCaisoLocationFiltersList('sub_lap'),
    });
};

export const useCaisoLocationResourceIdsListQuery = () => {
    return useQuery({
        queryKey: [QueryKey.DRRS_LOCATION_RESOURCE_NAMES],
        queryFn: () => fetchCaisoLocationFiltersList('resource_id'),
    });
};
