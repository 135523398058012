import { useState } from 'react';
import uniqBy from 'lodash/uniqBy';

export const useTableSelection = (selectionKey: string) => {
    const [selectedKeys, setSelectedKeys] = useState<number[]>([]);
    const [selectedItems, setSelectedItems] = useState<any[]>([]);

    const handleSelectedItems = (keys: number[], items: any[]) => {
        if (!keys.length) {
            setSelectedKeys([]);
            setSelectedItems([]);
            return;
        }

        setSelectedKeys(keys);
        const newSelectedItems = items
            .map((item: any) => {
                const isSelectedItem = keys.includes(item[selectionKey]);
                return isSelectedItem && item;
            })
            .filter(Boolean);
        const newState = [...selectedItems, ...newSelectedItems].filter((item: any) => keys.includes(item[selectionKey]));
        setSelectedItems(uniqBy(newState, selectionKey));
    };

    return { selectedKeys, selectedItems, handleSelectedItems };
};
