import config from '../../config';
import { parseGooglePlace } from '../location';

interface IResults {
    address_components?: google.maps.GeocoderAddressComponent[];
}

interface IGeoCodeInfo {
    results: IResults[];
}

enum allowedCountries {
    USA = 'United States',
}
export class GeoCodeProvider {
    static async getAddressByLocation(lat: number, lng: number): Promise<string | null> {
        const geo_code_url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${config.GOOGLE_MAP_API}`;
        const response = await fetch(geo_code_url, {});

        if (response.status !== 200) {
            return null;
        }

        const body: IGeoCodeInfo = await response.json();
        if (body.results && body.results.length > 0) {
            const { address_components } = body.results[0];
            const parsed = await parseGooglePlace({ address_components });
            if (parsed && Object.values(allowedCountries).includes(parsed.site_country as allowedCountries)) {
                return `${parsed.site_city},${parsed.site_state}`;
            }
            return null;
        }

        return null;
    }
}
