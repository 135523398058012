import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import DownloadOutlined from '@ant-design/icons/DownloadOutlined';
import PlusCircleOutlined from '@ant-design/icons/PlusCircleOutlined';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import Button from 'antd/lib/button';
import Table, { ColumnsType } from 'antd/lib/table';
import App from 'antd/lib/app';
import Tag from 'antd/lib/tag';
import { PageHeader } from 'src/components/pageHeader/pageHeader';
import { ActionList } from 'src/components/table/actionList/actionList';
import { WithEmptyDataTable } from 'src/components/table/withEmptyDataTable';
import { useFormMode, FORM_MODE } from 'src/components/useFormMode';
import { fetchCaisoResources, exportCaisoResources, deleteCaisoResource } from 'src/domain/caiso-resource';
import { ICaisoResource } from 'src/domain/caiso-resource/interface';
import useAbortableFetch from 'src/domain/useAbortableFetch';
import { DownloadXMLModal } from '../../../components/downloadXMLModal';
import { ResourceFormModal } from '../../../components/resourceFormModal';
import {
    ICaisoResourceData,
    RESOURCE_ACTION,
    generateDatePeriod,
    validateRequiredMWValue,
    month,
    RESOURCE_PAGE_ACTION,
    caisoSibrViewOptions,
} from '../../../interface';
import { AbilityContext } from '../../../../../components/ability/can';
import './SibrConfigurationView.css';
import { CAISO_SIBR_VIEW } from 'src/pages/interface';
import { sortingComparator } from 'src/domain/common/sortingComparator';

interface SibrConfigurationViewProps {
    view: CAISO_SIBR_VIEW;
    handleView: (view: CAISO_SIBR_VIEW) => void;
}

export const SibrConfigurationView = ({ view, handleView }: SibrConfigurationViewProps) => {
    const { notification, modal } = App.useApp();
    const ability = useContext(AbilityContext);
    const location = useLocation();

    const [caisoResources, setCaisoResources] = useState<ICaisoResource[]>([]);
    const [loadingResources, setLoadingResources] = useState(false);
    const [resource, setResource] = useState<ICaisoResourceData | Partial<ICaisoResourceData>>();
    const [resourceAction, setResourceAction] = useState<RESOURCE_ACTION>();
    const [exportLoading, setExportLoading] = useState<boolean>(false);

    const { onSetFormMode, isEditMode } = useFormMode();
    const [abortableFetchCaisoResources] = useAbortableFetch(fetchCaisoResources);

    const fetchCaisoResourcesAction = useCallback(async () => {
        setLoadingResources(true);
        try {
            const { data } = await abortableFetchCaisoResources({ pagination: { pageSize: 10000, current: 1 } });

            setCaisoResources(data);
        } catch (error: any) {
            if (error.name === 'AbortError') return;
            notification.error({ key: 'caiso-resources-fetch-error', message: 'Cannot fetch caiso resources!' });
        } finally {
            setLoadingResources(false);
        }
    }, [abortableFetchCaisoResources, notification]);

    useEffect(() => {
        (async () => {
            await fetchCaisoResourcesAction();
        })();
    }, [location, fetchCaisoResourcesAction]);

    const exportCaisoSCAction = async () => {
        try {
            setExportLoading(true);
            await exportCaisoResources();
        } catch (error: any) {
            notification.error({ key: 'resource-export-error', message: error.message || 'Cannot export resource!' });
        }
        setExportLoading(false);
    };

    const showResourceActionModal = (record: ICaisoResourceData, action: FORM_MODE) => () => {
        onSetFormMode(action);
        setResource(record);
    };

    const onResourceFormModalClose = async (resource: any) => {
        setResource(undefined);

        if (resource) {
            await fetchCaisoResourcesAction();
        }
    };

    function deleteResourceWithConfirm(record: ICaisoResource) {
        return () => {
            modal.confirm({
                icon: <ExclamationCircleOutlined />,
                content: `Deleting resource ${record.name}. Are you sure?`,
                onOk() {
                    deleteResourceAction(record);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        };
    }

    const deleteResourceAction = async (resource: ICaisoResource) => {
        try {
            await deleteCaisoResource(resource);
            notification.info({ key: 'caiso-resource-delete-info', message: 'Caiso resource deleted' });
            await fetchCaisoResourcesAction();
        } catch (error: any) {
            notification.error({
                key: 'caiso-resource-delete-error',
                message: error.message || 'Cannot delete Caiso resource!',
            });
        }
    };

    const monthsPeriod = generateDatePeriod();

    const showNewCaisoResourceModal = () => {
        onSetFormMode(FORM_MODE.NEW);
        setResource({
            id: undefined,
            name: '',
            pmin: undefined,
            pmax: undefined,
            months: Object.fromEntries(monthsPeriod.map(month => [month, null])),
        });
    };

    const actions = [
        ...(ability.can('update', 'CaisoSIBR')
            ? [
                  {
                      title: 'Edit',
                      onClick: (record: any) => showResourceActionModal(record, FORM_MODE.EDIT),
                  },
              ]
            : []),
        ...(ability.can('delete', 'CaisoSIBR')
            ? [
                  {
                      title: 'Delete',
                      onClick: (record: any) => deleteResourceWithConfirm(record),
                  },
              ]
            : []),
    ];

    const preparedCaisoResources = caisoResources.map(resource => ({
        ...resource,
        months: Object.fromEntries(
            resource.months.map(({ month, mw, heStart, heEnd, lmp, program_id }) => [month, { mw, heStart, heEnd, lmp, program_id }])
        ),
    }));

    const columns: ColumnsType<ICaisoResourceData> = [
        {
            title: 'Resource name',
            dataIndex: 'name',
            width: 140,
            fixed: 'left',
            sorter: (a, b) => sortingComparator(a.name, b.name),
            filters: preparedCaisoResources.map(resource => ({ text: resource.name, value: resource.name })),
            onFilter: (value, record) => record.name.includes(value as string),
            render: (text, record) => {
                const mwValueErrors = validateRequiredMWValue(record.months, Object.values(month));
                return <>{text}</>;
            },
        },
        {
            title: 'pmin',
            dataIndex: 'pmin',
            fixed: 'left',
            width: 60,
        },
        {
            title: 'pmax',
            dataIndex: 'pmax',
            fixed: 'left',
            width: 60,
        },
        ...monthsPeriod.map(month => {
            const formattedMonth = format(new Date(month), 'yyyy/MM');
            const activeResourcesCount = caisoResources.reduce((count, resource) => {
                const isActiveInMonth = resource.months.some(m => m.month === formattedMonth && m.mw > 0);
                return isActiveInMonth ? count + 1 : count;
            }, 0);

            return {
                title: `${format(new Date(month), 'MMM, yyyy')} (${activeResourcesCount})`,
                dataIndex: ['months', formattedMonth],
                width: 120,
                key: `${month}`,
                render: (data: any) => {
                    return (
                        <div>
                            {data && data.mw !== undefined && <div>MW: {data.mw}</div>}
                            {data && data.lmp !== undefined && <div>LMP: {data.lmp}</div>}
                            {data && data.heStart !== undefined && <div>HE Start: {data.heStart}</div>}
                            {data && data.heEnd !== undefined && <div>HE End: {data.heEnd}</div>}
                            {(!data || data.mw <= 0) && <Tag color="volcano">Inactive</Tag>}
                        </div>
                    );
                },
            };
        }),
        ...(ability.can('update', 'CaisoSIBR') || ability.can('delete', 'CaisoSIBR')
            ? [
                  {
                      key: 'action',
                      fixed: 'right' as any,
                      width: 100,
                      render: (text: any, record: any) => <ActionList actions={actions} item={record} />,
                  },
              ]
            : []),
    ];

    return (
        <div className="sibr-configuration-view">
            <PageHeader
                pageTitle={`CAISO SC (${caisoResources.length})`}
                actions={[
                    <Button
                        size="large"
                        key="download-xml-caiso-sc-data"
                        onClick={() => {
                            setResourceAction(RESOURCE_PAGE_ACTION.DOWNLOAD_XML);
                        }}
                        icon={<DownloadOutlined />}
                    >
                        Download XML
                    </Button>,
                    <Button
                        size="large"
                        key="download-caiso-sc-data"
                        onClick={exportCaisoSCAction}
                        icon={<DownloadOutlined />}
                        loading={exportLoading}
                    >
                        Download CSV
                    </Button>,
                    ability.can('create', 'CaisoSIBR') && (
                        <Button size="large" key="new-caiso-sc-row" type="primary" onClick={showNewCaisoResourceModal} icon={<PlusCircleOutlined />}>
                            Add Resource
                        </Button>
                    ),
                ]}
            />
            <WithEmptyDataTable condition={loadingResources}>
                <Table
                    size="small"
                    rowKey="id"
                    sticky
                    scroll={{
                        x: 1300,
                    }}
                    columns={columns}
                    dataSource={preparedCaisoResources}
                    pagination={false}
                    loading={loadingResources}
                />
            </WithEmptyDataTable>

            {resource && <ResourceFormModal onCancel={onResourceFormModalClose} resource={resource} isEditMode={isEditMode} />}
            {resourceAction === RESOURCE_PAGE_ACTION.DOWNLOAD_XML && (
                <DownloadXMLModal onCancel={() => setResourceAction(undefined)} resources={preparedCaisoResources} monthsPeriod={monthsPeriod} />
            )}
        </div>
    );
};
