import DownloadOutlined from '@ant-design/icons/DownloadOutlined';
import App from 'antd/lib/app';
import Button from 'antd/lib/button';
import Radio, { RadioChangeEvent } from 'antd/lib/radio';
import React, { useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { PageHeader } from '../../components/pageHeader/pageHeader';
import { useDocumentTitle } from '../../components/useDocumentTitle';
import { useCompanyListQuery } from '../../domain/company/queries';
import { exportReadingsAvailabilityStats } from '../../domain/readings-availability-stats';
import { DataQualityReportPeriod, DataQualityReportPeriodMap } from '../../domain/readings-availability-stats/interface';
import { ReadingsAvailabilityStatsContext } from '../../domain/readings-availability-stats/ReadingsAvailabilityStatsContext';
import { useDataAvailabilityStatsQueryDates } from '../../domain/readings-availability-stats/useDataAvailabilityStatsQueryDates';
import { useGetFullMonthsFromRange } from '../../domain/readings-availability-stats/useGetFullMonthsFromRange';
import { usePageLocation } from '../usePageState';

interface IPageState {
    period?: DataQualityReportPeriod;
    companyId?: number | null;
}

export const ReadingsAvailabilityStats: React.FC = () => {
    const { notification } = App.useApp();
    const location = useLocation();
    const { queryToState, setPageQuery } = usePageLocation();
    const pageState: IPageState = queryToState(location.search);
    const navigate = useNavigate();

    const [startDate, endDate] = useDataAvailabilityStatsQueryDates();
    const months = useGetFullMonthsFromRange(startDate, endDate);
    const [exportLoading, setExportLoading] = useState<boolean>(false);

    const companyId = pageState.companyId;
    const companiesListQuery = useCompanyListQuery({});
    const isCompaniesLoading = companiesListQuery.isLoading;
    const companies = companiesListQuery.data?.data || [];
    const company = useMemo(() => companies.find(c => c.company_id === companyId) || null, [companyId, companies]);
    const period = pageState.period || DataQualityReportPeriod.fullday;

    async function exportReadingsAvailabilityStatsAction() {
        setExportLoading(true);

        try {
            await exportReadingsAvailabilityStats({
                startDate,
                endDate,
                companyId: company?.company_id,
                period,
            });
        } catch (err: any) {
            notification.error({
                key: 'export-readings-availability-stats',
                message: err.message || 'Cannot export Data Quality Report!',
            });
        }

        setExportLoading(false);
    }

    const handlePeriodChange = (e: RadioChangeEvent) => {
        setPageQuery({ ...pageState, period: e.target.value });
    };

    useDocumentTitle('Data Quality Report');

    useEffect(() => {
        if (isCompaniesLoading || !navigate) return;

        /** Redirect rules:
         * 1. If no company selected, we redirect to statistics by companies
         * 2. If global company selected, we redirect to statistics by enrollments
         * */
        if (location.pathname === '/admin/data-quality/by-enrollment' && !company) {
            navigate(`/admin/data-quality/by-company?period=${period}`);
        }

        if (location.pathname === '/admin/data-quality/by-company' && company) {
            navigate(`/admin/data-quality/by-enrollment?companyId=${company.company_id}&period=${period}`);
        }
    }, [company, location.pathname, navigate, isCompaniesLoading, period]);

    return (
        <ReadingsAvailabilityStatsContext.Provider
            value={{
                company,
                companies,
                isCompaniesLoading,
                startDate,
                endDate,
                months,
                period,
            }}
        >
            <PageHeader
                pageTitle="Data quality report"
                actions={[
                    <Button
                        size="large"
                        key="download-csv"
                        onClick={exportReadingsAvailabilityStatsAction}
                        icon={<DownloadOutlined />}
                        loading={exportLoading}
                    >
                        Download CSV
                    </Button>,
                ]}
                extra={[
                    <Radio.Group buttonStyle="solid" value={period} onChange={handlePeriodChange}>
                        <Radio.Button value={DataQualityReportPeriod.fullday}>
                            {DataQualityReportPeriodMap[DataQualityReportPeriod.fullday]}
                        </Radio.Button>
                        <Radio.Button value={DataQualityReportPeriod.keyhours}>
                            {DataQualityReportPeriodMap[DataQualityReportPeriod.keyhours]}
                        </Radio.Button>
                    </Radio.Group>,
                ]}
            />
            <Outlet />
        </ReadingsAvailabilityStatsContext.Provider>
    );
};
