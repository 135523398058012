import Card from 'antd/lib/card';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import Skeleton from 'antd/lib/skeleton';
import { add, endOfDay, startOfDay } from 'date-fns';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AbilityContext } from '../../components/ability/can';
import { useCompanies } from '../../components/selectors/GlobalCompanySelector/CompanyContext';
import { useDocumentTitle } from '../../components/useDocumentTitle';
import { ISite, ISiteLocation } from '../../domain/site/interface';
import { usePageLocation } from '../usePageState';
import { ImpactInformation } from './components/impact/impactInformation';
import { MemoizedSitesMap } from './components/sitesMap/sitesMap';
import { WeatherForecast } from './components/weatherForecast/weatherForecast';
import { EventsDateWidget } from './EventsDateWidget';
import { useFetchMapLocations } from './useFetchMapLocations';
import './dashboard.css';
import { GeoCodeProvider } from 'src/domain/geocode/geoCode';
import notification from 'antd/lib/notification';
import { useAuth } from '../../domain/auth/useAuth';

export const DATE = {
    today: {
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
    },
    tomorrow: {
        start: startOfDay(add(new Date(), { days: 1 })),
        end: endOfDay(add(new Date(), { days: 1 })),
    },
};

const getInitialCoordinates = (locations: ISiteLocation[]) => {
    const CALIFORNIA_COORDINATES = {
        lat: 36.7783,
        lng: -119.4179,
    };

    if (!locations || locations?.length === 0) {
        return CALIFORNIA_COORDINATES;
    }

    return { lat: locations[0]?.site_lat, lng: locations[0]?.site_long };
};

export function Dashboard() {
    const urlLocation = useLocation();
    const { setPageQuery, queryToState } = usePageLocation();
    const pageState: any = queryToState(urlLocation.search);

    const isActiveEventsMap = useRef(new Map<string, boolean>());

    const [locationValue, setLocationValue] = useState<string | null>(null);

    const { companyId } = useCompanies()!;
    const auth = useAuth();
    const ability = useContext(AbilityContext);

    const { locations, isLoading } = useFetchMapLocations(companyId);

    useDocumentTitle('Dashboard');

    const onSiteChange = (site: ISite) => {
        if (!site) return;

        const coordinates = { lat: site?.site_lat, lng: site?.site_long };
        setPageQuery({ ...pageState, location: coordinates });
    };

    const onMapLocationChange = (mapCenter: { lat: number; lng: number }) => {
        setPageQuery({ ...pageState, location: mapCenter });
    };

    const onActiveEventsHandle = (field: string) => (value: boolean) => isActiveEventsMap.current.set(field, value);

    useEffect(() => {
        isActiveEventsMap.current.clear();
    }, [companyId]);

    useEffect(() => {
        if (!pageState.location && !isLoading) {
            const coordinates = getInitialCoordinates(locations);
            setPageQuery({ ...pageState, location: coordinates });
        }
    }, [locations]);

    useEffect(() => {
        if (pageState.location && pageState.location.lat && pageState.location.lng) {
            (async () => {
                try {
                    const data = await GeoCodeProvider.getAddressByLocation(pageState.location.lat, pageState.location.lng);
                    setLocationValue(data);
                } catch (error: any) {
                    notification.error({
                        key: 'fetch-geocode-data-error',
                        message: error.message || 'Cannot fetch geocode data!',
                    });
                }
            })();
        }
    }, [pageState.location]);

    const locationLabel = useMemo(() => {
        return locationValue ? `(${locationValue})` : '';
    }, [locationValue]);

    return (
        <Row key="" className="dashboard-page" gutter={[16, 16]} data-cy="dashboard-page">
            <Col xs={24} md={12} key="weather-forecast">
                <Card title={`Energy Program Weekly Forecast ${locationLabel} `}>
                    {isLoading ? (
                        <Skeleton loading paragraph={{ rows: 5 }} active />
                    ) : (
                        <WeatherForecast location={pageState.location} isActiveEvent={isActiveEventsMap.current} />
                    )}
                    {companyId && (
                        <Row gutter={[14, 16]}>
                            <Col xs={24} md={12}>
                                <EventsDateWidget
                                    title="Today's Events"
                                    date={DATE.today}
                                    company={companyId}
                                    onActiveEventsHandle={onActiveEventsHandle('today')}
                                />
                            </Col>
                            <Col xs={24} md={12}>
                                <EventsDateWidget
                                    title="Tomorrow's Events"
                                    date={DATE.tomorrow}
                                    company={companyId}
                                    onActiveEventsHandle={onActiveEventsHandle('tomorrow')}
                                />
                            </Col>
                        </Row>
                    )}
                </Card>
                {ability.can('read', 'ImpactReporting') &&
                    (auth?.user?.isUtilityCustomer() ? (
                        !companyId && <ImpactInformation company={auth?.user?.company_id} />
                    ) : (
                        <ImpactInformation company={companyId} />
                    ))}
            </Col>

            <Col xs={24} md={12} key="sites-map">
                <Card title="Locations" loading={isLoading}>
                    <MemoizedSitesMap siteLocations={locations} onMarkerClick={onSiteChange} onMapLocationChange={onMapLocationChange} />
                </Card>
            </Col>
        </Row>
    );
}
