import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal';
import App from 'antd/lib/app';
import Alert from 'antd/lib/alert';
import { IAffectedPriceResponseTrigger } from '../../../domain/price-response/interface';
import { PriceResponseTriggerCollapsedList } from './PriceResponseTriggerCollapsedList';
import { useDeleteBatchPriceResponseTriggersMutation } from '../../../domain/price-response/queries';

interface IDeleteBatchPriceResponseModal {
    triggerIdList: number[];
    selectedItemList: IAffectedPriceResponseTrigger[];
    onClose: Function;
}

export const DeleteBatchPriceResponseTriggerModal = ({ onClose, triggerIdList, selectedItemList }: IDeleteBatchPriceResponseModal) => {
    const { notification } = App.useApp();

    const { mutateAsync: deleteBatchTriggers, isLoading } = useDeleteBatchPriceResponseTriggersMutation();

    async function batchDeletePriceResponseTriggerAction() {
        try {
            await deleteBatchTriggers(triggerIdList);

            await onClose(triggerIdList);

            notification.info({ key: 'batch-trigger-delete-info', message: 'Price Response triggers deleted' });
        } catch (error: any) {
            notification.error({
                key: 'batch-trigger-delete-error',
                message: error.message || 'Cannot delete Price Response triggers!',
            });
        }
    }

    return (
        <Modal
            open
            destroyOnClose
            title="Delete Price Response Triggers Batch"
            width={600}
            onCancel={() => onClose()}
            footer={[
                <Button key="batch-price-response-modal-cancel" onClick={() => onClose()}>
                    Cancel
                </Button>,
                <Button
                    key="batch-price-response-modal-submit"
                    type="primary"
                    loading={isLoading}
                    onClick={() => batchDeletePriceResponseTriggerAction()}
                >
                    OK
                </Button>,
            ]}
            data-cy="batch-delete-modal"
        >
            <Alert
                message="You are going to delete all selected Price Response Triggers. Are you sure?"
                type="warning"
                style={{ marginBottom: '8px' }}
                showIcon
            />
            <PriceResponseTriggerCollapsedList resources={selectedItemList} />
        </Modal>
    );
};
