import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import { Button, Layout, Typography, Tabs } from 'antd';
import { useLocation } from 'react-router-dom';
import { DoubleLeftOutlined, DoubleRightOutlined, FilterOutlined } from '@ant-design/icons';
import './sidebar.css';
import { useSidebarResize } from './hooks/Sidebar.hook';
import { useSidebar } from './context/SidebarContext';
import { DEFAULT_SIDEBAR_MAX_WIDTH, DEFAULT_SIDEBAR_MIN_WIDTH, DEFAULT_SIDEBAR_WIDTH } from 'src/domain/commonConst';
import SidebarOptionsPanels, { SidebarOptionsPanelsRef } from './SidebarPanels/SidebarPanels';
import { parseQueryParams } from 'src/domain/parseQueryParams';
import { usePageLocation } from 'src/pages/usePageState';
import { toUsefulParams } from 'src/pages/toUsefulParams';
import { useColumns } from '../../layouts/private/context/ColumnsContext';
import SavedTab, { SiteSavedFilterDto } from './SidebarSavedFilter/SidebarSavedFilter';
import SidebarGridFilter from './SidebarGridFilter/SidebarGridFilter';
import SidebarModal from './SidebarModal/SidebarModal';

const { Sider } = Layout;
const { Text } = Typography;

const LOCAL_STORAGE_KEY = 'sidebarGridSettings';

interface SidebarProps {
    minWidth?: number;
    maxWidth?: number;
    defaultWidth?: number;
    onSidebar?: (shouldDisplay: boolean) => void;
}

// TODO move this to a better place like a configuration file
const AllowedPaths: { [key: string]: boolean } = {
    '/sites': true,
};

const Sidebar: React.FC<SidebarProps> = ({
    minWidth = DEFAULT_SIDEBAR_MIN_WIDTH,
    maxWidth = DEFAULT_SIDEBAR_MAX_WIDTH,
    defaultWidth = DEFAULT_SIDEBAR_WIDTH,
    onSidebar,
}) => {
    const { resultAmountValue, config, setConfig, resetAll, filters, options, getFilterItem, resetSelectedFilterId, hasActiveFilters, savedFilters } =
        useSidebar();
    const [collapsed, setCollapsed] = useState(config?.collapsed);
    const [sidebarConfig, setSidebarConfig] = useState<Record<string, any> | null>(null);
    const [activeTab, setActiveTab] = useState('filters');
    const location = useLocation();
    const [openModal, setOpenModal] = useState(false);
    const [filterModal, setFilterModal] = useState<Record<string, string | string[]>>();
    const [itemForUpdate, setItemForUpdate] = useState<SiteSavedFilterDto>({} as SiteSavedFilterDto);
    const [triggerFetchAll, setTriggerFetchAll] = useState(0);
    const siderRef = useRef<HTMLDivElement>(null);
    const sidebarFormRef = useRef<SidebarOptionsPanelsRef>(null);
    const { setPageQuery, queryToState } = usePageLocation();
    const pageState = queryToState(location.search);
    const { width, setIsResizing, resetDefault } = useSidebarResize({ minWidth, maxWidth, defaultWidth });
    const { columns, setColumns, countHiddenColumns } = useColumns();

    // Count of hidden columns of SidebarGridFilter
    const hiddenColumnsCount = countHiddenColumns();

    // Save current grid settings to local storage
    const saveGridSettings = () => {
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(columns));
    };

    // Clear saved grid settings from local storage
    const clearGridSettings = () => {
        localStorage.removeItem(LOCAL_STORAGE_KEY);
        setColumns(prev =>
            prev.map(col => ({
                ...col,
                hidden: false,
            }))
        );
    };

    const [activePanels, setActivePanels] = useState<string[]>([]);

    useEffect(() => {
        // Check if there are any saved keys in the config
        const savedKeys = config?.activePanels || [];
        setActivePanels(savedKeys);
    }, [config]);

    const handlePanelChange = (keys: string[]) => {
        setActivePanels(keys);
        setConfig({ ...config, activePanels: keys });
    };

    const shouldDisplay = useMemo(() => {
        const pathAllowed = AllowedPaths[location.pathname as string];
        onSidebar?.(pathAllowed);
        return pathAllowed;
    }, [location, onSidebar]);

    const handleCollapse = (value: boolean) => {
        setCollapsed(value);
        setConfig({
            ...config,
            collapsed: value,
        });
    };

    const startResizing = useCallback(
        (e: React.MouseEvent) => {
            setIsResizing(true);
            document.body.style.cursor = 'ew-resize';
            document.body.style.userSelect = 'none';
        },
        [setIsResizing]
    );

    const handleOnSave = () => {
        const parsedParams = parseQueryParams(location.search);
        const { filter } = parsedParams;
        setFilterModal(filter);
        setOpenModal(true);

        /*
       const parsedParams = parseQueryParams(location.search);
       setFilterItem(filters.rootPage || 'filters', {
           path: filters.rootPage,
           ...parsedParams,
       });
       */
    };

    const openModalFromSaved = (item: SiteSavedFilterDto) => {
        setItemForUpdate({ ...item });
        setOpenModal(true);
    };

    useEffect(() => {
        if (!sidebarConfig && shouldDisplay) {
            const config = getFilterItem(filters.rootPage || '');
            if (config?.filter) {
                const preparedParams = toUsefulParams({
                    filter: {
                        ...config.filter,
                    },
                });

                setTimeout(() => {
                    setPageQuery({ ...preparedParams });
                }, 100);

                setSidebarConfig(config);
            }
        }
    }, [sidebarConfig, shouldDisplay, getFilterItem, setPageQuery, filters.rootPage]);

    /**
     * Resets the sidebar forms by calling the `resetSidebarForms` method on the `sidebarFormRef` reference.
     * This function is triggered when the 'Clear Filters' is used.
     */
    const handleReset = () => {
        sidebarFormRef.current?.resetSidebarForms();
    };

    const handleOnClear = () => {
        const parsedParams = parseQueryParams(location.search);
        const { companyId } = parsedParams;
        resetAll();
        resetDefault();
        handleReset();
        resetSelectedFilterId(); // Reset the selected filter ID

        if (pageState.pagination?.pageSize) {
            setPageQuery({ search: pageState.search, ...(companyId ? { companyId } : {}), pagination: { pageSize: pageState.pagination.pageSize } });
        } else {
            setPageQuery({ search: pageState.search, ...(companyId ? { companyId } : {}) });
        }
    };

    const SidebarHeader = () => {
        const resultLabel = useMemo(() => {
            return Number(resultAmountValue) > 1 || Number(resultAmountValue) === 0 ? `${resultAmountValue} Results` : `${resultAmountValue} Result`;
        }, []);

        return (
            <div className={`sidebar-header ${collapsed ? 'collapsed' : ''}`}>
                {!collapsed && <Text className="results">{resultLabel}</Text>}
                <div className="collapse-trigger" onClick={() => handleCollapse(!collapsed)}>
                    {!collapsed && <DoubleLeftOutlined className="collapse-trigger-arrow-left" />}
                    <FilterOutlined className="collapse-trigger-filter-icon" />
                    {collapsed && <DoubleRightOutlined className="collapse-trigger-arrow-right" />}
                </div>
            </div>
        );
    };

    const SidebarActions = () => {
        return (
            <div className="sidebar-footer">
                {activeTab === 'filters' && (
                    <Button type="text" onClick={handleOnSave}>
                        Save Filters
                    </Button>
                )}

                <Button type="primary" className={!hasActiveFilters ? 'inactive' : ''} onClick={handleOnClear}>
                    Clear Filters
                </Button>
            </div>
        );
    };

    const handleCancelModal = () => {
        setItemForUpdate({} as SiteSavedFilterDto);
        setOpenModal(false);
    };

    const increaseTriggerFetchAll = () => {
        setTriggerFetchAll(prev => prev + 1);
    };

    return shouldDisplay ? (
        <>
            <Sider
                ref={siderRef}
                trigger={null}
                collapsible
                collapsed={collapsed}
                width={width}
                collapsedWidth={40}
                className={`sidebar ${collapsed ? 'collapsed' : ''}`}
            >
                {/* Header with collapse button */}
                <SidebarHeader />
                {!collapsed && (
                    <div className="sidebar-options-panels-container">
                        {/* Fields and Options filters */}
                        <Tabs
                            defaultActiveKey="filters"
                            activeKey={activeTab}
                            onChange={setActiveTab}
                            tabPosition="top"
                            className="sidebar-tab"
                            items={[
                                {
                                    key: 'filters',
                                    label: 'Filters',
                                    children: !collapsed && (
                                        <SidebarOptionsPanels
                                            ref={sidebarFormRef}
                                            rootPage={filters.rootPage || ''}
                                            filters={filters.props || []}
                                            options={options.props || []}
                                            activeKey={activePanels}
                                            onChange={handlePanelChange}
                                        />
                                    ),
                                },
                                {
                                    key: 'grid',
                                    label: `Grid${hiddenColumnsCount > 0 ? ` (${hiddenColumnsCount})` : ''}`,
                                    children: (
                                        <SidebarGridFilter
                                            rootPage={filters.rootPage || ''}
                                            filters={filters.props || []}
                                            options={options.props || []}
                                            clearGridSettings={clearGridSettings}
                                        />
                                    ),
                                },
                                {
                                    key: 'saved',
                                    label: `Saved (${savedFilters.length})`,
                                    children: <SavedTab openModal={openModalFromSaved} triggerFetchAll={triggerFetchAll} />,
                                },
                            ]}
                        />

                        {/* Sidebar Actions */}
                        {!collapsed && <SidebarActions />}
                    </div>
                )}
                {/* Resizer */}
                {!collapsed && <div className="resizer" onMouseDown={startResizing} />}
            </Sider>
            <SidebarModal
                open={openModal}
                selection={filterModal}
                handleCancel={handleCancelModal}
                handlerConfirm={() => setOpenModal(false)}
                item={itemForUpdate}
                subType={filters.rootPage || ''}
                increaseTriggerFetchAll={increaseTriggerFetchAll}
            />
        </>
    ) : null;
};

export default Sidebar;
