import { sub } from 'date-fns';
import { useQuery } from '@tanstack/react-query';
import { API } from '../api';
import { IPagination } from '../IPagination';
import { IFetchUnknownVenErrorsQuery, IUnknownVenError } from './interface';
import { QueryKey } from '../../query-client';

export const UNKNOWN_VEN_ERROR_LOG_VISIBLE_MINUTES: number = 10;

const fetchUnknownVenErrors = async ({ pagination, sorter }: IFetchUnknownVenErrorsQuery): Promise<IPagination<IUnknownVenError>> => {
    const limit = pagination?.pageSize || 10;
    const offset = (pagination?.current ? pagination.current - 1 : 0) * limit;

    if (sorter && (!sorter.field || !sorter.order)) {
        sorter = {};
    }

    const path = `/unknown-ven-errors?${new URLSearchParams({
        offset: offset.toString(),
        limit: limit.toString(),
        start: sub(new Date(), { minutes: UNKNOWN_VEN_ERROR_LOG_VISIBLE_MINUTES }).toISOString(),
        ...(sorter?.field && { sortField: sorter.field, sortOrder: sorter.order }),
    })}`;

    const response = await API.fetch(path, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error(`Cannot load list of unknown customer VEN errors!`);
    }

    const respBody: any = await response.json();

    if (respBody?.error?.message) {
        throw new Error(respBody?.error?.message);
    }

    return respBody;
};

/****************************** */

export const useUnknownVenErrorsListQuery = (query: IFetchUnknownVenErrorsQuery, options = {}) => {
    return useQuery<IPagination<IUnknownVenError>, Error>([QueryKey.UNKNOWN_VEN_ERRORS, query], () => fetchUnknownVenErrors(query), {
        keepPreviousData: true,
        refetchOnWindowFocus: true,
        ...options,
    });
};
