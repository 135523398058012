import { useEffect, useRef } from 'react';
import { ICarbonIntensity } from '../../../../domain/carbon-intensity/interface';
import { calculateCO2IntensityStyle } from './formatMapStyle';
import { REGIONS } from './regions';
import zonesData from './usaZones.json';

export const useUpdateGoogleMapStyles = (isLoaded: boolean, map: google.maps.Map | null, carbonIntensity: ICarbonIntensity[]) => {
    const carbonIntensityMap = useRef(new Map<string, number>());

    useEffect(() => {
        const isCO2IntensityLayerVisible = isLoaded && map && carbonIntensity.length;
        if (isCO2IntensityLayerVisible) {
            carbonIntensity.forEach((intensity: ICarbonIntensity) => carbonIntensityMap.current.set(intensity.market, intensity.carbonIntensity));
            map.data.addGeoJson(zonesData);
            map.data.setStyle((item: any) => {
                const zoneName = item.getProperty('zoneName');
                const correspondRegion = REGIONS[zoneName] || '';
                const regionCarbonIntensity = carbonIntensityMap.current.get(correspondRegion.toLowerCase());

                return {
                    fillColor: calculateCO2IntensityStyle(regionCarbonIntensity),
                    strokeWeight: 0.5,
                    strokeColor: '#A9A9A9',
                    strokeOpacity: 0.4,
                };
            });
        }
    }, [isLoaded, map, carbonIntensity]);
};
