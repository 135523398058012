import App from 'antd/lib/app';
import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal/Modal';
import Typography from 'antd/lib/typography';
import React from 'react';
import { openSite } from '../../domain/site';
import { ISite } from '../../domain/site/interface';

interface IOpenSiteModalProps {
    site: ISite;
    onCancel: (site?: ISite) => void;
}

export const OpenSiteModal: React.FC<IOpenSiteModalProps> = ({ site, onCancel }) => {
    const { notification } = App.useApp();
    const [loading, setLoading] = React.useState<boolean>(false);

    const handleConfirm = async () => {
        setLoading(true);
        try {
            await openSite(site.site_id);

            onCancel(site);
        } catch (error: any) {
            notification.error({ key: 'open-site-error', message: error.message || 'Cannot open site!' });
        }
        setLoading(false);
    };

    const handleClose = () => {
        onCancel();
    };

    return (
        <Modal
            open
            title="Open Site"
            destroyOnClose
            onCancel={handleClose}
            footer={[
                <Button key="open-site-modal-cancel" onClick={handleClose}>
                    Cancel
                </Button>,
                <Button key="open-site-modal-submit" type="primary" loading={loading} onClick={handleConfirm}>
                    Open Site
                </Button>,
            ]}
        >
            <Typography.Paragraph>You are going to open site. After opening a site will be able to receive events again.</Typography.Paragraph>
        </Modal>
    );
};
