import React, { createContext, useContext, useState, Dispatch, SetStateAction, useEffect } from 'react';
import { ColumnsType } from 'antd/lib/table';

export interface IColumn {
    title: string;
    dataIndex?: string | string[];
    key?: string;
    hidden?: boolean;
}

export interface ColumnsContextType {
    columns: ColumnsType<any>; // Allow Ant Design's column type
    setColumns: Dispatch<SetStateAction<ColumnsType<any>>>;
    countHiddenColumns: () => number; // Function to count hidden columns
}

const defaultValue: ColumnsContextType = {
    columns: [],
    setColumns: () => {
        throw new Error('setColumns called outside of ColumnsProvider');
    },
    countHiddenColumns: () => 0,
};

const ColumnsContext = createContext<ColumnsContextType>(defaultValue);

export const ColumnsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [columns, setColumns] = useState<ColumnsType<any>>([]);

    useEffect(() => {
        const savedSettings = localStorage.getItem('sidebarGridSettings');
        if (savedSettings) {
            const parsedSettings = JSON.parse(savedSettings);
            setColumns(parsedSettings);
        }
    }, []);

    const countHiddenColumns = (): number => {
        return columns.filter(column => (column as IColumn).hidden).length;
    };

    return <ColumnsContext.Provider value={{ columns, setColumns, countHiddenColumns }}>{children}</ColumnsContext.Provider>;
};

export const useColumns = (): ColumnsContextType => {
    const context = useContext(ColumnsContext);
    if (!context) {
        throw new Error('useColumns must be used within a ColumnsProvider');
    }
    return context;
};
