import App from 'antd/lib/app';
import Calendar, { CalendarProps } from 'antd/lib/calendar';
import { add, endOfMonth, isSameMonth, startOfMonth } from 'date-fns';
import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { FC } from 'react';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import { Button } from 'antd';
import LeftOutlined from '@ant-design/icons/lib/icons/LeftOutlined';
import RightOutlined from '@ant-design/icons/lib/icons/RightOutlined';
import classNames from 'classnames';
import { preparePeakEventIndexDays } from '../PeakEventIndexDay';
import { usePeakEventIndexQuery } from 'src/domain/peak-load-forecast/queries';
import { fromZonedTime, toZonedTime } from 'date-fns-tz';
import { PeakLoadForecastMarket } from '../PeakLoadForecastMarket';
import { isSameDayInTimezone, makeDateKey } from 'src/domain/date/date';
import './PeakLoadForecastCalendar.css';

type PeakLoadForecastCalendarProps = {
    date: Date;
    market: PeakLoadForecastMarket;
    onChange: (date: Date) => void;
};

export const PeakLoadForecastCalendar: FC<PeakLoadForecastCalendarProps> = ({ date, market: plfMarket, onChange }) => {
    dayjs.extend(utc);

    const { notification } = App.useApp();

    const start = fromZonedTime(startOfMonth(date), plfMarket.timezone);
    const end = fromZonedTime(endOfMonth(date), plfMarket.timezone);

    const peakEventIndexRes = usePeakEventIndexQuery(
        {
            start,
            end,
            market: plfMarket.name,
            loadZone: plfMarket.loadZone,
        },
        {
            keepPreviousData: false,
        }
    );

    if (peakEventIndexRes.isError) {
        notification.error({
            key: 'plf-peak-event-index-error',
            message: peakEventIndexRes.error.message || 'Cannot load peak event index!',
        });
    }

    const selectedWeekDays = preparePeakEventIndexDays({ start, end, selected: date }, peakEventIndexRes.data, plfMarket);

    function isDateAllowedToSelect(date: Date) {
        const today = new Date();
        const maxAllowedDate = add(today, { days: 8 });
        return date.valueOf() <= maxAllowedDate.valueOf();
    }

    const cellRender: CalendarProps<Dayjs>['fullCellRender'] = (cellDate, info) => {
        if (info.type !== 'date') return info.originNode;

        if (isSameMonth(date, cellDate.toDate()) === false) {
            return undefined;
        }

        const today = isSameDayInTimezone(new Date(), cellDate.toDate(), plfMarket.timezone);

        const selectedDay = selectedWeekDays?.find(day => {
            const dateTimeInTimezone = toZonedTime(day.datetime, plfMarket.timezone);
            const key = `${dateTimeInTimezone.getFullYear()}-${dateTimeInTimezone.getMonth() + 1}-${dateTimeInTimezone.getDate()}`;
            const cellKey = `${cellDate.get('year')}-${cellDate.get('month') + 1}-${cellDate.get('date')}`;
            const isSame = key === cellKey;

            return isSame;
        });

        const isInSelectedWeek = false;

        const dayClass = classNames({
            'peak-load-forecast-calendar--day': true,
            today: today,
            high: !!(selectedDay && selectedDay.eventChance() === 'high'),
            medium: !!(selectedDay && selectedDay.eventChance() === 'medium'),
        });

        return React.cloneElement(info.originNode, {
            ...info.originNode.props,
            className: classNames('peak-load-forecast-calendar-cell-inner', { week: isInSelectedWeek }),
            children: <div className={dayClass}>{cellDate.get('date')}</div>,
        });
    };

    const onPrevMonthClick = (value: Dayjs, onMonthChange: (date: Dayjs) => void) => {
        const prevMonth = value.clone().month(value.month() - 1);
        const startMonth = dayjs(startOfMonth(prevMonth.utc().toDate()));
        onMonthChange(startMonth);
    };

    const onNextMonthClick = (value: Dayjs, onMonthChange: (date: Dayjs) => void) => {
        const nextMonth = value.clone().month(value.month() + 1);

        const selectedDate = startOfMonth(nextMonth.utc().toDate());
        if (isDateAllowedToSelect(selectedDate)) {
            const startMonth = dayjs(selectedDate);
            onMonthChange(startMonth);
        }
    };

    const headerRender: CalendarProps<Dayjs>['headerRender'] = ({ value, type, onChange: onMonthChange, onTypeChange }) => {
        const year = value.format('YYYY');
        const month = value.format('MMMM');

        return (
            <Row justify="space-between" gutter={8} style={{ padding: 8 }}>
                <Col>
                    <Button type="text" icon={<LeftOutlined />} onClick={() => onPrevMonthClick(value, onMonthChange)} />
                </Col>
                <Col style={{ lineHeight: '32px' }}>{month + ' ' + year}</Col>
                <Col>
                    <Button type="text" icon={<RightOutlined />} onClick={() => onNextMonthClick(value, onMonthChange)} />
                </Col>
            </Row>
        );
    };

    const onSelect: CalendarProps<Dayjs>['onSelect'] = (date, selectInfo) => {
        const key = makeDateKey(date.toDate());
        const selectedDate = fromZonedTime(`${key}T00:00:00Z`, plfMarket.timezone);

        isDateAllowedToSelect(selectedDate) && onChange(selectedDate);
    };

    return (
        <Calendar
            className="peak-load-forecast-calendar"
            value={dayjs(date)}
            fullscreen={false}
            fullCellRender={cellRender}
            headerRender={headerRender}
            onSelect={onSelect}
        />
    );
};
