import App from 'antd/lib/app';
import Button from 'antd/lib/button';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Modal from 'antd/lib/modal/Modal';
import Typography from 'antd/lib/typography';
import { ALPHANUMERIC_REGEX } from '../../domain/commonConst';
import { IProgramType } from '../../domain/program-type/interface';
import { useProgramTypeCreate } from '../../domain/program-type/queries';

interface ICreateProgramTypeFormModalProps {
    onClose: Function;
    onFormFinish: Function;
}

export const CreateProgramTypeFormModal = ({ onClose, onFormFinish }: ICreateProgramTypeFormModalProps) => {
    const { notification } = App.useApp();
    const [form] = Form.useForm<IProgramType>();
    const { mutate: createProgramType, isLoading, isError, error } = useProgramTypeCreate();

    if (isError) {
        notification.error({
            key: 'program-type-create-error',
            message: error.message || 'Cannot create program type!',
        });
    }

    const onProgramTypeModalFormFinish = (values: IProgramType) => {
        createProgramType(values, {
            onSuccess: () => {
                onFormFinish();
            },
        });
    };

    const closeProgramDialog = () => {
        onClose();
    };

    return (
        <Modal
            open
            destroyOnClose
            title="Create new Type"
            onCancel={closeProgramDialog}
            footer={[
                <Button key="program-modal-cancel" onClick={closeProgramDialog}>
                    Cancel
                </Button>,
                <Button key="program-modal-submit" type="primary" loading={isLoading} onClick={form.submit}>
                    Submit
                </Button>,
            ]}
        >
            <Form form={form} name="program-form" preserve={false} layout="vertical" onFinish={onProgramTypeModalFormFinish}>
                <Form.Item
                    name="name"
                    label={<Typography.Text strong>Type Name</Typography.Text>}
                    rules={[
                        { required: true, message: 'Please enter type name!' },
                        { max: 150, message: 'Number of characters should be less than 150' },
                    ]}
                >
                    <Input placeholder="Name" size="large" />
                </Form.Item>

                <Form.Item
                    name="id"
                    label={<Typography.Text strong>Type Id</Typography.Text>}
                    rules={[
                        { required: true, message: 'Please enter type id!' },
                        { max: 32, message: 'Number of characters should be less or equal to 32' },
                        { pattern: ALPHANUMERIC_REGEX, message: 'Only alphanumeric characters and dash is allowed' },
                    ]}
                >
                    <Input placeholder="Id" size="large" />
                </Form.Item>
            </Form>
        </Modal>
    );
};
