import App from 'antd/lib/app';
import { useAuth } from '../../domain/auth/useAuth';
import { exportProgramToProductRelations, exportPrograms } from '../../domain/program';
import { ExportButton } from '../ExportButton/ExportButton';

interface IProgramExportButton {
    filter: { [key: string]: string };
}

export const ProgramExportButton = ({ filter, ...props }: IProgramExportButton) => {
    const { notification } = App.useApp();
    const auth = useAuth()!;

    const exportProgramsAction = async () => {
        try {
            await exportPrograms(filter);
        } catch (err: any) {
            notification.error({ key: 'programs-export-error', message: err.message || 'Cannot export programs!' });
        }
    };

    const exportProgramToProductRelationAction = async () => {
        try {
            await exportProgramToProductRelations();
        } catch (error: any) {
            notification.error({
                key: 'program-to-product-relation-export-error',
                message: error.message || 'Cannot export product to programs links!',
            });
        }
    };

    const actions = [
        {
            key: 'e-b-1',
            title: 'Download CSV',
            fn: exportProgramsAction,
        },
    ];

    if (auth.user?.isAdmin()) {
        actions.push({
            key: 'e-b-2',
            title: 'Download Links between Salesforce Products and DRMS Programs',
            fn: exportProgramToProductRelationAction,
        });
    }

    return <ExportButton actions={actions} {...props} />;
};
