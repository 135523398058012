import { useMutation, useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../query-client';
import { IFetchPageQuery } from '../IFetchQueryOptions';
import {
    deleteBatchPriceResponseTriggers,
    deletePriceResponseTrigger,
    fetchPriceResponseTriggers,
    savePriceResponseTrigger,
    updateBatchTriggers,
} from './index';
import { IBatchUpdatePriceResponseTrigger, IFetchPriceResponseReportingQuery, IPriceResponseMetric, IPriceResponseTrigger } from './interface';
import { fetchPriceResponseReportingData } from './reporting';

export const usePriceResponseTriggerListQuery = (query: IFetchPageQuery, options = {}) => {
    return useQuery({
        queryKey: [QueryKey.PRICE_RESPONSE_TRIGGERS, query],
        queryFn: () => fetchPriceResponseTriggers(query),
        ...options,
    });
};

export const useSavePriceResponseTriggerMutation = () => {
    return useMutation<IPriceResponseTrigger, Error, IPriceResponseTrigger>({
        mutationFn: trigger => savePriceResponseTrigger(trigger),
    });
};

export const useUpdateBatchPriceResponseTriggersMutation = () => {
    return useMutation<void, Error, { idList: number[]; updatedFields: IBatchUpdatePriceResponseTrigger }>({
        mutationFn: ({ idList, updatedFields }) => updateBatchTriggers(idList, updatedFields),
    });
};

export const useDeletePriceResponseTriggerMutation = () => {
    return useMutation<void, Error, IPriceResponseTrigger>({
        mutationFn: trigger => deletePriceResponseTrigger(trigger),
    });
};

export const useDeleteBatchPriceResponseTriggersMutation = () => {
    return useMutation<void, Error, number[]>({
        mutationFn: idList => deleteBatchPriceResponseTriggers(idList),
    });
};
export const usePriceResponseReportQuery = (query: IFetchPriceResponseReportingQuery, options = {}) => {
    return useQuery<IPriceResponseMetric, Error>({
        queryKey: [QueryKey.PRICE_RESPONSE_REPORTING, query],
        queryFn: () => fetchPriceResponseReportingData(query),
        ...options,
    });
};
