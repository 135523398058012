import Card from 'antd/lib/card';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import Skeleton from 'antd/lib/skeleton';
import { useContext } from 'react';
import { useLocation, useOutletContext } from 'react-router-dom';
import { AbilityContext } from '../../../components/ability/can';

import { PageHeader } from '../../../components/pageHeader/pageHeader';
import { IView } from '../../../components/viewSwitcher/viewInterface';
import { ViewSwitcher } from '../../../components/viewSwitcher/ViewSwitcher';
import { useVenOverviewQuery } from '../../../domain/ven/queries';
import { usePageLocation } from '../../usePageState';
import { OverviewPieChart } from '../components/charts/OverviewPieChart';
import { OverviewStackedChart } from '../components/charts/OverviewStackedChart';
import { UnknownVenErrorsTable } from './UnknownVenErrorsTable';
import { viewOptions } from './venViewInterface';

export const OverviewView = () => {
    const ability = useContext(AbilityContext);
    const { auth, view, handleViewMode }: IView = useOutletContext();

    const location = useLocation();
    const { queryToState } = usePageLocation();
    const pageState: any = queryToState(location.search);

    const { data: overview, isLoading } = useVenOverviewQuery({
        ...(pageState?.companyId && { companyId: pageState.companyId }),
    });

    const renderContent = () =>
        overview && (
            <Col span={24}>
                <Row justify="center">
                    <Col xs={24} sm={12} data-cy="overview-customer-ven-chart">
                        <OverviewPieChart overviewData={overview?.customerVen.total} title="Customer VEN" />
                    </Col>
                    {auth?.user?.isAdminRoleType() && (
                        <Col xs={24} sm={12} data-cy="overview-cloud-ven-chart">
                            <OverviewPieChart overviewData={overview.cloudVen!.total} title="Cloud VEN" />
                        </Col>
                    )}
                </Row>
                <Col xs={24} data-cy="overview-ven-chart-by-company">
                    <OverviewStackedChart overviewData={overview.customerVen.byCompany} title="By company" />
                </Col>
                <Col xs={24} data-cy="overview-ven-chart-by-control-provider">
                    <OverviewStackedChart overviewData={overview.customerVen.byControlProvider} title="By Control Provider" />
                </Col>
                {auth?.user?.isAdminRoleType() && (
                    <Col xs={24} data-cy="ven-error-table">
                        <UnknownVenErrorsTable />
                    </Col>
                )}
            </Col>
        );

    return (
        <>
            <PageHeader
                pageTitle="VEN"
                extra={[<ViewSwitcher viewOptions={viewOptions(ability!)} key="ven-view-switcher" view={view} handleViewMode={handleViewMode} />]}
            />
            <Card size="small">
                {!isLoading ? (
                    renderContent()
                ) : (
                    <>
                        <Skeleton loading={isLoading} paragraph active />
                        <Skeleton loading={isLoading} paragraph active />
                    </>
                )}
            </Card>
        </>
    );
};
