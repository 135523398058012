import React, { Fragment } from 'react';
import Divider from 'antd/lib/divider';
import Flex from 'antd/lib/flex';
import Typography from 'antd/lib/typography';
import { EVENT_COLORS } from '../../../domain/commonConst';
import { EventLegendListItem } from './EventLegendListItem';

interface IEventLegend {
    title: string;
    background: string;
    abbr?: string;
}

export const eventLegends: IEventLegend[] = [
    { title: 'Event', background: EVENT_COLORS.DEFAULT },
    { title: 'Pre-Event', background: EVENT_COLORS.PRE_EVENT },
    { title: 'Post-Event', background: EVENT_COLORS.POST_EVENT },
    // { title: 'Event OptIn', background: EVENT_COLORS.OPT_IN },
    { title: 'Event Opt Out', background: EVENT_COLORS.OPT_OUT },
    { title: 'Event Cancelled', background: EVENT_COLORS.CANCELLED },
    { title: 'Voluntary', abbr: 'VOLUNTARY', background: EVENT_COLORS.VOLUNTARY },
    { title: 'Emergency', abbr: 'EMERGENCY', background: EVENT_COLORS.EMERGENCY },
    { title: 'Test', abbr: 'TEST', background: EVENT_COLORS.TEST },
    { title: 'Price Response', abbr: 'PRICE RESPONSE', background: EVENT_COLORS.PRICE_RESPONSE },
    { title: 'Clean Response', abbr: 'CLEAN RESPONSE', background: EVENT_COLORS.CLEAN_RESPONSE },
    { title: 'Coincident Peak', abbr: 'COINCIDENT PEAK', background: EVENT_COLORS.COINCIDENT_PEAK },
];

interface IEventLegendListProps {
    vertical?: boolean;
}

export const EventLegendList: React.FC<IEventLegendListProps> = ({ vertical }) => {
    return (
        <Flex vertical={vertical} gap={6}>
            <Typography.Text strong>Legend</Typography.Text>

            {eventLegends.map((legend, index) => (
                <Fragment key={legend.title}>
                    <EventLegendListItem key={index} title={legend.title} background={legend.background} abbr={legend.abbr} />

                    {legend.title === 'Event Cancelled' && <Divider style={{ margin: '8px 0' }} />}
                </Fragment>
            ))}
        </Flex>
    );
};
