import { Market } from '../market-prices/interface';

export enum PeakLoadForecastType {
    FORECAST = 'forecast',
    AVERAGE = 'average',
}

export interface IPeakEventIndex {
    datetime: string;
    loadMw: number;
    eventIndex: number | null;
    forecastEventIndex: number;
    forecastType: PeakLoadForecastType;
    market: Market;
    loadZone: string;
}

export interface IPeakLoad {
    datetime: string;
    loadMw: number;
    market: Market;
    loadZone: string;
}

export interface IPeakLoadForecast {
    datetime: string;
    loadMw: number;
    forecastType: PeakLoadForecastType;
    market: Market;
    loadZone: string;
}

export interface IPeakLoadResult {
    forecast: IPeakLoadForecast[];
    history: IPeakLoad[];
}

export interface IPeakDays {
    peakDay: string; // datetime

    data: IPeakLoad[];
}
