import { isArray } from 'lodash';

/**
 * Sets a value in an object using a dot-notation path
 * @param {Object} obj - The target object
 * @param {string} path - The dot-notation path
 * @param {any} value - The value to set
 */
const setNestedValue = (obj: any, path: string, value: any): void => {
    const keys = path.split('.');
    let params = obj;

    for (let i = 0; i < keys.length - 1; i++) {
        const key = keys[i];
        if (!(key in params)) {
            params[key] = {};
        }
        params = params[key];
    }

    const lastKey = keys[keys.length - 1];
    let val = value.split(',');

    if (isArray(val) && val.length <= 1) {
        val = value;
    }

    // Handle arrays
    if (lastKey in params) {
        if (!isArray(params[lastKey])) {
            params[lastKey] = [params[lastKey]];
        }
        params[lastKey].push(val);
    } else {
        params[lastKey] = val;
    }

    return params;
};

/**
 * Converts URL query parameters into an object, handling nested objects
 * @param {string} queryString - The URL query string (with or without the leading '?')
 * @returns {Object} An object containing the parsed query parameters
 *
 * @example
 * // URL: http://localhost:3000/sites?foo=var&filter.control_provider_id=558&filter.program_id=2033,61
 * const params = parseQueryParams(location.search);
 * // Result:
 * {
 *   foo: "var",
 *   filter: {
 *     control_provider_id: "55",
 *     program_id: ["2033", "61"]
 *   }
 * }
 */
export const parseQueryParams = (queryString: string): Record<string, any> => {
    // Remove the leading '?' if present
    const searchParams = queryString.startsWith('?') ? new URLSearchParams(queryString.substring(1)) : new URLSearchParams(queryString);

    const params: Record<string, any> = {};

    // Iterate through all parameters
    searchParams.forEach((value, key) => {
        setNestedValue(params, key, value);
    });

    return params;
};
