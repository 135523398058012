import { DATA_AVAILABILITY_PERCENTAGES_MAP, TKeyofDataAvailabilityPercentagesMap } from './buildMonthlyReadingsAvailabilityFilter';
import { IGroupedReadingsAvailabilityStat } from './interface';

export function filterReadingsAvailabilityStatsByCompany(
    data: IGroupedReadingsAvailabilityStat[],
    companyId: string | string[]
): IGroupedReadingsAvailabilityStat[] {
    return data.filter(record => {
        if (Array.isArray(companyId)) {
            return companyId.includes(record.companyId!.toString());
        }

        return record.companyId.toString() === companyId;
    });
}

export function filterReadingsAvailabilityStatsByEnrollmentId(
    data: IGroupedReadingsAvailabilityStat[],
    enrollmentId: string | string[]
): IGroupedReadingsAvailabilityStat[] {
    return data.filter(record => {
        if (Array.isArray(enrollmentId)) {
            return enrollmentId.includes(record?.enrollmentId!.toString());
        }

        return enrollmentId === record.enrollmentId;
    });
}

export function filterReadingsAvailabilityStatsByMonthPercentRange(
    data: IGroupedReadingsAvailabilityStat[],
    month: string,
    range: TKeyofDataAvailabilityPercentagesMap | TKeyofDataAvailabilityPercentagesMap[]
): IGroupedReadingsAvailabilityStat[] {
    if (Array.isArray(range)) {
        const ranges = (range as TKeyofDataAvailabilityPercentagesMap[]).map(key => DATA_AVAILABILITY_PERCENTAGES_MAP[key]);
        return data.filter(record => {
            return ranges.some(({ start, end }) => record.stats[month] >= start && record.stats[month] <= end);
        });
    }

    const { start, end } = DATA_AVAILABILITY_PERCENTAGES_MAP[range.toString() as TKeyofDataAvailabilityPercentagesMap];
    return data.filter(record => {
        return record.stats[month] >= start && record.stats[month] <= end;
    });
}
