import { ReactElement, useEffect, useState } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { SibrConfigurationView, SibrOverviewView } from './view';
import { CAISO_SIBR_VIEW } from 'src/pages/interface';
import { CaisoBreadcrumbNav } from 'src/components/CaisoBreadcrumb/CaisoBreadcrumbNav';

export function SibrView() {
    const [view, setView] = useState<CAISO_SIBR_VIEW>(CAISO_SIBR_VIEW.OVERVIEW);
    const navigate = useNavigate();
    const location = useLocation();

    const handleView = (newView: CAISO_SIBR_VIEW) => {
        setView(newView);
    };

    useEffect(() => {
        if (location.pathname === '/caiso/sibr' || location.pathname === '/caiso/sibr/') {
            navigate('/caiso/sibr/overview');
        }
    }, [location.pathname, navigate]);

    return (
        <>
            <CaisoBreadcrumbNav />
            <Routes>
                <Route path="overview" element={<SibrOverviewView view={view} handleView={handleView} />} />
                <Route path="configuration" element={<SibrConfigurationView view={view} handleView={handleView} />} />
            </Routes>
        </>
    );
}
