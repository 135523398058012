import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import isNil from 'lodash/isNil';
import { QueryKey } from '../../query-client';
import { IPagination } from '../IPagination';
import { ICompany, ICreateCompany, IFetchCompanyPageQuery, IFetchCompanyQuery, IUpdateCompany } from './interface';
import { API } from '../api';
import { DATE_FORMAT_CSV } from '../commonConst';
import { exportCSVData } from '../common/exportCSVData';

const fetchCompanies = async (
    { pagination, filter, sorter, search, include }: IFetchCompanyPageQuery,
    signal?: AbortSignal | null
): Promise<IPagination<ICompany>> => {
    const limit = pagination?.pageSize || 1000;
    const current = pagination?.current || 1;
    const offset = (current - 1) * limit;

    if (filter && isNil(filter.type)) {
        delete filter.type;
    }

    if (filter && isNil(filter.company_vertical)) {
        delete filter.company_vertical;
    }

    if (sorter && (!sorter.field || !sorter.order)) {
        sorter = {};
    }

    const path = `/companies?${new URLSearchParams({
        ...(sorter?.field && { sortField: sorter.field, sortOrder: sorter.order }),
        ...filter,
        ...(search && { search }),
        offset: offset.toString(),
        limit: limit.toString(),
        ...(include && { include }),
    })}`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(response.statusText);
};

const fetchCompany = async ({ company_id }: IFetchCompanyQuery, signal?: AbortSignal | null): Promise<ICompany> => {
    const path = `/companies/${company_id}`;
    const response = await API.fetch(path, {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(response.statusText);
};

const deleteCompany = async (companyId: number) => {
    const response = await API.fetch(`/companies/${companyId}`, {
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (response.ok) {
        return;
    }

    const respBody: any = await response.json();
    throw new Error(respBody?.error?.message || `Cannot delete company!`);
};

interface IExportCompanies {
    company_vertical?: string;
    type?: number;
    search?: string;
}

const exportCompanies = async (filter: IExportCompanies): Promise<string> => {
    const query = new URLSearchParams({
        format: 'csv',
        ...(!isNil(filter?.company_vertical) && { company_vertical: filter?.company_vertical }),
        ...(!isNil(filter?.type) && { type: filter?.type.toString() }),
        ...(filter?.search && { search: filter?.search }),
        include: 'sites-count,account',
    });
    const response = await API.fetch(`/companies?${query.toString()}`, {
        headers: {
            'Content-Type': 'text/csv',
        },
    });

    if (response.ok) {
        const respBody: string = await response.text();

        const filename = `companies--${format(new Date(), DATE_FORMAT_CSV)}.csv`;
        exportCSVData(respBody, filename);

        return respBody;
    }

    const respBody: any = await response.json();
    throw new Error(respBody?.error?.message || `Cannot export data!`);
};

const exportEnergyCustomers = async () => {
    const response = await API.fetch('/reporting/san?format=csv', {
        headers: {
            'Content-Type': 'text/csv',
        },
    });

    if (response.ok) {
        const respBody: string = await response.text();

        const filename = `interval-data-customers--${format(new Date(), DATE_FORMAT_CSV)}.csv`;
        exportCSVData(respBody, filename);

        return respBody;
    }

    const respBody: any = await response.json();
    throw new Error(respBody?.error?.message || `Cannot export data!`);
};

const exportConnectCustomers = async () => {
    const response = await API.fetch('/reporting/customers?format=csv', {
        headers: {
            'Content-Type': 'text/csv',
        },
    });

    if (response.ok) {
        const respBody: string = await response.text();

        const filename = `connect-customers--${format(new Date(), DATE_FORMAT_CSV)}.csv`;

        exportCSVData(respBody, filename);

        return respBody;
    }

    const respBody: any = await response.json();
    throw new Error(respBody?.error?.message || `Cannot export data!`);
};

const createCompany = async (company: ICreateCompany) => {
    const response = await API.fetch(`/companies`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(company),
    });
    const respBody: any = await response.json();

    if (response.ok) {
        return;
    }

    throw new Error(respBody?.error?.message || `Cannot save site!`);
};

const updateCompany = async ({ company_id, ...company }: IUpdateCompany) => {
    const response = await API.fetch(`/companies/${company_id}`, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(company),
    });
    const respBody: any = await response.json();

    if (response.ok) {
        return;
    }

    throw new Error(respBody?.error?.message || `Cannot save company!`);
};

const syncCopelandSites = async (companyId: number) => {
    const response = await API.fetch(`/companies/${companyId}/control-provider/sync`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (response.ok) {
        return;
    }

    const respBody: any = await response.json();
    throw new Error(respBody?.error?.message || `Synchronization failed, please try again later`);
};

/****************************** */

export const useCompanyListQuery = (query: IFetchCompanyPageQuery, options = {}) => {
    return useQuery<IPagination<ICompany>, Error>([QueryKey.COMPANIES, query], ({ signal }) => fetchCompanies(query, signal), {
        keepPreviousData: true,
        ...options,
    });
};

export const useCompanyQuery = (query: IFetchCompanyQuery, options = {}) => {
    return useQuery<ICompany, Error>([QueryKey.COMPANIES, query], ({ signal }) => fetchCompany(query, signal), {
        keepPreviousData: true,
        ...options,
    });
};

export const useExportCompanyListQuery = (query: IExportCompanies) => {
    return useQuery<string, Error>([QueryKey.COMPANIES, 'export-csv', query], () => exportCompanies(query), {
        enabled: false,
    });
};

export const useExportEnergyCustomersQuery = () => {
    return useQuery<string, Error>(['energy-customers'], () => exportEnergyCustomers(), { enabled: false });
};

export const useExportConnectCustomersQuery = () => {
    return useQuery<string, Error>(['connect-customers'], () => exportConnectCustomers(), { enabled: false });
};

export const useCompanyDeleteMutation = () => {
    const queryClient = useQueryClient();

    return useMutation<void, Error, number>(deleteCompany, {
        onSuccess: async () => {
            await queryClient.invalidateQueries([QueryKey.COMPANIES]);
        },
    });
};

export const useCompanyCreateMutation = () => {
    const queryClient = useQueryClient();

    return useMutation<void, Error, ICreateCompany>(createCompany, {
        onSuccess: async () => {
            await queryClient.invalidateQueries([QueryKey.COMPANIES]);
        },
    });
};

export const useCompanyUpdateMutation = () => {
    const queryClient = useQueryClient();

    return useMutation<void, Error, IUpdateCompany>(updateCompany, {
        onSuccess: async () => {
            await queryClient.invalidateQueries([QueryKey.COMPANIES]);
        },
    });
};

export const useSyncCopelandSitesMutation = () => {
    return useMutation<void, Error, number>(syncCopelandSites);
};
