import { FC } from 'react';
import { EChartsOption } from 'echarts';
import { groupBy, max } from 'lodash';
import { fromZonedTime, toZonedTime } from 'date-fns-tz';
import { endOfDay, startOfDay, startOfHour } from 'date-fns';
import { IMarketZonePrice, Market } from 'src/domain/market-prices/interface';
import { usePriceListQuery } from 'src/domain/market-prices/queries';
import { CHART_DATA_COLOR, ChartSettings, makeChartAreasSeries, makeLineSeries, formatChartTooltip, calculateTooltipPosition } from './chart';
import { PlfChart } from './PlfChart';
import { InfoIcon } from '../InfoIcon';
import './Chart.css';

type LmpChartProps = {
    date: Date;
    market: Market;
    loadZone: string;
    timezone: string;
    settings: ChartSettings;
};

export const LmpChart: FC<LmpChartProps> = ({ date, market, loadZone, timezone, settings }) => {
    const start = fromZonedTime(startOfDay(date), timezone);
    const end = fromZonedTime(endOfDay(date), timezone);

    const { isFetching, data } = usePriceListQuery(
        {
            market,
            zone: loadZone,
            start,
            end,
        },
        {
            keepPreviousData: true,
            staleTime: 1 * 60 * 1000, // 1h
        }
    );

    const areaSeries = makeChartAreasSeries(settings);
    const dataSeries = makeLineSeries(
        'Day Ahead',
        toSeriesData(data, timezone),
        { color: CHART_DATA_COLOR, type: 'dashed' },
        start,
        end,
        settings.timeframe
    );

    const series: EChartsOption['series'] = [...areaSeries, ...dataSeries];

    const option: EChartsOption = {
        tooltip: {
            trigger: 'axis',
            padding: 0,
            borderColor: '#ffffff',
            axisPointer: {
                type: 'line',
                lineStyle: {
                    type: 'dashed',
                },
            },
            position: (point, params, dom, rect, size) => calculateTooltipPosition(point, size.contentSize),
            formatter: params => formatChartTooltip(params, timezone, ''),
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: (value: number) => `$${value}`,
            },
        },
        series: series,
    };

    return (
        <div className="peak-load-forecast-base-chart">
            <div className="peak-load-forecast-chart-title">
                Locational Marginal Price
                <InfoIcon tooltip="Locational Marginal Day Ahead Price for the selected zone" />
            </div>
            <PlfChart style={{ height: '320px', width: '100%' }} showLoading={isFetching} option={option} />
            <div className="chart-timezone">{timezone}</div>
        </div>
    );
};

type SeriesData = [Date, number][];

function toSeriesData(data: IMarketZonePrice[] | undefined, timezone: string): SeriesData {
    const prices = data?.length ? data[0].prices : [];

    const groupedByHour = groupBy(prices, it => {
        const [timestamp] = it;
        return startOfHour(timestamp * 1000).toISOString();
    });

    const res = Object.entries(groupedByHour).map(([hour, hourData]) => {
        const maxPrice = max(hourData.map(it => it[2]));
        // const offsetMs = getTimezoneOffset(tz, date);
        // const estHour = toZonedTime(new Date(hour), timezone).getHours();
        const estHour = toZonedTime(new Date(hour), timezone);
        return [estHour, maxPrice];
    }) as [Date, number][];

    return res;
}
