import { formatInTimeZone, format } from 'date-fns-tz';

export const formatToISOString = (date: Date) => {
    const formattedDate = format(date, 'yyyy-MM-dd');
    const formattedTime = format(date, 'HH:mm:ss');

    return `${formattedDate}T${formattedTime}.000Z`;
};

export const adjustForUTCOffset = (date: Date) => {
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
};

export const formatToMinutesAndHours = (duration: number) => {
    if (!duration) {
        return '0m';
    }

    const hours = Math.floor(duration / 60);
    const minutes = Math.round(duration % 60);
    const hourWithMinutes = minutes ? `${hours}h ${minutes}m` : `${hours}h`;

    return hours ? hourWithMinutes : `${minutes}m`;
};

export const defaultDateFormat = (date: Date | string | undefined | null, defaultValue = '', dateFormat = 'MMM dd, yyyy') => {
    if (!date) return defaultValue;

    const dateValue = typeof date === 'string' ? new Date(date) : date;
    return format(dateValue, dateFormat);
};

export const defaultDateFormatInUTC = (date: Date | string | undefined | null, defaultValue = '', dateFormat = 'MMM dd, yyyy') => {
    if (!date) return defaultValue;

    const dateValue = typeof date === 'string' ? new Date(date) : date;
    return formatInTimeZone(dateValue, 'UTC', dateFormat);
};
