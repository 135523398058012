import { ArrowLeftOutlined } from '@ant-design/icons';
import Button from 'antd/lib/button';
import Tooltip from 'antd/lib/tooltip';
import Typography from 'antd/lib/typography';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { defaultDateFormatInUTC } from '../../domain/common/dateFormatters';
import { toAllowedStringLength } from '../../domain/common/formattersToAllowedValueLength';
import { DataQualityReportPeriod, DataQualityReportPeriodMap } from '../../domain/readings-availability-stats/interface';
import { SECONDARY_COLOR } from '../../theme';
import { useCompanies } from '../selectors/GlobalCompanySelector/CompanyContext';

interface ITableTitleProps {
    updatedAt: string | null;
    companyName: string;
    isLoading: boolean;
    period: DataQualityReportPeriod;
}

export const ReadingsAvailabilityStatsByEnrollmentTableTitle: React.FC<ITableTitleProps> = ({ period, companyName, isLoading, updatedAt }) => {
    const navigate = useNavigate();
    const { handleCompany } = useCompanies()!;
    const periodTitle = DataQualityReportPeriodMap[period];

    const formattedDate = useMemo(() => {
        const date = updatedAt ? new Date(updatedAt) : new Date();
        return defaultDateFormatInUTC(date, new Date().toISOString(), 'dd/MMM/yyyy hh:mm a');
    }, [updatedAt]);

    const returnToAllCompaniesStatistics = () => {
        handleCompany(null);
        navigate(`/admin/data-quality/by-company?period=${period}`);
    };

    return (
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
            <Tooltip title="Back to all companies">
                <Button size="small" shape="circle" icon={<ArrowLeftOutlined />} onClick={returnToAllCompaniesStatistics} style={{ marginTop: 4 }} />
            </Tooltip>

            {!isLoading ? (
                <Typography.Title level={4} style={{ marginTop: 0, color: SECONDARY_COLOR, textAlign: 'center' }}>
                    <span style={{ color: 'black' }}>{periodTitle}</span> Report of KW interval % for{' '}
                    <Tooltip title={companyName}>
                        <span style={{ color: 'black' }}>{toAllowedStringLength(companyName)}</span>
                    </Tooltip>{' '}
                    company
                    <br />
                    <Typography.Text style={{ marginTop: 0, color: SECONDARY_COLOR, textAlign: 'center' }}>
                        Report last processed {formattedDate} (UTC)
                    </Typography.Text>
                </Typography.Title>
            ) : (
                <Typography.Title
                    level={4}
                    style={{
                        marginTop: 0,
                        color: SECONDARY_COLOR,
                        textAlign: 'center',
                    }}
                >
                    Loading...
                </Typography.Title>
            )}

            <div></div>
        </div>
    );
};
