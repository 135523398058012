import { DownOutlined, ExportOutlined, HighlightOutlined, UserOutlined } from '@ant-design/icons';
import { Flex } from 'antd';
import App from 'antd/lib/app';
import Avatar from 'antd/lib/avatar';
import Button from 'antd/lib/button';
import Dropdown from 'antd/lib/dropdown';
import Layout from 'antd/lib/layout';
import Typography from 'antd/lib/typography';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { SidebarProvider } from 'src/components/Sidebar/context/SidebarContext';
import { ColumnsProvider } from './context/ColumnsContext';
import { useSidebarEnabled } from 'src/components/Sidebar/hooks/SidebarEnabled.hook';
import { AbilityContext } from '../../components/ability/can';
import { AppNavigation } from '../../components/AppNavigation/AppNavigation';
import { FeedbackModal } from '../../components/modals/feedbackModal/FeedbackModal';
import { GlobalCompanySelector } from '../../components/selectors/GlobalCompanySelector/GlobalCompanySelector';
import Sidebar from '../../components/Sidebar/Sidebar';
import config from '../../config';
import { initializePendo } from '../../domain/analytics/pendo';
import './PrivateLayout.css';
import { useAuth } from '../../domain/auth/useAuth';
import { useCompanyLogo } from '../../domain/company/branding/BrandingProvider';
import { COMPANY_TYPE } from '../../domain/company/interface';
import { fetchUser } from '../../domain/user';
import { IUser } from '../../domain/user/interface';
import { CompanyBrandingModal } from '../../pages/companies/components/CompanyBrandingModal';
import { UserProfile } from '../../pages/userProfile/userProfile';

const { Header, Content, Footer } = Layout;

enum APP_ACTION {
    FEEDBACK = 'feedback',
    USER_PROFILE = 'user_profile',
    COMPANY_BRANDING = 'company_branding',
}

const FEATURE_FLAG_SIDEBAR = 'enable_sidebar';

if (localStorage.getItem(FEATURE_FLAG_SIDEBAR) === null) {
    localStorage.setItem(FEATURE_FLAG_SIDEBAR, 'false');
}

export function PrivateLayout() {
    const { notification } = App.useApp();
    const [appAction, setAppAction] = useState<APP_ACTION | ''>('');
    const [user, setUser] = useState<IUser>();
    const logo = useCompanyLogo();

    const { sidebarEnabled } = useSidebarEnabled();
    const [shouldDisplaySidebar, setShouldDisplaySidebar] = useState(sidebarEnabled);

    const auth = useAuth()!;
    const ability = useContext(AbilityContext);

    const fetchUserAction = useCallback(async () => {
        try {
            const user = await fetchUser(auth.user!.user_id);
            console.info('user loaded');
            setUser(user);
            initializePendo(user);
        } catch (error: any) {
            notification.error({ key: 'fetch-user-error', message: error.message || 'Cannot fetch user!' });
        }
    }, []);

    useEffect(() => {
        fetchUserAction();
    }, []);

    const signout = () => {
        auth.signout();
    };

    const closeUserProfile = (user?: IUser) => {
        closeAppActionModal();
        if (user) {
            fetchUserAction();
        }
    };

    const closeAppActionModal = () => setAppAction('');
    const showAppActionModal = (action: APP_ACTION) => setAppAction(action);

    const userMenu = [
        {
            key: 'profile',
            onClick: () => showAppActionModal(APP_ACTION.USER_PROFILE),
            label: (
                <Typography>
                    <UserOutlined /> Profile
                </Typography>
            ),
        },
        ...(ability.can('update:branding', 'Company') && user?.company?.type === COMPANY_TYPE.UTILITY_CUSTOMER
            ? [
                  {
                      key: 'company-branding',
                      onClick: () => showAppActionModal(APP_ACTION.COMPANY_BRANDING),
                      label: (
                          <Typography>
                              <HighlightOutlined /> Company Branding
                          </Typography>
                      ),
                  },
              ]
            : []),
        {
            key: 'signout',
            onClick: signout,
            label: (
                <Typography>
                    <ExportOutlined /> Logout
                </Typography>
            ),
        },
    ];

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Header className="header">
                <Link to="/">
                    <img className="app-logo full-logo" src={logo} alt="Enersponse logo" />
                </Link>
                {ability.can('filter', 'Company') && <GlobalCompanySelector />}

                <AppNavigation />

                <Dropdown key="user-menu" trigger={['click']} menu={{ items: userMenu }}>
                    <div className="user-menu">
                        <Avatar size={32} icon={<UserOutlined />} />
                        <span className="user-name">{user?.user_fname} </span>
                        <DownOutlined className="user-menu-icon" />
                    </div>
                </Dropdown>
            </Header>
            <Content className={`${shouldDisplaySidebar ? 'layout-container' : ''}`}>
                <SidebarProvider>
                    <ColumnsProvider>
                        <Flex>
                            {sidebarEnabled ? (
                                <Sidebar
                                    onSidebar={shouldDisplay => {
                                        setShouldDisplaySidebar(shouldDisplay);
                                    }}
                                />
                            ) : null}
                            <div className={`${shouldDisplaySidebar ? 'sidebar-layout-content' : 'layout-content'}`}>
                                <Outlet />

                                <Footer className={`footer`}>
                                    <span>(C) Enersponse, LLC. All rights reserved.</span>
                                    <br />
                                    <span>
                                        Need help? Email <a href={`mailto:${config.SUPPORT_EMAIL}`}>{config.SUPPORT_EMAIL}</a> or call +1 (949)
                                        734-0043
                                    </span>
                                </Footer>
                            </div>
                        </Flex>
                        <Button
                            size="large"
                            className="global-feedback-button"
                            onClick={() => showAppActionModal(APP_ACTION.FEEDBACK)}
                            type="primary"
                        >
                            Feedback
                        </Button>
                    </ColumnsProvider>
                </SidebarProvider>
            </Content>
            {appAction === APP_ACTION.USER_PROFILE && <UserProfile user={user} onClose={closeUserProfile} />}
            {appAction === APP_ACTION.FEEDBACK && <FeedbackModal onClose={closeAppActionModal} />}
            {appAction === APP_ACTION.COMPANY_BRANDING && (
                <CompanyBrandingModal company={{ company_id: user?.company_id }} onClose={closeAppActionModal} />
            )}
        </Layout>
    );
}
