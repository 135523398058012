import Select, { SelectProps } from 'antd/lib/select';
import { useState, useRef, useMemo } from 'react';
import debounce from 'lodash/debounce';
import React from 'react';
import App from 'antd/lib/app';

interface IDebounceSelectProps<ValueType = any> extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
    fetchOptions: (search: string, limit: number) => Promise<ValueType[]>;
    debounceTimeout?: number;
    maxOptions?: number;
}
export const DebounceSelect = <ValueType extends { key?: string; label: React.ReactNode; value: string | number } = any>({
    fetchOptions,
    debounceTimeout = 800,
    maxOptions = 5,
    ...props
}: IDebounceSelectProps<ValueType>) => {
    const [info, setInfo] = useState<string | null>(null);
    const [options, setOptions] = useState<ValueType[]>([]);
    const fetchRef = useRef(0);
    const { notification } = App.useApp();

    const debounceFetcher = useMemo(() => {
        const loadOptions = async (value: string) => {
            if (!value) {
                setOptions([]);
                setInfo(null);
                return;
            }
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setInfo('Loading ...');

            try {
                const newOptions = await fetchOptions(value, maxOptions);
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }

                if (newOptions.length) {
                    setInfo(null);
                } else {
                    setInfo(`No options found matching "${value}"`);
                }
                setOptions(newOptions);
            } catch (err) {
                setInfo('Cannot get list of enrollment IDs!');
                notification.error({
                    key: 'nyiso-san-list-error',
                    message: (err as Error)?.message || 'Cannot get list of enrollment IDs!',
                });
            }
        };

        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);

    return <Select filterOption={false} onSearch={debounceFetcher} notFoundContent={info ? <div>{info}</div> : null} options={options} {...props} />;
};
