import { ISite, ISiteDuplicatesCheckRequest } from '../../../domain/site/interface';
import { checkForSiteDuplicate } from '../../../domain/site';
import { ICompany } from '../../../domain/company/interface';
import { SiteImportTableIndexes as Idx } from './siteImportTableIndexes';
import { ITableCellDto } from './SitesParser';
import { uniqWith, isEqual } from 'lodash';

interface IGetDuplicateSitesByCompanyData {
    companyId: number;
    conditionsToCompare: ISiteDuplicatesCheckRequest[];
}

const MAX_CONDITIONS_TO_CHECK = 500; // Max allowed amount of conditions to be checked at once

export async function getDuplicatedSites(records: ITableCellDto[][], companies: ICompany[]): Promise<ISite[]> {
    if (!records.length || !companies.length) {
        return [];
    }

    try {
        const requestsPerCompanyData: IGetDuplicateSitesByCompanyData[] = buildRequestsData(records, companies);

        const allDuplicatedSites: ISite[] = [];

        for await (let item of requestsPerCompanyData) {
            const { duplicatedSites } = await checkForSiteDuplicate(item.companyId, item.conditionsToCompare);
            allDuplicatedSites.push(...duplicatedSites);
        }

        return allDuplicatedSites;
    } catch (error) {
        console.warn(`Sites check for duplicates failed with error: ${error}`);
        return [];
    }
}

function buildRequestsData(records: ITableCellDto[][], companies: ICompany[]): IGetDuplicateSitesByCompanyData[] {
    const companyToStoreNumber: IGetDuplicateSitesByCompanyData[] = [];

    records.forEach(record => {
        const recordCompanyName = record[Idx.COMPANY_NAME].value;
        const companyId = companies.find(company => company.company_name === recordCompanyName)?.company_id;

        if (!companyId) {
            return;
        }

        const recordConditions = getConditionsFromRecord(record);

        if (!recordConditions.length) {
            return;
        }

        const companyIndex = companyToStoreNumber.findIndex(item => item.companyId === companyId);

        if (companyIndex === -1) {
            companyToStoreNumber.push({ companyId, conditionsToCompare: recordConditions });
        } else {
            companyToStoreNumber[companyIndex].conditionsToCompare.push(...recordConditions);
        }
    });

    return prepareRequestsData(companyToStoreNumber);
}

function getConditionsFromRecord(record: ITableCellDto[]): ISiteDuplicatesCheckRequest[] {
    const result: ISiteDuplicatesCheckRequest[] = [];

    const recordStoreNumber = record[Idx.SITE_STORE_NUMBER].value;

    if (recordStoreNumber) {
        result.push({ site_store_number: recordStoreNumber });
    }

    const address: ISiteDuplicatesCheckRequest = {
        site_address: record[Idx.SITE_ADDRESS].value,
        site_city: record[Idx.SITE_CITY].value,
        site_zip: record[Idx.SITE_ZIP].value,
        site_state: record[Idx.SITE_STATE].value,
    };

    if (Object.keys(address).length) {
        result.push(address);
    }

    return result;
}

function prepareRequestsData(requestsData: IGetDuplicateSitesByCompanyData[]): IGetDuplicateSitesByCompanyData[] {
    const result: IGetDuplicateSitesByCompanyData[] = [];

    requestsData.forEach(request => {
        // Make sure we don't have duplicated conditions
        const uniqueConditionsToCompare = uniqWith(request.conditionsToCompare, isEqual);

        // Split the unique data into batches
        for (let i = 0; i < uniqueConditionsToCompare.length; i += MAX_CONDITIONS_TO_CHECK) {
            result.push({
                companyId: request.companyId,
                conditionsToCompare: uniqueConditionsToCompare.slice(i, i + MAX_CONDITIONS_TO_CHECK),
            });
        }
    });

    return result;
}
