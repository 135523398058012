import Select from 'antd/lib/select';
import { IProgram } from '../../../domain/program/interface';
import { usePrograms } from '../../../domain/program/usePrograms';
import { filterSelectorOption } from '../selectorHelpers';
import { getIconByKey } from '../../site/EnrollProgramModal';
import Typography from 'antd/lib/typography';
import { useContext } from 'react';
import { AbilityContext } from '../../ability/can';

interface IProgramSelector {
    selectedProgram: number | (number | string)[] | null;
    handleProgram: (program: IProgram) => void;
    mode?: 'multiple' | 'tags' | undefined;
    [key: string]: any;
}

const toProgramOptions = (programs: IProgram[]) => {
    const options = programs.map(program => ({
        label: program.name,
        value: program.program_id,
        key: program.program_id,
    }));

    return options;
};

export const ProgramSelector = ({ handleProgram, selectedProgram, extraProgramList = [], mode, ...props }: IProgramSelector) => {
    const ability = useContext(AbilityContext);
    const { programs, isLoading } = usePrograms();

    const onProgramChange = (value: any) => {
        const program = mode ? value : programs.find(p => p.program_id === value)!;
        handleProgram(program);
    };

    const prepareOptionsList = () => {
        if (extraProgramList.length && !ability.can('enroll', 'Program')) {
            return extraProgramList;
        } else {
            return [...extraProgramList, ...toProgramOptions(programs)];
        }
    };

    return (
        <Select
            mode={mode}
            size="large"
            placeholder="Please select Program"
            options={prepareOptionsList()}
            value={selectedProgram as any}
            onChange={onProgramChange}
            style={{ width: '200px' }}
            showSearch
            filterOption={filterSelectorOption}
            loading={isLoading}
            optionRender={(option: any) => {
                const isExtraProgram = ['price-response', 'clean-response', 'leap-api-dispatch'].includes(option.value);

                return (
                    <>
                        {isExtraProgram && getIconByKey({ key: option.key })} <Typography.Text>{option.label}</Typography.Text>
                    </>
                );
            }}
            {...props}
        />
    );
};
