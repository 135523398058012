import { Market } from 'src/domain/market-prices/interface';
import { usePageLocation } from '../usePageState';

type PeakLoadForecastPageState = {
    start: number;
    selected: number;
    // end: number;
    market: Market;
    loadZone: string;
    location: string;
};

export const usePeakLoadPageState = (defaultState: Partial<PeakLoadForecastPageState>) => {
    const { setPageQuery, pageQuery } = usePageLocation();

    const pageState: PeakLoadForecastPageState = {
        ...defaultState,
        ...pageQuery,
    } as any;

    function setPageState(newState: Partial<PeakLoadForecastPageState>) {
        setPageQuery({ ...pageState, ...newState });
    }

    return {
        pageState,
        setPageState,
    };
};
