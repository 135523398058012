import Button from 'antd/lib/button';
import { useEffect, useState } from 'react';

interface ICountdownButton {
    time: number;
    onClick: any;
    title: string;
    loading?: boolean;
    danger?: boolean;
    type?: 'default' | 'link' | 'text' | 'primary' | 'dashed' | undefined;
}

export const CountdownButton = ({ time, title, onClick, loading = false, type = 'primary', danger = false }: ICountdownButton) => {
    const [timer, setTimer] = useState(time);

    useEffect(() => {
        const counter = timer > 0 && setInterval(() => setTimer(timer - 1), 1000);
        return () => clearInterval(Number(counter));
    }, [timer]);

    const handleClick = () => {
        onClick();
        setTimer(time);
    };

    const renderTimer = (time: number) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return time > 0 && `${minutes}m:${seconds}s`;
    };

    return (
        <Button data-cy="countdown-btn" danger={danger} type={type} loading={loading} disabled={timer > 0} onClick={handleClick}>
            {title} {renderTimer(timer)}
        </Button>
    );
};
