import Modal from 'antd/lib/modal';
import { App, Table } from 'antd';
import { CaisoSyncOrigin, ICaisoSync } from 'src/domain/caiso/drrs/caiso-sync/interface';
import { WithEmptyDataTable } from 'src/components/table/withEmptyDataTable';
import { useCaisoSyncListQuery, useCaisoSyncNowMutation } from 'src/domain/caiso/drrs/caiso-sync/queries';
import { useEffect, useState } from 'react';
import { ICaisoPageState } from 'src/pages/caiso/interface';
import { DEFAULT_PAGINATION } from 'src/domain/commonConst';
import { ColumnsType } from 'antd/lib/table';
import { toUsefulParams } from 'src/pages/toUsefulParams';
import { defaultDateFormat } from 'src/domain/common/dateFormatters';
import Button from 'antd/lib/button';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKey } from 'src/query-client';
import { format } from 'date-fns';

interface IDrrsCaisoSyncModalProps {
    origin: CaisoSyncOrigin;
    onClose: Function;
}

export function DrrsCaisoSyncModal({ origin, onClose }: IDrrsCaisoSyncModalProps) {
    const { notification } = App.useApp();
    const queryClient = useQueryClient();

    const [pageState, setPageState] = useState<Omit<ICaisoPageState, 'search' | 'sorter' | 'filter'>>({
        pagination: DEFAULT_PAGINATION,
    });
    const { data, isLoading, error, refetch, isRefetching } = useCaisoSyncListQuery(origin, pageState, {
        refetchInterval: 10_000, // 10 seconds
        refetchOnWindowFocus: false,
    });
    const { mutate: syncNow, isLoading: isSyncNowLoading } = useCaisoSyncNowMutation();

    const syncs = data?.data || [];

    useEffect(() => {
        if (!isLoading && error) {
            notification.error({
                key: 'caiso-syncs-list-error',
                message: (error as Error)?.message || 'Cannot load syncs!',
            });
        }
    }, [isLoading, error]);

    function closeModal() {
        onClose();
    }

    function onTableChange(pagination: any, filter: any, sorter: any) {
        const preparedParams = toUsefulParams({ pagination, filter, sorter });

        setPageState({ ...pageState, ...preparedParams });
    }

    async function handleSyncNow() {
        syncNow(origin);
    }

    const columns: ColumnsType<ICaisoSync> = [
        {
            title: 'Sync Date',
            dataIndex: 'date',
            width: '30%',
            render: date => format(new Date(date), 'MMMM do yyyy, h:mm:ss a'),
        },
        {
            title: 'Requested by',
            dataIndex: 'source',
            width: '30%',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            width: '15%',
        },
        {
            title: 'Error Message',
            dataIndex: 'error_message',
            width: '30%',
        },
    ];

    return (
        <Modal
            open
            destroyOnClose
            title="CAISO Sync"
            onCancel={closeModal}
            width="60%"
            footer={[
                <Button key="company-account-modal-submit" type="primary" onClick={handleSyncNow} disabled={isLoading || isRefetching}>
                    Sync Now
                </Button>,
            ]}
        >
            <WithEmptyDataTable condition={isLoading}>
                <Table
                    size="middle"
                    rowKey="id"
                    sticky
                    columns={columns}
                    dataSource={syncs}
                    pagination={false}
                    loading={isLoading}
                    onChange={onTableChange}
                />
            </WithEmptyDataTable>
        </Modal>
    );
}
