//The intention of this function is to prevent the JSON.stringify from throwing an error when it encounters a circular reference

import React from 'react';

//so we can identify is the columns have changed and run the useEffect to update the columns
export function safeStringify(obj: any) {
    const seen = new WeakSet();
    try {
        return JSON.stringify(obj, (key, value) => {
            // Skip functions and React elements
            if (typeof value === 'function') return undefined;
            if (React.isValidElement(value)) return undefined;
            // Skip window, document, DOM nodes
            if (
                typeof value === 'object' &&
                value !== null &&
                (value === window || value instanceof Window || value instanceof Document || (value.nodeType && typeof value.nodeName === 'string'))
            ) {
                return;
            }
            if (typeof value === 'object' && value !== null) {
                if (seen.has(value)) {
                    return;
                }
                seen.add(value);
            }
            return value;
        });
    } catch (err) {
        console.error('Error in safeStringify:', err);
        return '';
    }
}
