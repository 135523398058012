import { INyisoPeakHour } from '../../../domain/nyiso/interface';
import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal';
import React, { useState } from 'react';
import { INyisoCurrentSeason } from '../../../domain/nyiso/getCurrentSeasonInfo';
import Dragger from 'antd/lib/upload/Dragger';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import InboxOutlined from '@ant-design/icons/InboxOutlined';
import { PRIMARY_COLOR } from '../../../theme';
import Typography from 'antd/lib/typography';
import { UploadFile, Upload, App } from 'antd/lib';
import { useNyisoPeakHoursSeasonDataUpload } from '../../../domain/nyiso/queries';
import { PeakHoursDataPreview } from './PeakHoursDataPreview';
import Divider from 'antd/lib/divider';

interface IPeakHoursUploadModelProps {
    peakHoursData?: INyisoPeakHour[];
    currentSeasonInfo: INyisoCurrentSeason;
    onClose: Function;
}

const ALLOWED_FILE_SIZE_MB = 1;
const ALLOWED_FILE_TYPE = '.xlsx';
export const PeakHoursUploadModal = ({ peakHoursData, currentSeasonInfo, onClose }: IPeakHoursUploadModelProps) => {
    const { notification } = App.useApp();
    const [file, setFile] = useState<UploadFile | null>(null);
    const { mutateAsync: uploadPeakHoursData, isLoading } = useNyisoPeakHoursSeasonDataUpload();
    const onModalClose = () => onClose();

    const uploadProps = {
        accept: ALLOWED_FILE_TYPE,
        multiple: false,
        maxCount: 1,
        onRemove: () => {
            setFile(null);
        },
        beforeUpload: (file: UploadFile) => {
            const fileSizeInMB = file.size! / 1024 / 1024;
            if (fileSizeInMB > ALLOWED_FILE_SIZE_MB) {
                notification.warning({
                    key: 'upload-file-size-notification',
                    message: `Your file is more than ${ALLOWED_FILE_SIZE_MB}MB. Looks like it incorrect.`,
                });
                return Upload.LIST_IGNORE;
            }

            if (!file.name.endsWith(ALLOWED_FILE_TYPE)) {
                notification.warning({
                    key: 'upload-file-type-notification',
                    message: `Please select "${ALLOWED_FILE_TYPE}" file.`,
                });
                return Upload.LIST_IGNORE;
            }

            return false;
        },
        onChange: (info: { file: UploadFile; fileList: UploadFile[] }) => {
            if (info.file.status) {
                return;
            }
            setFile(info.file);
        },
    };

    const onPeakHoursUpload = async () => {
        if (!file) {
            return;
        }

        try {
            await uploadPeakHoursData({ id: currentSeasonInfo.id, file });
            onModalClose();
        } catch (err) {
            console.log('Peak hours upload failed with error: ', err);
        }
    };

    return (
        <Modal
            open
            destroyOnClose
            title={`${peakHoursData ? '' : 'Upload'} Peak Hours Data for NYISO ${currentSeasonInfo.viewValue}`}
            onCancel={onModalClose}
            footer={[
                <Button key="resource-modal-cancel" onClick={onModalClose}>
                    Cancel
                </Button>,
                <Button key="resource-modal-submit" type="primary" disabled={!file} loading={isLoading} onClick={onPeakHoursUpload}>
                    Upload
                </Button>,
            ]}
        >
            {peakHoursData && <PeakHoursDataPreview peakHours={peakHoursData} />}
            {peakHoursData && <Divider> Upload New Peak Hours Data for {currentSeasonInfo.viewValue}</Divider>}
            <Dragger {...uploadProps} height={120}>
                <Row align="middle" justify="center" style={{ paddingLeft: 8, paddingRight: 8 }}>
                    <Col xs={24}>
                        <InboxOutlined style={{ fontSize: '20px', marginRight: '4px', color: PRIMARY_COLOR }} />
                        <Typography.Text>Click or drag XLSX file to this area to upload</Typography.Text>
                        <Typography.Text type="secondary" style={{ display: 'block' }}>
                            {`Expected dates to be in range from ${currentSeasonInfo.capabilityStart} to ${currentSeasonInfo.capabilityEnd}`}
                        </Typography.Text>
                        <Typography.Text type="secondary" style={{ display: 'block' }}>
                            The size limit is {ALLOWED_FILE_SIZE_MB}MB
                        </Typography.Text>
                    </Col>
                </Row>
            </Dragger>
        </Modal>
    );
};
