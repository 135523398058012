import { IIdentifyMarketZoneQuery } from './interface';
import { API } from '../api';
import { IMarket } from './IMarket';
import { marketLoadZoneToLabel } from '../../components/site/SiteMarketAndLoadZone';

export interface NYISOLabelMappingI {
    [key: string]: string;
}

export const NYISOLabelMapping: NYISOLabelMappingI = {
    WEST: 'A - WEST',
    GENESE: 'B - GENESEE',
    CENTRL: 'C - CENTRAL',
    NORTH: 'D - NORTH',
    'MHK VL': 'E - MOHAWK VALLEY',
    CAPITL: 'F - CAPITAL',
    'HUD VL': 'G - HUDSON VALLEY',
    MILLWD: 'H - MILLWOOD',
    DUNWOD: 'I - DUNWOOD',
    'N.Y.C.': 'J - NEW YORK CITY',
    LONGIL: 'K - LONG ISLAND',
};

export function transformMarketZone(receivedMarketZones: { [key: string]: string[] }): { [key: string]: string[] } {
    const marketZones: { [key: string]: string[] } = {};
    Object.entries(receivedMarketZones).forEach(([market, zones]) => {
        marketZones[market.toUpperCase()] = zones.sort().map((zone: string) => (market === 'NYISO' ? marketLoadZoneToLabel(market, zone) : zone));
    });
    return marketZones;
}

export const fetchMarketZones = async (signal?: AbortSignal): Promise<{ [key: string]: string[] }> => {
    const response = await API.fetch('/markets', {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return transformMarketZone(respBody);
    }

    throw new Error(respBody?.error?.message || `Cannot fetch market zones!`);
};

export const identifyMarketZone = async ({ zipcode, lat, long, county }: IIdentifyMarketZoneQuery, signal?: AbortSignal): Promise<IMarket | null> => {
    const path = `/markets/identify?${new URLSearchParams({
        zipcode,
        lat: lat.toString(),
        long: long.toString(),
        ...(county && { county }),
    })}`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (response.status === 204) {
        return null;
    }

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    return null;
};
