import { isBoolean, isNumber } from 'lodash';
import { useEffect } from 'react';
import { useLocalStorage } from 'src/domain/useLocalStorage';

export type PeakLoadForecastCustomizeSettings = {
    timeframe: [number, number];
    layers: {
        sunriseSunset: { visible: boolean; index: number };
        event: { visible: boolean; index: number };
    };
    order: {
        peakLoad: { visible: boolean; index: number };
        peakHours: { visible: boolean; index: number };
        realFeel: { visible: boolean; index: number };
        lmp: { visible: boolean; index: number };
        solarIndex: { visible: boolean; index: number };
        systemLoad: { visible: boolean; index: number };
    };
};

export const defaultSettings: PeakLoadForecastCustomizeSettings = {
    timeframe: [1, 24],
    layers: {
        sunriseSunset: { visible: true, index: 0 },
        event: { visible: true, index: 1 },
    },
    order: {
        peakLoad: { visible: true, index: 0 },
        peakHours: { visible: true, index: 1 },
        realFeel: { visible: true, index: 2 },
        lmp: { visible: true, index: 3 },
        solarIndex: { visible: true, index: 4 },
        systemLoad: { visible: true, index: 5 },
    },
};

export const usePeakLoadForecastCustomizeSettings = () => {
    const [settings, setSettings] = useLocalStorage<PeakLoadForecastCustomizeSettings>('peak-load-forecast', defaultSettings);

    useEffect(() => {
        console.info(`usePeakLoadForecastCustomizeSettings changed`, settings);
    }, [settings]);

    // make sure settings read from local storage are valid - otherwise - reset them to default
    if (isSettingsValid(settings) === false) {
        console.info('PeakLoadForecastCustomizeSettings.', settings);
        console.warn('PeakLoadForecastCustomizeSettings is not valid - reset to defaults. ');
        setSettings(defaultSettings);
    }

    return {
        settings,
        setSettings,
    };
};

/**
 * Consider using zod for validation
 */
function isSettingsValid(settings: PeakLoadForecastCustomizeSettings) {
    if (!settings) return false;
    if (isSettingsTimeframeValid(settings.timeframe) === false) return false;
    if (isSettingsLayersValid(settings.layers) === false) return false;
    if (isSettingsOrderValid(settings.order) === false) return false;

    return true;
}

function isSettingsTimeframeValid(timeframe: PeakLoadForecastCustomizeSettings['timeframe']) {
    if (timeframe?.length !== 2) return false;
    if (!isNumber(timeframe[0]) || !isNumber(timeframe[1])) return false;
    if (timeframe[0] < 1 || timeframe[0] > 24) return false;
    if (timeframe[1] < 1 || timeframe[1] > 24) return false;
    if (timeframe[0] >= timeframe[1]) return false;

    return true;
}

function isSettingsLayersValid(layers: PeakLoadForecastCustomizeSettings['layers']) {
    if (!layers) return false;

    if (isSettingsOrderAndVisibilityValid(layers?.sunriseSunset) === false) return false;
    if (isSettingsOrderAndVisibilityValid(layers?.event) === false) return false;

    return true;
}

function isSettingsOrderValid(order: PeakLoadForecastCustomizeSettings['order']) {
    if (!order) return false;

    if (isSettingsOrderAndVisibilityValid(order?.peakLoad) === false) return false;
    if (isSettingsOrderAndVisibilityValid(order?.peakHours) === false) return false;
    if (isSettingsOrderAndVisibilityValid(order?.realFeel) === false) return false;
    if (isSettingsOrderAndVisibilityValid(order?.lmp) === false) return false;
    if (isSettingsOrderAndVisibilityValid(order?.solarIndex) === false) return false;
    if (isSettingsOrderAndVisibilityValid(order?.systemLoad) === false) return false;

    return true;
}

function isSettingsOrderAndVisibilityValid(key: { visible: boolean; index: number }) {
    if (!key) return false;
    if (!isBoolean(key?.visible)) return false;
    if (!isNumber(key?.index)) return false;

    return true;
}
