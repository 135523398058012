import React from 'react';
import Typography from 'antd/lib/typography';

interface ICsvParsingErrorsProps {
    errors: string[];
}

export const CsvParsingErrors: React.FC<ICsvParsingErrorsProps> = ({ errors }) => {
    if (!errors || errors.length === 0) return null;

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography.Text style={{ display: 'block' }}>Errors:</Typography.Text>
            {errors.map((error, i) => (
                <Typography.Text key={i} type="danger" style={{ display: 'block' }}>
                    {i + 1}. {error}
                </Typography.Text>
            ))}
        </div>
    );
};
