import DownloadOutlined from '@ant-design/icons/DownloadOutlined';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import SettingOutlined from '@ant-design/icons/lib/icons/SettingOutlined';
import PlusCircleOutlined from '@ant-design/icons/PlusCircleOutlined';
import UploadOutlined from '@ant-design/icons/UploadOutlined';
import App from 'antd/lib/app';
import Button from 'antd/lib/button';
import Table from 'antd/lib/table';
import { ColumnsType } from 'antd/lib/table/interface';
import { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AbilityContext } from '../../components/ability/can';
import { TriggerSettingsModal } from '../../components/modals/triggerSettingsModal/TriggerSettingsModal';
import { PageHeader } from '../../components/pageHeader/pageHeader';
import { SearchInput } from '../../components/searchInput/searchInput';
import { useCompanies } from '../../components/selectors/GlobalCompanySelector/CompanyContext';
import { ActionList } from '../../components/table/actionList/actionList';
import { getColumnSortOrder } from '../../components/table/columnFormatHelpers';
import { WithEmptyDataTable } from '../../components/table/withEmptyDataTable';
import { TriggerTimeTag } from '../../components/TriggerTimeTag';
import { useDocumentTitle } from '../../components/useDocumentTitle';
import { FORM_MODE, useFormMode } from '../../components/useFormMode';
import { WithControlledTooltip } from '../../components/withControlledTooltip';
import { exportCleanResponseTriggers } from '../../domain/clean-response';
import { ICleanResponseTrigger } from '../../domain/clean-response/interface';
import { useCleanResponseTriggerListQuery, useDeleteCleanResponseTriggerMutation } from '../../domain/clean-response/queries';
import { formatToMinutesAndHours } from '../../domain/common/dateFormatters';
import { DEFAULT_PAGINATION, PAGE_ACTION } from '../../domain/commonConst';
import { SIGNAL_LEVEL_OPTIONS, SignalType } from '../../domain/event/interface';
import { IPaginationMeta } from '../../domain/IPagination';
import { validatePagination } from '../../domain/validatePagination';
import { toUsefulParams } from '../toUsefulParams';
import { usePageLocation } from '../usePageState';
import { CleanResponseFileUploadModal } from './components/CleanResponseFileUploadModal/CleanResponseFileUploadModal';
import { CleanResponseTriggerModal } from './components/CleanResponseTriggerModal';

export const CleanResponse = () => {
    const { notification, modal } = App.useApp();
    const location = useLocation();
    const ability = useContext(AbilityContext);
    const { setPageQuery, queryToState } = usePageLocation();
    const pageState: any = queryToState(location.search);

    useDocumentTitle('Clean Response');

    const [pageAction, setPageAction] = useState<PAGE_ACTION | ''>();
    const { onSetFormMode, isEditMode } = useFormMode();

    const { companyId } = useCompanies()!;
    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const [cleanResponseTrigger, setCleanResponseTrigger] = useState<ICleanResponseTrigger | Partial<ICleanResponseTrigger>>();
    const [exportLoading, setExportLoading] = useState<boolean>(false);

    const listQuery = {
        pagination: pageState?.pagination ?? DEFAULT_PAGINATION,
        ...(pageState?.sorter && { sorter: pageState?.sorter }),
        ...(pageState?.search && { search: pageState?.search }),
        ...(pageState?.companyId && { companyId: pageState?.companyId }),
        ...(pageState?.filter && { filter: pageState.filter }),
        include: 'company',
    };

    const { mutateAsync: deleteCleanResponseTrigger } = useDeleteCleanResponseTriggerMutation();
    const { data, isLoading, refetch: refetchTriggers } = useCleanResponseTriggerListQuery(listQuery);

    const triggers = data?.data ?? [];
    const meta = data?.meta ?? ({ total: 0 } as IPaginationMeta);

    const exportCleanResponseTriggersAction = async () => {
        try {
            setExportLoading(true);
            await exportCleanResponseTriggers({ companyId: companyId!, search: pageState?.search });
        } catch (err: any) {
            notification.error({
                key: 'clean-response-trigger-export-error',
                message: err.message || 'Cannot export Clean Response triggers!',
            });
        }
        setExportLoading(false);
    };

    const deleteCleanResponseTriggerAction = async (trigger: ICleanResponseTrigger) => {
        try {
            await deleteCleanResponseTrigger(trigger);

            const validPagination = validatePagination(pageState.pagination, meta.total - 1);

            setPageQuery({ ...pageState, pagination: validPagination });
            await refetchTriggers({ ...pageState, pagination: validPagination });

            notification.info({ key: 'trigger-delete-info', message: 'Clean Response trigger deleted' });
        } catch (error: any) {
            notification.error({
                key: 'trigger-delete-error',
                message: error.message || 'Cannot delete Clean Response trigger!',
            });
        }
    };

    async function onTableChange(pagination: any, filter: any, sorter: any) {
        const preparedParams = toUsefulParams({ pagination, filter, sorter });

        setPageQuery({ ...pageState, ...preparedParams });
    }

    const showNewCleanResponseTriggerModal = () => {
        setPageAction(PAGE_ACTION.NEW_EDIT);
        onSetFormMode(FORM_MODE.NEW);

        setCleanResponseTrigger({
            company_id: undefined,
            carbon_intensity: undefined,
            duration: 30,
            signal: SignalType.HIGH,
            start_time: null,
            end_time: null,
            market: undefined,
        });
    };

    const onCleanResponseTriggerModalClose = async (cleanResponseTrigger?: ICleanResponseTrigger) => {
        setPageAction('');
        setCleanResponseTrigger(undefined);

        if (cleanResponseTrigger) {
            await refetchTriggers(toUsefulParams(pageState));
        }
    };

    const showEditCleanResponseTriggerModal = (record: ICleanResponseTrigger) => {
        return () => {
            setPageAction(PAGE_ACTION.NEW_EDIT);
            onSetFormMode(FORM_MODE.EDIT);
            setCleanResponseTrigger(record);
        };
    };

    const deleteCleanResponseTriggerWithConfirmation = (record: ICleanResponseTrigger) => {
        return () => {
            modal.confirm({
                icon: <ExclamationCircleOutlined />,
                content: `Delete Clean Response trigger for ${record.company.company_name}. Are you sure?`,
                onOk: async () => {
                    await deleteCleanResponseTriggerAction(record);
                },
                onCancel: () => {
                    console.log('Cancel');
                },
            });
        };
    };

    const showUploadModal = () => {
        setPageAction(PAGE_ACTION.UPLOAD_FILE);
    };

    const onCloseUploadModal = async (cleanResponseTriggers: ICleanResponseTrigger[]) => {
        setPageAction('');

        if (cleanResponseTriggers) {
            await refetchTriggers(toUsefulParams(pageState));
        }
    };

    const handleSearch = (value: string) => {
        if (value === pageState.search) {
            setPageQuery({
                ...pageState,
                pagination: { ...pageState.pagination, current: DEFAULT_PAGINATION.current },
            });
            return;
        }

        setPageQuery({ ...pageState, search: value });
    };

    const handleShowSettingsModal = () => setShowSettingsModal(true);
    const handleCloseSettingsModal = () => setShowSettingsModal(false);

    const actions = [
        ...(ability.can('update', 'CleanResponse')
            ? [
                  {
                      title: 'Edit',
                      onClick: (record: ICleanResponseTrigger) => showEditCleanResponseTriggerModal(record),
                  },
              ]
            : []),
        ...(ability.can('delete', 'CleanResponse')
            ? [
                  {
                      title: 'Delete',
                      onClick: (record: ICleanResponseTrigger) => deleteCleanResponseTriggerWithConfirmation(record),
                  },
              ]
            : []),
    ];

    const columns: ColumnsType<ICleanResponseTrigger> = [
        {
            title: 'Company',
            dataIndex: ['company_id'],
            render: (text, record) => <>{record.company.company_name}</>,
            sorter: (a: ICleanResponseTrigger, b: ICleanResponseTrigger) => a.company_id - b.company_id,
            ...getColumnSortOrder('company_id', pageState.sorter),
        },
        {
            title: 'Intensity CO2/MWh',
            dataIndex: 'carbon_intensity',
            sorter: (a: ICleanResponseTrigger, b: ICleanResponseTrigger) => a.carbon_intensity - b.carbon_intensity,
            ...getColumnSortOrder('carbon_intensity', pageState.sorter),
        },
        {
            title: 'Market',
            dataIndex: 'market',
            render: text => text.toUpperCase(),
            sorter: (a: ICleanResponseTrigger, b: ICleanResponseTrigger) => a.market.localeCompare(b.market),
            ...getColumnSortOrder('market', pageState.sorter),
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            sorter: (a: ICleanResponseTrigger, b: ICleanResponseTrigger) => a.duration - b.duration,
            render: text => formatToMinutesAndHours(text),
            ...getColumnSortOrder('duration', pageState.sorter),
        },
        {
            title: 'Signal Level',
            dataIndex: 'signal',
            sorter: (a: ICleanResponseTrigger, b: ICleanResponseTrigger) => a.signal - b.signal,
            render: text => SIGNAL_LEVEL_OPTIONS.find(signal => +text === signal.value)?.label,
            ...getColumnSortOrder('signal', pageState.sorter),
        },
        {
            title: 'Trigger Time',
            render: (text, record) => <TriggerTimeTag value={record} />,
        },
        {
            key: 'action',
            width: 100,
            sorter: false,
            render: (text: string, record: ICleanResponseTrigger) => <ActionList actions={actions} item={record} />,
        },
    ];

    return (
        <>
            <PageHeader
                pageTitle={`Clean Response (${meta.total || 0})`}
                extra={[]}
                actions={[
                    <SearchInput key="search-input-clean-response" onSearch={handleSearch} defaultValue={pageState.search} />,
                    ability.can('create', 'CleanResponse') && (
                        <WithControlledTooltip key="import-clean-response-triggers" title="Please select company" extraVisibleCondition={!companyId}>
                            <Button
                                size="large"
                                key="import-clean-response-triggers"
                                data-cy="import-action"
                                disabled={!companyId}
                                style={!companyId ? { pointerEvents: 'none' } : {}}
                                icon={<UploadOutlined />}
                                onClick={showUploadModal}
                            >
                                Import Triggers
                            </Button>
                        </WithControlledTooltip>
                    ),
                    ability.can('read', 'CleanResponse') && (
                        <Button
                            key="export-clean-response-triggers"
                            data-cy="export-action"
                            size="large"
                            onClick={exportCleanResponseTriggersAction}
                            loading={exportLoading}
                            icon={<DownloadOutlined />}
                        >
                            Export Triggers
                        </Button>
                    ),
                    ability.can('read', 'Settings') && (
                        <Button
                            size="large"
                            key="trigger-settings"
                            icon={<SettingOutlined />}
                            onClick={handleShowSettingsModal}
                            data-cy="trigger-settings-action"
                        >
                            Trigger Settings
                        </Button>
                    ),
                    ability.can('create', 'CleanResponse') && (
                        <Button
                            size="large"
                            key="new-clean-response-trigger"
                            data-cy="new-trigger"
                            type="primary"
                            onClick={showNewCleanResponseTriggerModal}
                            icon={<PlusCircleOutlined />}
                        >
                            New Trigger
                        </Button>
                    ),
                ]}
            />
            <WithEmptyDataTable condition={isLoading}>
                <Table
                    sticky
                    rowKey="id"
                    size="small"
                    columns={columns}
                    dataSource={triggers}
                    pagination={{
                        ...pageState.pagination,
                        total: meta.total,
                        showSizeChanger: true,
                        size: 'default',
                    }}
                    loading={isLoading}
                    onChange={onTableChange}
                />
            </WithEmptyDataTable>

            {cleanResponseTrigger && pageAction === PAGE_ACTION.NEW_EDIT && (
                <CleanResponseTriggerModal onClose={onCleanResponseTriggerModalClose} trigger={cleanResponseTrigger} isEditMode={isEditMode} />
            )}

            {pageAction === PAGE_ACTION.UPLOAD_FILE && <CleanResponseFileUploadModal onClose={onCloseUploadModal} company={companyId} />}

            {showSettingsModal && <TriggerSettingsModal triggerName="cleanResponse" onClose={handleCloseSettingsModal} />}
        </>
    );
};
