import React from 'react';
import { format } from 'date-fns';
import Table from 'antd/lib/table';
import Popover from 'antd/lib/popover';
import Typography from 'antd/lib/typography';
import { ColumnsType } from 'antd/lib/table/interface';
import { InfoCircleOutlined } from '@ant-design/icons';
import { AsyncJobContextKeyList, AsyncJobStatus, IAsyncJob } from '../../../domain/async-job/interface';
import { PRIMARY_COLOR } from '../../../theme';
import { WithEmptyDataTable } from '../../table/withEmptyDataTable';

interface IAsyncJobsModalProps {
    asyncJobs: IAsyncJob[];
    isLoading?: boolean;
    limit?: number;
}

const STATUS_COLOR_MAP: { [key: string]: string } = {
    [AsyncJobStatus.PENDING]: '#888787',
    [AsyncJobStatus.IN_PROGRESS]: '#fac42a',
    [AsyncJobStatus.SUCCESS]: '#26ad5f',
    [AsyncJobStatus.FAILED]: '#fa675c',
};

const STATUS_TEXT_MAP: { [key: keyof typeof STATUS_COLOR_MAP]: string } = {
    [AsyncJobStatus.PENDING]: 'Pending',
    [AsyncJobStatus.IN_PROGRESS]: 'In Progress',
    [AsyncJobStatus.SUCCESS]: 'Success',
    [AsyncJobStatus.FAILED]: 'Failed',
};

const DATE_FORMAT = 'hh:mm a MMM dd, yyyy';

export const AsyncJobsModalContent: React.FC<IAsyncJobsModalProps> = ({ limit = 10, isLoading, asyncJobs }) => {
    const columns: ColumnsType<IAsyncJob> = [
        {
            title: 'Start Date',
            dataIndex: 'started_at',
            render: (startedAt: string) => (!startedAt ? '-' : format(new Date(startedAt), DATE_FORMAT)),
        },
        {
            title: 'End Date',
            dataIndex: 'finished_at',
            render: (finishedAt: string) => (!finishedAt ? '-' : format(new Date(finishedAt), DATE_FORMAT)),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (status: string) => <span style={{ color: STATUS_COLOR_MAP[status] }}>{STATUS_TEXT_MAP[status]}</span>,
        },
        {
            title: 'Requested By',
            dataIndex: 'requested_by',
        },
        {
            title: 'Created Date',
            dataIndex: 'created_at',
            render: (createdAt: string) => format(new Date(createdAt), DATE_FORMAT),
        },
        {
            title: 'Error Message',
            dataIndex: ['context', 'error'], // context?.error
            ellipsis: true,
            width: 240,
            render: (error: string) => error || '-',
        },
        {
            title: 'Details',
            dataIndex: 'context',
            render: (context: { [key: string]: string } | null) => {
                if (!context || context?.error) {
                    return '-';
                }

                const content = (
                    <div>
                        {Object.entries(context)
                            .sort(([a], [b]) => AsyncJobContextKeyList.indexOf(a) - AsyncJobContextKeyList.indexOf(b))
                            .map(([key, value]) => {
                                return (
                                    <p>
                                        {key.toString()}: {value.toString()}
                                    </p>
                                );
                            })}
                    </div>
                );

                return (
                    <Popover content={content} title="Details">
                        <InfoCircleOutlined style={{ color: PRIMARY_COLOR, fontSize: '16px', paddingLeft: '8px' }} />
                    </Popover>
                );
            },
        },
    ];

    return (
        <>
            <WithEmptyDataTable condition={!!isLoading}>
                <Table columns={columns} dataSource={asyncJobs} pagination={false} loading={isLoading} />
            </WithEmptyDataTable>

            <div style={{ padding: '8px 0' }}>
                <Typography.Text>*Shows the last {limit} operations, updates every minute</Typography.Text>
            </div>
        </>
    );
};
