import CaretRightOutlined from '@ant-design/icons/CaretRightOutlined';
import Collapse from 'antd/lib/collapse';
import List from 'antd/lib/list';
import Typography from 'antd/lib/typography';
import { IAffectedSites } from '../eventInterface';

export const ResourceCollapsedList = ({ resources, keyPrefix = '' }: { resources: IAffectedSites[]; keyPrefix?: string }) => (
    <Collapse accordion size="small" expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
        <Collapse.Panel
            key="1"
            header={
                <div>
                    Selected {resources.length} site{resources.length > 1 && 's'}
                </div>
            }
        >
            <List
                style={{
                    maxHeight: '400px',
                    overflow: 'auto',
                }}
                size="small"
                bordered={false}
                dataSource={resources}
                renderItem={item => (
                    <List.Item key={`${keyPrefix}${item.id}`}>
                        <Typography.Text ellipsis={{ tooltip: `${item.company}/${item.site}` }}>{`${item.company}/${item.site}`}</Typography.Text>
                    </List.Item>
                )}
            />
        </Collapse.Panel>
    </Collapse>
);
