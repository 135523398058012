import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../query-client';
import { fetchGroupedReadingsAvailabilityStats } from './index';
import { IGroupedReadingsAvailabilityStat, IFetchGroupedReadingsAvailabilityStats } from './interface';

export const useGroupedReadingsAvailabilityStatListQuery = (query: IFetchGroupedReadingsAvailabilityStats, options = {}) => {
    return useQuery<IGroupedReadingsAvailabilityStat[], Error>({
        queryKey: [QueryKey.READINGS_AVAILABILITY_STATS, query],
        queryFn: () => fetchGroupedReadingsAvailabilityStats(query),
        ...options,
    });
};
