import { isString, some, toLower } from 'lodash';
import { ICompany } from '../../../domain/company/interface';
import { IProgram } from '../../../domain/program/interface';
import { SiteImportTableIndexes as Idx } from './siteImportTableIndexes';
import { removeNonUTF8ASCII } from '../../../domain/stringCleaner';
import { ISite } from '../../../domain/site/interface';
import { groupBy } from 'lodash';

export interface ITableCellDto {
    value: string;
    error?: string | null;
    warning?: string | null;
}

interface IRecordFieldsToCheckForDuplicates {
    companyName: string;
    storeNumber: string;
    address: string;
    zipCode: string;
    state: string;
    city: string;
}

export class SitesParser {
    private companies: ICompany[] = [];
    private providers: ICompany[] = [];
    private utilityCustomers: ICompany[] = [];
    private programs: IProgram[] = [];

    parse(
        records: ITableCellDto[][],
        companies: ICompany[],
        providers: ICompany[],
        utilityCustomers: ICompany[],
        programs: IProgram[],
        duplicatedSites: ISite[]
    ): ITableCellDto[][] {
        this.companies = companies;
        this.providers = providers;
        this.utilityCustomers = utilityCustomers;
        this.programs = programs;

        // We are checking for duplicates between records in the import file and the existed sites in DB
        const dataFromImportToCheckForDuplicates = records.map(this.recordToDuplicateCheck);

        const groupedDataFromImportForDuplicatesCheck = groupBy(dataFromImportToCheckForDuplicates, 'companyName');
        const groupedDuplicatedSites = groupBy(duplicatedSites, 'company_id');

        records.forEach(record => {
            this.validateAndSetErrors(record);

            // Only check for duplicates for sites that are relate to same company
            const recordCompanyName = record[Idx.COMPANY_NAME].value;
            if (!recordCompanyName) return;

            const recordCompanyId = this.companies.find(company => company.company_name === recordCompanyName)?.company_id;
            if (!recordCompanyId) return;

            const duplicatesInReportByCompany = groupedDataFromImportForDuplicatesCheck[recordCompanyName] || [];
            const duplicatedSitesCompany = groupedDuplicatedSites[recordCompanyId] || [];

            this.setWarnings(record, duplicatesInReportByCompany, duplicatedSitesCompany);
        });

        return records;
    }

    private validateAndSetErrors(record: any[]) {
        record = this.cleanStringItems(record);

        record[Idx.COMPANY_NAME].error = this.validateCompany(record[Idx.COMPANY_NAME].value, this.companies);
        record[Idx.CONTROL_PROVIDER_NAME].error = this.validateControlProvider(record[Idx.CONTROL_PROVIDER_NAME].value, this.providers);
        record[Idx.UTILITY_CUSTOMER_NAME].error = this.validateUtilityCustomer(record[Idx.UTILITY_CUSTOMER_NAME].value, this.utilityCustomers);
        record[Idx.PROGRAMS].error = this.validatePrograms(record[Idx.PROGRAMS].value, this.programs);
        record[Idx.SITE_NAME].error = this.validateSiteName(record[Idx.SITE_NAME].value);
        record[Idx.SITE_STORE_NUMBER].error = this.checkForIncorrectEncodedValues(record[Idx.SITE_STORE_NUMBER].value);
        record[Idx.SITE_ADDRESS].error = this.validateSiteAddress(record[Idx.SITE_ADDRESS].value);
        record[Idx.SITE_CITY].error = this.validateSiteCity(record[Idx.SITE_CITY].value);
        record[Idx.SITE_STATE].error = this.validateSiteState(record[Idx.SITE_STATE].value);
        record[Idx.SITE_ZIP].error = this.validateSiteZip(record[Idx.SITE_ZIP].value);
        record[Idx.SITE_MARKET].error = this.validateMarket(record[Idx.SITE_MARKET].value);
        record[Idx.SITE_LOAD_ZONE].error = this.validateLoadZone(record[Idx.SITE_LOAD_ZONE].value, record[Idx.SITE_MARKET]);
        record[Idx.SITE_ESTIMATED_KW].error = this.checkForIncorrectEncodedValues(record[Idx.SITE_ESTIMATED_KW].value);
        record[Idx.SITE_SERVICE_ACCOUNT_NUMBER].error = this.validateEnrollmentIds(record[Idx.SITE_SERVICE_ACCOUNT_NUMBER].value);
        record[Idx.SITE_UTILITY].error = this.checkForIncorrectEncodedValues(record[Idx.SITE_UTILITY].value);
        record[Idx.SITE_SLAP].error = this.checkForIncorrectEncodedValues(record[Idx.SITE_SLAP].value);
        record[Idx.SITE_NETWORK].error = this.checkForIncorrectEncodedValues(record[Idx.SITE_NETWORK].value);
        record[Idx.EVENT_OFFSET].error = this.checkForIncorrectEncodedValues(record[Idx.EVENT_OFFSET].value);
        record[Idx.EVENT_MAX_DURATION].error = this.checkForIncorrectEncodedValues(record[Idx.EVENT_MAX_DURATION].value);
        record[Idx.CREATE_VEN].error = this.checkForIncorrectEncodedValues(record[Idx.CREATE_VEN].value);
        record[Idx.PARTNER].error = this.checkForIncorrectEncodedValues(record[Idx.PARTNER].value);

        return record;
    }

    private cleanStringItems(record: any[]) {
        const stringIndexes = [
            Idx.SITE_NAME,
            Idx.SITE_STORE_NUMBER,
            Idx.SITE_ADDRESS,
            Idx.SITE_CITY,
            Idx.SITE_STATE,
            Idx.SITE_ZIP,
            Idx.SITE_SLAP,
            Idx.SITE_NETWORK,
            Idx.SITE_UTILITY,
            Idx.PARTNER,
            Idx.SITE_LOAD_ZONE,
            Idx.SITE_MARKET,
            Idx.SITE_SERVICE_ACCOUNT_NUMBER,
        ];

        for (const key of stringIndexes) {
            record[key].value = removeNonUTF8ASCII(record[key].value);
        }

        return record;
    }

    private validateEnrollmentIds(enrollmentIdString: string | null): string | null {
        let incorrectEncodedValues = this.checkForIncorrectEncodedValues(enrollmentIdString || '');
        if (incorrectEncodedValues) return incorrectEncodedValues;

        if (!enrollmentIdString) return null;

        const enrollmentIds = enrollmentIdString
            .split(',')
            .map(id => id.trim())
            .filter(Boolean);
        if (enrollmentIds.length > 100) {
            return 'Maximum 100 enrollment IDs allowed';
        }

        return null;
    }

    private validateCompany(name: string, companies: ICompany[]): string | null {
        if (!name) return `company name is required`;

        const encodingError = this.checkForIncorrectEncodedValues(name);
        if (encodingError) return encodingError;

        const unknown = !some(companies, company => toLower(company.company_name) === toLower(name));
        if (unknown) return `unknown company`;

        return null;
    }

    private validateControlProvider(name: string, providers: ICompany[]): string | null {
        /** control provider is not required */
        if (!name) return null;

        const encodingError = this.checkForIncorrectEncodedValues(name);
        if (encodingError) return encodingError;

        const unknown = !some(providers, provider => toLower(provider.company_name) === toLower(name));
        if (unknown) return `unknown control provider`;

        return null;
    }

    private validateUtilityCustomer(name: string, utilityCustomers: ICompany[]): string | null {
        /** utility customer is not required */
        if (!name) return null;

        const encodingError = this.checkForIncorrectEncodedValues(name);
        if (encodingError) return encodingError;

        const unknown = !some(utilityCustomers, item => toLower(item.company_name) === toLower(name));
        if (unknown) return `unknown utility customer`;

        return null;
    }

    private validatePrograms(programsStr: string, programs: IProgram[]): string | null {
        if (!programsStr) return null;

        const encodingError = this.checkForIncorrectEncodedValues(programsStr);
        if (encodingError) return encodingError;

        // Split and trim program names, remove redundant spaces, filter out empty strings
        const programNames = programsStr
            .split(',')
            .map(p => p.trim())
            .filter(Boolean);

        // Collect errors for unknown programs
        const unknownErrors = programNames.reduce((errors, name) => {
            const isKnownProgram = programs.some(program => toLower(program.name) === toLower(name));

            if (!isKnownProgram) {
                errors.push(`unknown program - "${name}"`);
            }

            return errors;
        }, [] as string[]);

        return unknownErrors.length > 0 ? unknownErrors.join('; ') : null;
    }

    private validateMarket(value: string) {
        return this.checkForIncorrectEncodedValues(value);
    }

    private validateLoadZone(loadZone?: string, market?: string) {
        if (!market && loadZone) return `cannot have load zone without market`;

        const encodingError = this.checkForIncorrectEncodedValues(loadZone);
        if (encodingError) return encodingError;
    }

    private validateSiteName(value: string) {
        if (!value) return `site name is required`;

        const encodingError = this.checkForIncorrectEncodedValues(value);
        if (encodingError) return encodingError;
    }

    private validateSiteAddress(value: string) {
        if (!value) return `address is required`;

        const encodingError = this.checkForIncorrectEncodedValues(value);
        if (encodingError) return encodingError;
    }

    private validateSiteCity(value: string) {
        if (!value) return `city is required`;

        const encodingError = this.checkForIncorrectEncodedValues(value);
        if (encodingError) return encodingError;
    }

    private validateSiteState(value: string) {
        if (!value) return `state is required`;

        const encodingError = this.checkForIncorrectEncodedValues(value);
        if (encodingError) return encodingError;
    }

    private validateSiteZip(value: string | number) {
        if (!value) return `zip is required`;

        const encodingError = this.checkForIncorrectEncodedValues(value);
        if (encodingError) return encodingError;
    }

    private checkForIncorrectEncodedValues(value?: string | number): string | null {
        const incorrectEncodeSymbol = '�';

        // Check for string as zip code can be parsed as number
        if (isString(value) && value.includes(incorrectEncodeSymbol)) {
            return 'Your file contains incorrect encoded data, please make sure to save file with UTF-8 encoding format';
        }

        return null;
    }

    private recordToDuplicateCheck(record: ITableCellDto[]): IRecordFieldsToCheckForDuplicates {
        return {
            companyName: record[Idx.COMPANY_NAME].value,
            storeNumber: record[Idx.SITE_STORE_NUMBER].value,
            address: record[Idx.SITE_ADDRESS].value,
            state: record[Idx.SITE_STATE].value,
            zipCode: record[Idx.SITE_ZIP].value,
            city: record[Idx.SITE_CITY].value,
        };
    }

    private setWarnings(record: ITableCellDto[], dataFromImport: IRecordFieldsToCheckForDuplicates[], duplicatedSites: ISite[]) {
        record[Idx.SITE_STORE_NUMBER].warning = this.setWarningForDuplicatedStoreNumbers(record, dataFromImport, duplicatedSites);
        record[Idx.SITE_ADDRESS].warning = this.setWarningForDuplicatedAddress(record, dataFromImport, duplicatedSites);
    }

    private setWarningForDuplicatedStoreNumbers(
        record: ITableCellDto[],
        dataFromImport: IRecordFieldsToCheckForDuplicates[],
        duplicatedSites: ISite[]
    ): string | null {
        const recordStoreNumber = record[Idx.SITE_STORE_NUMBER].value;
        if (!recordStoreNumber) {
            return null;
        }

        const duplicateStoreNumberFromExistedSites = duplicatedSites.some(site => site.site_store_number === recordStoreNumber);

        if (duplicateStoreNumberFromExistedSites) {
            return 'Site with this store number already exists.';
        }

        const duplicatesFromImportFile = dataFromImport.filter(recordFromImport => recordFromImport.storeNumber === recordStoreNumber);

        if (duplicatesFromImportFile.length > 1) {
            return 'Site with this store number duplicates another site from this import file.';
        }

        return null;
    }

    private setWarningForDuplicatedAddress(
        record: ITableCellDto[],
        dataFromImport: IRecordFieldsToCheckForDuplicates[],
        duplicatedSites: ISite[]
    ): string | null {
        const recordAddress = record[Idx.SITE_ADDRESS].value;
        const recordCity = record[Idx.SITE_CITY].value;
        const recordZipCode = record[Idx.SITE_ZIP].value;
        const recordState = record[Idx.SITE_STATE].value;

        const isAllAddressFieldFilled = recordAddress && recordCity && recordZipCode && recordState;
        if (!isAllAddressFieldFilled) return null;

        const duplicateAddressFromExistedSites = duplicatedSites.some(
            site =>
                site.site_address === recordAddress &&
                site.site_city === recordCity &&
                site.site_state === recordState &&
                site.site_zip === recordZipCode
        );

        if (duplicateAddressFromExistedSites) {
            return 'Site with this address already exists.';
        }

        const duplicatesFromImportFile = dataFromImport.filter(
            recordFromImport =>
                recordFromImport.address === recordAddress &&
                recordFromImport.city === recordCity &&
                recordFromImport.state === recordState &&
                recordFromImport.zipCode === recordZipCode
        );

        if (duplicatesFromImportFile.length > 1) {
            return 'Site with this address duplicates another site from this import file.';
        }

        return null;
    }
}
