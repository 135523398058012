import { useDataInboxFileInfoQuery } from '../../../domain/data-inbox/queries';
import { INYISOResourceFile } from '../../../domain/nyiso/interface';
import Typography from 'antd/lib/typography';
import Tag from 'antd/lib/tag';
import Popover from 'antd/lib/popover';
import { DataInboxFileStatus } from '../../../domain/data-inbox/interface';
import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined';
import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';
import { INFO_COLOR, ERROR_COLOR, SECONDARY_COLOR, PRIMARY_COLOR } from '../../../theme';
import { MinusCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined';
import { getComputerFormattedTime } from '../../../domain/date/date';

interface IEnergyDataFileInfoProps {
    file: INYISOResourceFile;
}

export const EnergyDataFileInfo = ({ file }: IEnergyDataFileInfoProps) => {
    const { data: fileInfo, isLoading, isError } = useDataInboxFileInfoQuery(file.file_path, { refetchInterval: 60000 });

    if (!fileInfo || isLoading || isError) {
        return null;
    }

    const fileStatus = (status: DataInboxFileStatus) => {
        if (status === DataInboxFileStatus.FAILED)
            return (
                <Tag icon={<CloseCircleOutlined />} color={ERROR_COLOR}>
                    Failed
                </Tag>
            );

        if (status === DataInboxFileStatus.PENDING) {
            return (
                <Tag icon={<ClockCircleOutlined />} color={INFO_COLOR}>
                    Pending
                </Tag>
            );
        }

        if (status === DataInboxFileStatus.SKIPPED) {
            return (
                <Tag icon={<MinusCircleOutlined />} color={SECONDARY_COLOR}>
                    Skipped
                </Tag>
            );
        }

        if (status === DataInboxFileStatus.DONE) {
            return (
                <Tag icon={<CheckCircleOutlined />} color={PRIMARY_COLOR}>
                    Parsed
                </Tag>
            );
        }
        return (
            <Tag icon={<QuestionCircleOutlined />} color={SECONDARY_COLOR}>
                Unknown
            </Tag>
        );
    };

    return (
        <Typography.Text>
            Latest energy data file: <Typography.Text strong>{file.originalname}</Typography.Text> <br />
            Date uploaded: <Typography.Text strong>{getComputerFormattedTime(new Date(fileInfo.createdAt))}</Typography.Text> <br />
            Status:{' '}
            {fileInfo.message ? (
                <Popover content={'Message: ' + fileInfo.message}>
                    <Typography.Text strong>{fileStatus(fileInfo.status)}</Typography.Text>
                </Popover>
            ) : (
                <Typography.Text strong>{fileStatus(fileInfo.status)}</Typography.Text>
            )}
        </Typography.Text>
    );
};
