import { createContext, useContext } from 'react';
import { Market } from 'src/domain/market-prices/interface';
import { IWeatherLocation } from 'src/domain/weather/interface';
import { usePeakLoadForecastData } from './usePeakLoadForecastData';
import Spin from 'antd/lib/spin';
import {
    PeakLoadForecastCustomizeSettings,
    usePeakLoadForecastCustomizeSettings,
} from './PeakLoadForecastCustomizeView/usePeakLoadForecastCustomizeSettings';

interface PeakLoadForecastContextInterface {
    markets: Record<Market, string[]>;
    locations: IWeatherLocation[];
    defaultMarket: Market;
    defaultLoadZone: string;
    defaultLocation: IWeatherLocation;
    customizeViewSettings: PeakLoadForecastCustomizeSettings;
    setCustomizeViewSettings: (
        value: PeakLoadForecastCustomizeSettings | ((val: PeakLoadForecastCustomizeSettings) => PeakLoadForecastCustomizeSettings)
    ) => void;
}

const PeakLoadForecastContext = createContext<PeakLoadForecastContextInterface | undefined>(undefined);

export const usePeakLoadForecastContext = () => {
    const context = useContext(PeakLoadForecastContext);
    if (context === undefined) {
        throw new Error('usePeakLoadForecastContext must be used within a PeakLoadForecastProvider');
    }

    return context;
};

/**
 * This provider loads all needed data for the Peak Load Forecast page
 */
export const PeakLoadForecastProvider = ({ children }: { children: React.ReactNode }) => {
    /** here we need to load initial data - without it we cannot render other components */
    const { isFetching: isInitialDataFetching, markets, locations, defaultMarket, defaultLoadZone } = usePeakLoadForecastData();
    const { settings: customizeViewSettings, setSettings: setCustomizeViewSettings } = usePeakLoadForecastCustomizeSettings();

    if (isInitialDataFetching) {
        return <Spin />;
    }

    const defaultLocation = locations[0];

    return (
        <PeakLoadForecastContext.Provider
            value={{
                markets,
                locations,
                defaultMarket,
                defaultLoadZone,
                defaultLocation,
                customizeViewSettings,
                setCustomizeViewSettings,
            }}
        >
            {children}
        </PeakLoadForecastContext.Provider>
    );
};

export { PeakLoadForecastContext };
