import { FC } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import Button from 'antd/lib/button';
import Space from 'antd/lib/space';
import { add, isSameMonth, startOfHour, sub } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

type PeakLoadForecastDateSelectorProps = {
    start: Date;
    end: Date;
    timezone: string;
    onChange: (val: { start: number; end: number; newSelectedDay?: Date }) => void;
};

export const PeakLoadForecastDateSelector: FC<PeakLoadForecastDateSelectorProps> = ({ start, end, timezone, onChange }) => {
    const isNextDisabled = !isAllowedDate(start);

    function onClickToday() {
        const today = new Date();
        const newStart = startOfHour(today).valueOf();
        const newEnd = startOfHour(add(newStart, { days: 6 })).valueOf();

        onChange({ start: newStart, end: newEnd, newSelectedDay: today });
    }

    function onClickNext() {
        const newDate = add(start, { days: 1 });
        const newStart = startOfHour(newDate).valueOf();
        const newEnd = startOfHour(add(newDate, { days: 6 })).valueOf();

        onChange({ start: newStart, end: newEnd });
    }

    function onClickPrev() {
        const newDate = sub(start, { days: 1 });
        const newStart = startOfHour(newDate).valueOf();
        const newEnd = startOfHour(add(newDate, { days: 6 })).valueOf();

        onChange({ start: newStart, end: newEnd });
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div key="peak-load-week" style={{ lineHeight: '32px' }}>
                {makeWeekDateLabel(new Date(start), new Date(end), timezone)}
            </div>
            <Button key="peak-load-today" style={{ margin: '0 20px' }} onClick={onClickToday}>
                Today
            </Button>
            <Space.Compact key="peak-load-prev-next">
                <Button icon={<LeftOutlined />} onClick={onClickPrev} key="peak-load-prev" variant="text" />
                <Button icon={<RightOutlined />} onClick={onClickNext} disabled={isNextDisabled} key="peak-load-next" />
            </Space.Compact>
        </div>
    );
};

/** do not allow to select days which are after today+8 days - anyway we will not have data for this */
function isAllowedDate(newStart: Date) {
    const today = new Date();

    return newStart.valueOf() < add(today, { days: 8 }).valueOf();
}

function makeWeekDateLabel(start: Date, end: Date, timezone: string) {
    if (isSameMonth(start, end)) {
        return `This week: ${formatInTimeZone(start, timezone, 'MMMM do')} - ${formatInTimeZone(end, timezone, 'do, yyyy')}`;
    } else {
        return `This week: ${formatInTimeZone(start, timezone, 'MMMM do')} - ${formatInTimeZone(end, timezone, 'MMMM do, yyyy')}`;
    }
}
