import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import AntApp from 'antd/lib/app';
import { CookiesProvider } from 'react-cookie';
import { Navigate, Route, Routes } from 'react-router-dom';
import { GreenButtonConnect } from './components/greenButtonConnect/greenButtonConnect';
import { ReCaptchaProvider } from './domain/auth/recaptcha';
import { PrivateRoute, ProvideAuth } from './domain/auth/useAuth';
import { AuthLayout } from './layouts/auth/AuthLayout';
import { PrivateLayout } from './layouts/private/PrivateLayout';
import { PublicLayout } from './layouts/public/PublicLayout';
import { ReadingsAvailabilityStats } from './pages/adminDashboard/ReadingsAvailabilityStats';
import { ReadingsAvailabilityStatsByCompany } from './pages/adminDashboard/ReadingsAvailabilityStatsByCompany';
import { ReadingsAvailabilityStatsByEnrollment } from './pages/adminDashboard/ReadingsAvailabilityStatsByEnrollment';
import { RecentlyCreatedOrClosedSitesReporting } from './pages/adminDashboard/RecentlyCreatedOrClosedSitesReporting';
import { Caiso } from './pages/caiso/caiso';
import { DrrsView, SibrView } from './pages/caiso/view';
import { CleanResponse } from './pages/cleanResponse/CleanResponse';
import { Companies } from './pages/companies/Companies';
import { CompleteSignup } from './pages/completeSignup/completeSignup';
import { Dashboard } from './pages/dashboard/dashboard';
import { EnergyUsageDataAnalytics } from './pages/energyUsageData/view/EnergyUsageDataAnalytics';
import { EnergyUsageDataConnect } from './pages/energyUsageData/view/EnergyUsageDataConnect';
import { EnergyUsageDataSummary } from './pages/energyUsageData/view/EnergyUsageDataSummary';
import { Events } from './pages/events/Events';
import { Impact } from './pages/impact/impact';
import { Login } from './pages/login/login';
import { NyisoResourcesList } from './pages/nyiso/NyisoResourcesList';
import { PriceResponse } from './pages/priceResponse/PriceResponse';
import { PriceResponseReporting } from './pages/priceResponseReporting/priceResponseReporting';
import { Programs } from './pages/programs/Programs';
import { ResetPassword } from './pages/resetPassword/ResetPassword';
import { ResetPasswordComplete } from './pages/resetPasswordComplete/resetPasswordComplete';
import { Settlements } from './pages/settlements/Settlements';
import { Sites } from './pages/sites/Sites';
import { Users } from './pages/users/Users';
import { Vens as VenDevices } from './pages/ven/vens';
import { CloudVenView, CustomerVenView, OverviewView } from './pages/ven/view';
import { queryClient } from './query-client';
import './App.css';
import { PeakLoadForecast } from './pages/peak-load-forecast/PeakLoadForecast';
import { PeakLoadForecastProvider } from './pages/peak-load-forecast/PeakLoadForecastContext';
import { BrandingProvider } from './domain/company/branding/BrandingProvider';

export function App() {
    return (
        <AntApp>
            <div className="App">
                <QueryClientProvider client={queryClient}>
                    <CookiesProvider>
                        <ReCaptchaProvider>
                            <ProvideAuth>
                                <BrandingProvider>
                                    <Routes>
                                        <Route path="/:url*(/+)" element={<Navigate to={document.location.pathname.slice(0, -1)} />} />
                                        <Route element={<AuthLayout />}>
                                            <Route path="/login" element={<Login />} />
                                            <Route path="/reset-password" element={<ResetPassword />} />
                                            <Route path="/reset-password/complete/token/:token" element={<ResetPasswordComplete />} />
                                            <Route path="/complete-signup/token/:token" element={<CompleteSignup />} />
                                        </Route>
                                        <Route element={<PublicLayout />}>
                                            <Route path="/greenbutton" element={<GreenButtonConnect />} />
                                        </Route>
                                        <Route element={<PrivateRoute />}>
                                            <Route element={<PrivateLayout />}>
                                                <Route path="/companies" element={<Companies />} />
                                                <Route path="/sites" element={<Sites />} />
                                                <Route path="/ven-devices" element={<VenDevices />}>
                                                    <Route index element={<Navigate to="/" replace />} />
                                                    <Route path="overview" element={<OverviewView />} />
                                                    <Route path="cloud-ven" element={<CloudVenView />} />
                                                    <Route path="customer-ven" element={<CustomerVenView />} />
                                                </Route>
                                                <Route path="/energy-usage-data/summary" element={<EnergyUsageDataSummary />} />
                                                <Route path="/energy-usage-data/connect" element={<EnergyUsageDataConnect />} />
                                                <Route path="/energy-usage-data/analytics" element={<EnergyUsageDataAnalytics />} />
                                                <Route path="/reporting/price-response" element={<PriceResponseReporting />} />
                                                {/*<Route path="/reporting/power-bi" element={<PowerBIEmbeddedReport />} />*/}
                                                <Route path="/settlements" element={<Settlements />} />
                                                <Route path="/events" element={<Events />} />
                                                <Route path="/programs" element={<Programs />} />
                                                <Route path="/trigger/price-response" element={<PriceResponse />} />
                                                <Route path="/trigger/clean-response" element={<CleanResponse />} />
                                                <Route path="/users" element={<Users />} />
                                                <Route path="/impact" element={<Impact />} />
                                                <Route path="caiso" element={<Caiso />}>
                                                    <Route path="sibr/*" element={<SibrView />} />
                                                    <Route path="drrs/*" element={<DrrsView />} />
                                                </Route>
                                                <Route path="/nyiso-resources" element={<NyisoResourcesList />} />
                                                <Route
                                                    path="/peak-load-forecast"
                                                    element={
                                                        <PeakLoadForecastProvider>
                                                            <PeakLoadForecast />
                                                        </PeakLoadForecastProvider>
                                                    }
                                                />

                                                <Route path="/admin/data-quality" element={<ReadingsAvailabilityStats />}>
                                                    <Route index element={<Navigate to="by-company" replace />} />
                                                    <Route path="by-company" element={<ReadingsAvailabilityStatsByCompany />} />
                                                    <Route path="by-enrollment" element={<ReadingsAvailabilityStatsByEnrollment />} />
                                                </Route>

                                                <Route path="/admin/new-and-closed-sites" element={<RecentlyCreatedOrClosedSitesReporting />} />

                                                <Route path="/" element={<Dashboard />} />
                                                <Route path="*" element={<Navigate to="/" replace />} />
                                            </Route>
                                        </Route>
                                    </Routes>
                                </BrandingProvider>
                            </ProvideAuth>
                        </ReCaptchaProvider>
                    </CookiesProvider>
                    <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
            </div>
        </AntApp>
    );
}
