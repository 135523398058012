import Badge from 'antd/lib/badge';
import { NYISOResourceStatus, NyisoSeasonResourceData } from '../../../domain/nyiso/interface';
import { ERROR_COLOR, INFO_COLOR, PRIMARY_COLOR, SECONDARY_COLOR } from '../../../theme';
import Popover from 'antd/lib/popover';

interface IResourceStatusProps {
    isEnabled: boolean;
    peakHoursReady: boolean;
    seasonalData?: NyisoSeasonResourceData | null;
}

export const ResourceStatus = ({ isEnabled, peakHoursReady, seasonalData }: IResourceStatusProps) => {
    const renderBadge = (text: string, color: string, popoverMessage?: string) => {
        if (popoverMessage) {
            return (
                <Popover content={popoverMessage}>
                    <Badge color={color} text={text} />
                </Popover>
            );
        }

        return <Badge color={color} text={text} />;
    };

    if (!isEnabled) return renderBadge('Disabled', SECONDARY_COLOR);

    // When peak hours for new season is not uploaded - display an error
    if (!peakHoursReady) return renderBadge('Error', ERROR_COLOR, 'Peak Hours data for current season is missing');

    // When peak hours are uploaded but season data is missing - show status "Processing"
    if (!seasonalData) {
        return renderBadge('Processing', INFO_COLOR);
    }

    if (seasonalData.status === NYISOResourceStatus.ERROR) return renderBadge('Error', ERROR_COLOR, seasonalData.error_message);

    if (seasonalData.status === NYISOResourceStatus.PROCESSING) return renderBadge('Processing', INFO_COLOR);

    if (seasonalData.status === NYISOResourceStatus.SUCCESS) return renderBadge('Ready', PRIMARY_COLOR);

    return renderBadge('Unknown', SECONDARY_COLOR);
};
