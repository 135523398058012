import InboxOutlined from '@ant-design/icons/InboxOutlined';
import Form from 'antd/lib/form';
import Modal from 'antd/lib/modal';
import Typography from 'antd/lib/typography';
import Dragger from 'antd/lib/upload/Dragger';
import Row from 'antd/lib/row';
import React, { useEffect, useState } from 'react';
import Col from 'antd/lib/col';
import { App, Button, theme, UploadFile, ColorPicker } from 'antd/lib';
import { LIST_IGNORE } from 'antd/lib/upload/Upload';
import { ICompanyBranding, IUpdateCompanyBranding } from '../../../domain/company/branding/interface';
import { useCompanyBrandingQuery, useCompanyBrandingUpdateMutation } from '../../../domain/company/branding/queries';
import { ICompany } from '../../../domain/company/interface';
import { UploadChangeParam } from 'antd/lib/upload';
import './CompanyBrandingModal.css';

interface ICompanyBrandingModal {
    company: ICompany | Partial<ICompany>;
    onClose: Function;
}

const ALLOWED_FILE_SIZE_KB = 256;

const ALLOWED_FILE_TYPES = ['.png'];

export const CompanyBrandingModal = ({ company: { company_id }, onClose }: ICompanyBrandingModal) => {
    const { token: themeColors } = theme.useToken();

    const [open, setOpen] = useState(false);
    const { notification } = App.useApp();
    const [form] = Form.useForm();
    const [file, setFile] = useState<UploadFile>();

    const { data, isLoading: loading, isError } = useCompanyBrandingQuery({ companyId: company_id! });
    const { mutateAsync: updateCompanyBranding, isLoading } = useCompanyBrandingUpdateMutation();

    const onCloseCompanyBrandingModal = () => onClose();

    useEffect(() => {
        if (!loading) {
            setOpen(true);
        }

        if (data) {
            form.setFieldsValue(data);
            setFile(fileToVirtualFile(data));
        }
    }, [loading]);

    function fileToVirtualFile(branding?: ICompanyBranding | null): UploadFile | undefined {
        if (branding?.logoUrl) {
            const fileName = branding?.logoUrl.split('/').pop();
            return { uid: '1', name: fileName!, url: branding?.logoUrl, status: 'done', type: 'image/png' };
        }
    }

    const uploadProps = {
        defaultFileList: file ? [file] : [],
        accept: ALLOWED_FILE_TYPES.join(','),
        multiple: false,
        maxCount: 1,
        onRemove: (file: any) => {
            console.info('uploadProps onRemove', file);
            setFile(undefined);
        },
        beforeUpload: (file: UploadFile) => {
            const fileSizeInKB = file.size! / 1024;
            if (fileSizeInKB > ALLOWED_FILE_SIZE_KB) {
                notification.warning({
                    key: 'upload-file-size-notification',
                    message: `Please select file up to ${ALLOWED_FILE_SIZE_KB}KB.`,
                });
                return LIST_IGNORE;
            }

            if (!ALLOWED_FILE_TYPES.some(type => file?.name?.toLowerCase().endsWith(type))) {
                notification.warning({
                    key: 'upload-file-type-notification',
                    message: `Please select "${ALLOWED_FILE_TYPES.join('" or "')}" file.`,
                });
                return LIST_IGNORE;
            }

            return false;
        },
        onChange: (info: UploadChangeParam) => {
            if (info.file.status || !info.fileList.length) {
                return;
            }

            setFile(info.file);
        },
    };

    const onFormFinish = async (companyBranding: IUpdateCompanyBranding) => {
        const preparedCompanyBranding = {
            primaryColor: companyBranding.primaryColor,
            logoFile: file,
        };

        try {
            console.info('onFormFinish', companyBranding);

            await updateCompanyBranding({ companyId: company_id!, companyBranding: preparedCompanyBranding });

            onCloseCompanyBrandingModal();
        } catch (err: any) {
            console.log('Company branding update failed with error: ', err);
        }
    };

    return (
        <Modal
            footer={[
                <Button key="company-branding-modal-cancel" onClick={onCloseCompanyBrandingModal}>
                    Cancel
                </Button>,
                <Button key="company-branding-modal-submit" type="primary" onClick={form.submit} loading={isLoading}>
                    Save
                </Button>,
            ]}
            open={open}
            destroyOnClose
            title="Edit Company Branding"
            onCancel={onCloseCompanyBrandingModal}
            data-cy="company-branding-modal"
        >
            <Form
                layout="vertical"
                name="company-branding-form"
                form={form}
                onFinish={onFormFinish}
                preserve={false}
                initialValues={{ primaryColor: themeColors.colorPrimary }}
            >
                <Form.Item
                    name="primaryColor"
                    required
                    hasFeedback
                    label={<Typography.Text strong>Primary Color</Typography.Text>}
                    rules={[{ required: true, message: 'Please enter primary color!' }]}
                    getValueFromEvent={color => `#${color.toHex()}`}
                    tooltip="Primary color it is base color that used to indicate active state of buttons and other components."
                >
                    <ColorPicker size="large" disabledAlpha showText />
                </Form.Item>
                <Form.Item
                    name="dragger"
                    required
                    label={<Typography.Text strong>Logo file</Typography.Text>}
                    rules={[
                        {
                            required: true,
                            message: 'Please enter logo file!',
                            validator(_, value) {
                                if (file) {
                                    return Promise.resolve();
                                }

                                return Promise.reject();
                            },
                        },
                    ]}
                >
                    <Dragger {...uploadProps} height={120} listType="picture">
                        <Row align="middle" justify="center" style={{ paddingLeft: 8, paddingRight: 8 }}>
                            <Col xs={24}>
                                <InboxOutlined style={{ fontSize: '20px', marginRight: '4px', color: themeColors.colorPrimary }} />
                                <Typography.Text>Click or drag file to this area to upload</Typography.Text>
                                <Typography.Text type="secondary" style={{ display: 'block' }}>
                                    The size limit is {ALLOWED_FILE_SIZE_KB}KB
                                </Typography.Text>
                                <Typography.Text type="secondary" style={{ display: 'block' }}>
                                    Recommended height of logo image should have up to 200 px!
                                </Typography.Text>
                            </Col>
                        </Row>
                    </Dragger>
                </Form.Item>
            </Form>
        </Modal>
    );
};
