import React from 'react';
import Typography from 'antd/lib/typography';

interface IEventLegendListItemProps {
    title: string;
    background: string;
    color?: string;
    abbr?: string;
}

export const EventLegendListItem: React.FC<IEventLegendListItemProps> = ({ title, background, color = '#ffffff', abbr = '' }) => {
    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', textWrap: 'nowrap' }}>
            <div
                style={{
                    minWidth: 125,

                    height: 18,
                    fontSize: '12px',
                    lineHeight: '14px',
                    fontWeight: 'normal',

                    border: '1px solid black',
                    marginRight: 4,
                    display: 'flex',
                    alignItems: 'center',

                    textWrap: 'nowrap',
                    padding: abbr ? '0 5px' : 0,
                    color,
                    background,
                }}
            >
                {abbr}
            </div>
            <Typography.Text>{title}</Typography.Text>
        </div>
    );
};
