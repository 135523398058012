import { format } from 'date-fns';
import { API } from '../../../api';
import { IFetchPageQuery } from '../../../IFetchQueryOptions';
import { IPagination } from '../../../IPagination';
import { prepareFilters } from '../utils';
import { ICaisoRegistration, ICaisoRegistrationLocation } from './interface';
import { DATE_FORMAT_CSV } from 'src/domain/commonConst';
import { exportCSVData } from 'src/domain/common/exportCSVData';

export const fetchCaisoRegistrations = async (pageQuery: IFetchPageQuery, signal?: AbortSignal | null): Promise<IPagination<ICaisoRegistration>> => {
    let { pagination, sorter, search = '', include, filter } = pageQuery;

    const limit = pagination?.pageSize || 10;
    const offset = (pagination?.current ? pagination.current - 1 : 0) * limit;

    if (sorter && (!sorter.field || !sorter.order)) {
        sorter = {};
    }

    const path = `/scheduling-coordinator/caiso/drrs/registrations?${new URLSearchParams({
        offset: offset.toString(),
        limit: limit.toString(),
        ...(sorter?.field && { sortField: sorter.field, sortOrder: sorter.order }),
        ...(search && { search }),
        ...(include && { include }),
        ...prepareFilters(filter),
    })}`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot get caiso registrations data!`);
};

export const fetchCaisoRegistrationFiltersList = async (
    filter: 'status' | 'udc' | 'sub_lap' | 'resource_id',
    signal?: AbortSignal | null
): Promise<string[]> => {
    const response = await API.fetch(
        `/scheduling-coordinator/caiso/drrs/registrations/filters?${new URLSearchParams({
            filter,
        })}`,
        {
            ...(signal && { signal }),
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody.map((item: { value: string }) => item.value);
    }

    throw new Error(respBody?.error?.message || `Cannot get caiso location groups!`);
};

export const fetchCaisoRegistrationsLocations = async (
    registration_id: number,
    pageQuery: IFetchPageQuery,
    signal?: AbortSignal | null
): Promise<IPagination<ICaisoRegistrationLocation>> => {
    let { pagination, sorter } = pageQuery;

    const limit = pagination?.pageSize || 10;
    const offset = (pagination?.current ? pagination.current - 1 : 0) * limit;

    if (sorter && (!sorter.field || !sorter.order)) {
        sorter = {};
    }

    const path = `/scheduling-coordinator/caiso/drrs/registrations/${registration_id}/locations?${new URLSearchParams({
        offset: offset.toString(),
        limit: limit.toString(),
        ...(sorter?.field && { sortField: sorter.field, sortOrder: sorter.order }),
    })}`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot get caiso registrations data!`);
};

export const exportRegistrations = async (params: IFetchPageQuery, signal?: AbortSignal | null) => {
    let { search = '', include, filter } = params;

    const path = `/scheduling-coordinator/caiso/drrs/registrations?${new URLSearchParams({
        ...(search && { search }),
        ...(include && { include }),
        ...(filter && prepareFilters(filter)),
        format: 'csv',
        limit: '1000000',
    })}`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        method: 'get',
        headers: {
            'Content-Type': 'text/csv',
        },
    });

    if (!response.ok) {
        const errorBody: any = await response.json();
        throw new Error(errorBody?.error?.message || `Cannot export registrations data!`);
    }

    const respBody: string = await response.text();
    const filename = `drrs-registrations-${format(new Date(), DATE_FORMAT_CSV)}.csv`;
    exportCSVData(respBody, filename);
};

export const exportRegistrationLocations = async (
    registration_id: number,
    registration_name: string | undefined,
    pageQuery: IFetchPageQuery,
    signal?: AbortSignal | null
) => {
    let { search = '', include, filter } = pageQuery;

    const path = `/scheduling-coordinator/caiso/drrs/registrations/${registration_id}/locations?${new URLSearchParams({
        ...(search && { search }),
        ...(include && { include }),
        ...(filter && prepareFilters(filter)),
        format: 'csv',
        limit: '1000000',
    })}`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        method: 'get',
        headers: {
            'Content-Type': 'text/csv',
        },
    });

    if (!response.ok) {
        const errorBody: any = await response.json();
        throw new Error(errorBody?.error?.message || `Cannot export registration locations data!`);
    }

    const respBody: string = await response.text();
    const filename = `drrs-registration-locations-${registration_name}-${format(new Date(), DATE_FORMAT_CSV)}.csv`;
    exportCSVData(respBody, filename);
};
