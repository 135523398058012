import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import App from 'antd/lib/app';
import { QueryKey } from '../../query-client';
import { IPagination } from '../IPagination';
import { ICreateProgramType, IFetchProgramTypesQuery, IProgramType } from './interface';
import { createProgramType, fetchProgramTypes } from './index';

export const useProgramTypeListQuery = (query: IFetchProgramTypesQuery) => {
    return useQuery<IPagination<IProgramType>, Error>([QueryKey.PROGRAM_TYPES, query], ({ signal }) => fetchProgramTypes(query, signal));
};

export const useProgramTypeCreate = () => {
    const { notification } = App.useApp();
    const queryClient = useQueryClient();

    return useMutation<IProgramType, Error, ICreateProgramType>(createProgramType, {
        onSuccess: async () => {
            await queryClient.invalidateQueries([QueryKey.PROGRAM_TYPES]);
            notification.success({ key: 'program-type-create-success', message: 'Type created successfully' });
        },
        onError: (error: any) => {
            notification.error({ key: 'program-type-create-error', message: error.message || 'Cannot create type' });
        },
    });
};
