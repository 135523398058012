import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import App from 'antd/lib/app';
import InboxOutlined from '@ant-design/icons/InboxOutlined';
import Dragger from 'antd/lib/upload/Dragger';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import Modal from 'antd/lib/modal';
import Form from 'antd/lib/form';
import Tooltip from 'antd/lib/tooltip';
import Upload from 'antd/lib/upload';

import { ProgramToProductRelationImportFormatDescription } from './ProgramToProductRelationImportFormatDescription';
import { parserProgramToProductRelationsCsvData } from './parseProgramToProductRelationsCsvData';
import { CsvParsingErrors } from '../CsvParsingErrors/CsvParsingErrors';
import { batchUpdateProgramToProductRelation } from '../../domain/program';
import { IUpdateProgramToProductRelation } from '../../domain/program/interface';

const formItemLayout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 12 },
};

interface IUploadProgramToProductRelationModalProps {
    onClose: (isRefreshNeeded?: boolean) => void;
}

const ALLOWED_FILE_TYPE = '.csv';

const prepareErrors = (errors: any) => {
    const parsedError = JSON.parse(errors.message || '');
    return parsedError.map((error: any) => `In row ${error.rowIndex + 1}: ${error.error}`);
};

export const UploadProgramToProductRelationModal = ({ onClose }: IUploadProgramToProductRelationModalProps) => {
    const { notification } = App.useApp();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const [file, setFile] = useState<File | null>(null);
    const [parsedValue, setParsedValue] = useState<IUpdateProgramToProductRelation[]>([]);
    const [errors, setErrors] = useState<string[]>([]);

    const handleCancel = () => onClose();

    async function onFormFinish() {
        if (!file) {
            console.warn('File is absent!', file);
            return;
        }

        if (parsedValue.length === 0) return;
        setLoading(true);

        try {
            await batchUpdateProgramToProductRelation(parsedValue);

            onClose(true);
            setLoading(false);
            notification.success({
                key: 'upload-program-to-product-relation-success',
                message: 'Links between Product and Program was updated!',
            });
        } catch (error: any) {
            setLoading(false);
            const preparedErrors = prepareErrors(error);
            preparedErrors && setErrors(preparedErrors);
        }
    }

    const parseCsv = (content: string) => {
        const [errors, result] = parserProgramToProductRelationsCsvData(content);
        errors.length ? setErrors(errors) : setParsedValue(result);
    };

    const uploadProps = {
        accept: ALLOWED_FILE_TYPE,
        multiple: false,
        maxCount: 1,
        onRemove: (file: any) => {
            setFile(null);
            setErrors([]);
        },
        onChange: (info: any) => {
            if (info.file.status) return;

            setFile(info.file);
        },
        beforeUpload: (file: File) => {
            const ALLOWED_FILE_SIZE_MB = 5;
            const fileSizeInMB = file.size! / 1024 / 1024;
            if (fileSizeInMB > ALLOWED_FILE_SIZE_MB) {
                notification.warning({
                    key: 'upload-file-size-notification',
                    message: `Please select file up to ${ALLOWED_FILE_SIZE_MB}MB.`,
                });
                return Upload.LIST_IGNORE;
            }

            if (!file.name.endsWith(ALLOWED_FILE_TYPE)) {
                notification.warning({
                    key: 'upload-file-type-notification',
                    message: `Please select "${ALLOWED_FILE_TYPE}" file.`,
                });
                return Upload.LIST_IGNORE;
            }

            setErrors([]);

            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = (e: any) => {
                const content = e.target?.result;
                parseCsv(content);
            };

            return false;
        },
    };

    return (
        <Modal
            title="Update Links between Salesforce Products and DRMS Programs"
            width="50%"
            open
            destroyOnClose
            onCancel={handleCancel}
            footer={[
                <Tooltip
                    title="Click to download a CSV template that you could fill in with your data to import"
                    key="upload-program-to-product-relation-modal-template-tooltip"
                >
                    <Button key="upload-program-to-product-relation-modal-template" type="text" onClick={downloadTemplate}>
                        Download Template
                    </Button>
                </Tooltip>,
                <Button key="upload-program-to-product-relation-modal-cancel" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button
                    key="upload-program-to-product-relation-modal-submit"
                    type="primary"
                    disabled={!file || !!errors.length}
                    loading={loading}
                    onClick={form.submit}
                >
                    Upload
                </Button>,
            ]}
        >
            <Form form={form} name="upload-program-to-product-relation-form" preserve={false} {...formItemLayout} onFinish={onFormFinish}>
                <Form.Item name="upload-program-to-product-relation-file-id" hidden>
                    <Input />
                </Form.Item>
                <Dragger {...uploadProps}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag a CSV file to this area to upload</p>
                    <p className="ant-upload-hint">The size limit is 5MB.</p>
                    <p className="ant-upload-hint"></p>
                </Dragger>
                <CsvParsingErrors errors={errors} />
                <p></p>
                <ProgramToProductRelationImportFormatDescription key="import-program-to-product-relation-format" />
            </Form>
        </Modal>
    );
};

const downloadTemplate = () => {
    const csvColumns: string[] = ['Salesforce Product ID', 'Program ID', 'Program Name', 'Salesforce Product Name'];

    const csvData = Papa.unparse([csvColumns], {
        quotes: true,
        quoteChar: '"',
        escapeChar: '"',
        delimiter: ',',
        header: true,
        escapeFormulae: true,
    });
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, 'import-program-to-product-relation-template.csv');
};
