import App from 'antd/lib/app';
import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal';
import Spin from 'antd/lib/spin';
import Tooltip from 'antd/lib/tooltip';
import Transfer from 'antd/lib/transfer';
import { InfoCircleOutlined } from '@ant-design/icons';
import { isNull } from 'lodash';
import orderBy from 'lodash/orderBy';
import React, { useEffect, useMemo, useState } from 'react';
import { IAccount } from '../../domain/account/interface';
import { useAccountListQuery } from '../../domain/account/queries';
import { ICompany } from '../../domain/company/interface';
import { PRIMARY_COLOR } from '../../theme';

interface IManageAccountModalProps {
    onClose: () => void;
    onOk: (selectedAccounts: IAccount[]) => void;
    accounts: IAccount[];
    company: ICompany | null;
}

export const ManageAccountModal = ({ onClose, onOk, accounts: initialAccounts, company }: IManageAccountModalProps) => {
    const { notification } = App.useApp();
    const [targetKeys, setTargetKeys] = useState<React.Key[]>([]);
    const [transferOptions, setTransferOptions] = useState<IRecordType[]>([]);

    const { data, error, isError, isLoading } = useAccountListQuery({
        pagination: { pageSize: 10000, current: 1 },
        include: 'company',
    });
    const accounts = useMemo(() => data?.data || [], [data]);

    if (isError) {
        notification.error({ key: 'manage-account-modal-error', message: error?.message || 'Cannot load accounts!' });
    }

    const handleOk = () => {
        const selectedAccounts = accounts.filter(account => targetKeys.includes(account.salesforce_id));

        onOk(selectedAccounts);
    };

    const handleCancel = () => {
        onClose();
    };

    const handleChange = (newTargetKeys: React.Key[]) => {
        setTargetKeys(newTargetKeys);
    };

    const filterOption = (inputValue: string, option: IRecordType) => {
        const searchValue = inputValue.toLocaleLowerCase();

        return option.title.toLocaleLowerCase().indexOf(searchValue) > -1 || option.key.toLocaleLowerCase().indexOf(searchValue) > -1;
    };

    const renderItem = (item: IRecordType) => {
        return item?.companyName ? `${item.title} (${item.companyName})` : item.title;
    };

    useEffect(() => {
        const sortedAccounts = orderBy(
            accounts,
            [
                (account: IAccount) => !!account.company_id, // sort by attached company
                (account: IAccount) => account.name, // then by account name
            ],
            ['asc', 'asc']
        );
        const sortedInitialAccounts = orderBy(initialAccounts, ['name'], ['asc']);

        setTransferOptions(sortedAccounts.map(account => toTransferOptions(account, company)));
        setTargetKeys(sortedInitialAccounts.map(account => toTransferOptions(account, company)).map(option => option.key));
    }, [accounts, initialAccounts, company]);

    return (
        <Modal
            open
            title={
                <>
                    Manage Salesforce Accounts
                    <Tooltip title="Only showing Salesforce Accounts which have opportunities that are not Closed Lost">
                        <InfoCircleOutlined style={{ color: PRIMARY_COLOR, fontSize: '16px', marginLeft: '8px' }} />
                    </Tooltip>
                </>
            }
            destroyOnClose
            onCancel={handleCancel}
            width={800}
            styles={{
                mask: { background: 'rgba(0, 0, 0, 0.1)' }, // to not make background very dark
                footer: { padding: '8px 24px' },
            }}
            footer={[
                <Button key="company-account-modal-cancel" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key="company-account-modal-submit" type="primary" onClick={handleOk}>
                    Continue
                </Button>,
            ]}
        >
            <Spin tip="Loading..." spinning={isLoading}>
                <Transfer
                    showSearch
                    style={{ width: '100%' }}
                    listStyle={{ width: '48%', height: 300 }}
                    dataSource={transferOptions}
                    targetKeys={targetKeys}
                    titles={['All Salesforce Accounts', 'Linked Accounts']}
                    render={renderItem}
                    filterOption={filterOption}
                    onChange={handleChange}
                    pagination
                />
            </Spin>
        </Modal>
    );
};

interface IRecordType {
    key: string;
    title: string;
    companyName?: string;
    disabled: boolean;
}

function toTransferOptions(account: IAccount, company: ICompany | null): IRecordType {
    return {
        key: account.salesforce_id,
        title: account.name,
        companyName: account?.company?.company_name,
        disabled: isAccountSelectDisabled(account, company),
    };
}

function isAccountSelectDisabled(account: IAccount, company: ICompany | null): boolean {
    return !isNull(account.company_id) && account.company_id !== company?.company_id;
}
