import { ISite, IntervalDataStatus, ISan } from './interface';
import { orderBy } from 'lodash';

/**
 * Because we support multiple active SANs for one site - we want to display the one that needs attention.
 * There are next priority:
 * 1. SAN with older (more than 10 days ago) latest interval data received date;
 * 2. SAN with any other issues (invalid agreement, no interval data at all, etc.);
 * 3. SAN with older (less than 10 days ago) latest interval data received date;
 * @param site
 */
export function getActiveSiteSan(site: ISite): ISan | undefined {
    const activeSans = site.sans.filter(san => san.is_active);

    if (!activeSans.length) return;

    const activeSansWithConnectionIssue = activeSans.filter(san => {
        const status = san.san_info?.intervalDataState?.status;
        return status && [IntervalDataStatus.CONNECTED_BUT_HAS_PROBLEM, IntervalDataStatus.INVALID_AGREEMENT].includes(status);
    });

    if (activeSansWithConnectionIssue.length) {
        return orderBy(activeSansWithConnectionIssue, 'san_info.latestIntervalDataDate', ['asc'])[0];
    }

    const activeConnectedSans = activeSans.filter(san => san.san_info?.intervalDataState?.status === IntervalDataStatus.CONNECTED);
    if (activeConnectedSans.length) return orderBy(activeConnectedSans, 'san_info.latestIntervalDataDate', ['asc'])[0];
}
