import Button from 'antd/lib/button';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import List from 'antd/lib/list';
import Modal from 'antd/lib/modal/Modal';
import Space from 'antd/lib/space';
import Switch from 'antd/lib/switch';
import Typography from 'antd/lib/typography';
import trim from 'lodash/trim';
import { useMemo, useState } from 'react';
import { formatSan } from '../../domain/site/formatSan';
import { ISanFormItem } from '../../domain/site/interface';
import { validateIfSanAvailable, validateSanFormat } from './SiteFormModal';
import { SuggestedFormatIcon } from './SuggestedSanFormatIcon';

interface IManageSansModalProps {
    siteId: number | null;
    market: string | null;
    utility: string | null;
    sans: ISanFormItem[];
    onOk: (updatedSans: ISanFormItem[]) => void;
    onCancel: () => void;
}

export const ManageSanModal = ({ siteId, market, utility, sans: initialSans, onCancel, onOk }: IManageSansModalProps) => {
    const [form] = Form.useForm<IManageSanForm>();

    const [sans, setSans] = useState<ISanFormItem[]>(initialSans);
    const enteredSan = Form.useWatch('entered_san', form) || '';

    const isSanInList = useMemo(() => sans.some(san => san.service_account_number === formatSan(enteredSan)), [enteredSan, sans]);

    const handleCancel = () => {
        onCancel();
    };

    const toggleActiveSan = (selectedSan: ISanFormItem) => {
        const updatedSans = sans.map(san => {
            if (san.service_account_number === selectedSan.service_account_number) {
                san.is_active = !san.is_active;
            }

            return san;
        });

        setSans(updatedSans);
    };

    const deleteSan = (selectedSan: ISanFormItem) => () => {
        const updatedSans = sans.filter(s => s.service_account_number !== selectedSan.service_account_number);

        setSans(updatedSans);

        // re-validate entered_san field to check if it still in the list
        if (enteredSan?.length !== 0) {
            form.validateFields(['entered_san']);
        }
    };

    const handleOk = () => {
        onOk(sans);
    };

    const addNewSan = (values: IManageSanForm) => {
        const newSan = values.entered_san;

        if (!trim(newSan)) return;

        const existingSan = sans.find(san => san.service_account_number === formatSan(newSan));
        if (existingSan) return;

        const updatedSans = sans.concat({
            service_account_number: formatSan(newSan),
            is_active: true,
        });

        setSans(updatedSans);
        form.resetFields();
    };

    return (
        <Modal
            open
            onCancel={handleCancel}
            styles={{
                mask: { background: 'rgba(0, 0, 0, 0.1)' }, // to not make background very dark
                footer: { padding: '8px 24px' },
            }}
            title="Manage Enrollment ID's"
            footer={[
                <Button key="feedback-modal-cancel" type="default" htmlType="button" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key="feedback-modal-submit" type="primary" htmlType="submit" onClick={handleOk}>
                    Ok
                </Button>,
            ]}
        >
            <Form name="migrate-site-san-form" preserve layout="vertical" form={form} onFinish={addNewSan}>
                <Form.Item
                    name="entered_san"
                    label={<Typography.Text strong>Add Enrollment ID</Typography.Text>}
                    rules={[
                        {
                            required: true,
                            validateTrigger: ['onSubmit'], // to not make too many requests as "onChange"
                            validator: (_, value) => validateIfSanAvailable(_, value, siteId),
                            message: 'This Enrollment ID is already in use at another site',
                        },
                        {
                            warningOnly: true,
                            validateTrigger: ['onChange'],
                            validator: (_, value) => validateSanFormat(_, value, utility, market),
                        },
                        {
                            required: true,
                            validateTrigger: ['onChange'],
                            validator: (_, san) => validateIfSanInList(san, sans),
                            message: 'This Enrollment ID is already in use at a site',
                        },
                        {
                            required: true,
                            validateTrigger: ['onChange'],
                            validator: (_, san) => (sans.length < 100 ? Promise.resolve() : Promise.reject()),
                            message: 'Cannot attach more than 100 Enrollment IDs',
                        },
                        { max: 100, message: 'Number of characters should be less than 100' },
                    ]}
                    normalize={trim}
                >
                    <Space.Compact style={{ width: '100%' }}>
                        <Input placeholder="Add Enrollment ID" type="text" />
                        <Button htmlType="submit" type="primary" disabled={isSanInList}>
                            Add
                        </Button>
                    </Space.Compact>
                </Form.Item>
            </Form>

            <List
                size="large"
                dataSource={sans}
                header={<Typography.Text strong>Site Enrollment ID's</Typography.Text>}
                renderItem={san => (
                    <List.Item
                        extra={
                            <Space direction="horizontal" align="center">
                                <SuggestedFormatIcon san={san.service_account_number} market={market} utility={utility} />

                                <Switch
                                    checkedChildren="Active"
                                    unCheckedChildren="Inactive"
                                    checked={san.is_active}
                                    onChange={() => toggleActiveSan(san)}
                                />

                                <Button danger size="small" onClick={deleteSan(san)}>
                                    Delete
                                </Button>
                            </Space>
                        }
                        style={{ padding: '16px', paddingRight: 0 }}
                    >
                        <Typography.Text ellipsis={{ tooltip: san.service_account_number }}>{san.service_account_number}</Typography.Text>
                    </List.Item>
                )}
            />
        </Modal>
    );
};

interface IManageSanForm {
    entered_san: string;
}

const validateIfSanInList = (san: string, sans: ISanFormItem[]) => {
    if (!san) return Promise.resolve();

    const formattedSan = formatSan(san);
    const existingSan = sans.find(san => san.service_account_number === formattedSan);

    if (existingSan) return Promise.reject();

    return Promise.resolve();
};
