import { PeakLoadForecastType, IPeakEventIndex } from 'src/domain/peak-load-forecast/interface';
import { DateRange } from './DateRange';
import { PeakLoadForecastMarket } from './PeakLoadForecastMarket';
import { formatInTimeZone, fromZonedTime, toZonedTime } from 'date-fns-tz';
import { add } from 'date-fns';
import { maxBy } from 'lodash';
import { isSameDayInTimezone, makeDateKey } from 'src/domain/date/date';

export class PeakEventIndexDay {
    constructor(
        public market: PeakLoadForecastMarket,
        public datetime: Date,
        public peakEventIndex: number | null,
        public peakForecastEventIndex: number | null,
        public forecastType: PeakLoadForecastType,
        public selected: boolean
    ) {}

    getPeakEventIndex() {
        const eventIndex = this.peakEventIndex !== null ? this.peakEventIndex : this.peakForecastEventIndex;
        return eventIndex;
    }

    eventChance() {
        return getEventChance({
            eventIndex: this.peakEventIndex,
            forecastEventIndex: this.peakForecastEventIndex,
        });
    }
}

export function preparePeakEventIndexDays(dateRange: DateRange, records: IPeakEventIndex[] | undefined, market: PeakLoadForecastMarket) {
    // console.info(
    //     `[preparePeakEventIndexData] start=${dateRange.start.toISOString()}, end=${dateRange.end.toISOString()}, records=${records?.length}`
    // );

    if (!records?.length) records = [];

    const map = initMapForDateRange(dateRange, market.timezone);

    for (let item of records) {
        const dateTimeInTimezone = toZonedTime(item.datetime, market.timezone);
        const key = makeDateKey(dateTimeInTimezone);

        if (!map[key]) {
            continue;
        }

        map[key].data.push(item);
    }

    const selectedDatetime = dateRange.selected;

    const peakEventIndexDays: PeakEventIndexDay[] =
        Object.entries(map).map(([key, obj]) => {
            /**
             * when no data received for this date - create N/A day
             */
            if (!obj.data.length) {
                const datetime = obj.datetime;

                const datetimeKey = makeDateKey(datetime);
                const selectedDatetimeKey = makeDateKey(selectedDatetime);

                const sameDay = datetimeKey === selectedDatetimeKey;

                const tDateInTz = fromZonedTime(`${key}T00:00:00Z`, market.timezone);

                // console.info(
                //     `[PEI NOT exist] datetime=${datetime.toISOString()}, isSameDay=${sameDay} (key=${tDateInTz.toISOString()})`
                // );

                const day = new PeakEventIndexDay(market, tDateInTz, null, null, PeakLoadForecastType.AVERAGE, sameDay);

                return day;
            }

            const peakHourItem = maxBy(obj.data, 'loadMw')!;
            const datetime = new Date(peakHourItem.datetime);

            const sameDay = isSameDayInTimezone(datetime, selectedDatetime, market.timezone);

            // console.info(
            //     `[PEI exist] selected=${selectedDatetime.toISOString()} datetime=${datetime.toISOString()}, isSameDay=${sameDay}`
            // );
            const day = new PeakEventIndexDay(
                market,
                datetime,
                peakHourItem.eventIndex,
                peakHourItem.forecastEventIndex,
                peakHourItem.forecastType,
                sameDay
            );

            return day;
        }) || [];

    /**
     * if no day selected - select the first one
     */
    if (peakEventIndexDays.length && peakEventIndexDays.every(day => day.selected === false)) {
        peakEventIndexDays[0].selected = true;
    }

    // logPeakEventIndexDays(peakEventIndexDays, market);

    return peakEventIndexDays;
}

function initMapForDateRange(dateRange: DateRange, timezone: string) {
    const map: Record<string, { datetime: Date; data: IPeakEventIndex[] }> = {};

    let cursor = dateRange.start;
    while (cursor.valueOf() <= dateRange.end.valueOf()) {
        const estDateTime = toZonedTime(cursor, timezone);
        const key = makeDateKey(estDateTime);

        map[key] = { datetime: estDateTime, data: [] };

        cursor = add(cursor, { days: 1 });
    }

    return map;
}

function logPeakEventIndexDays(peakEventIndexDays: PeakEventIndexDay[], market: PeakLoadForecastMarket) {
    peakEventIndexDays.forEach(day => {
        const dateTz = formatInTimeZone(day.datetime.toISOString(), market.timezone, 'yyyy-MM-dd HH:mm:ss');
        console.info(
            `[DAY] UTC=${day.datetime.toISOString()} TZ=${dateTz} peakEI=${day.peakEventIndex}, peakFEI=${day.peakForecastEventIndex}, [${day.eventChance()}], selected=${day.selected}`
        );
    });
}

export type EventChance = 'low' | 'medium' | 'high';

/**
 * Based on eventIndex or forecastEventIndex
 * we predict a chance of event (how likely we suggest to dispatch event)
 */
export function getEventChance({ eventIndex, forecastEventIndex }: { eventIndex: number | null; forecastEventIndex: number | null }) {
    const index = eventIndex !== null ? eventIndex : forecastEventIndex;

    let chance: EventChance = 'low';

    const isHigh = index !== null && index > 94;
    const isMedium = index !== null && index >= 90 && index <= 94;

    if (isHigh) chance = 'high';
    if (isMedium) chance = 'medium';

    return chance;
}
