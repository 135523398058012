import { readAsBlob } from '../../common/readAsBlob';
import { API } from '../../api';
import { ICompanyBranding, IFetchCompanyBrandingQuery, IUpdateCompanyBranding } from './interface';

export const fetchCompanyBranding = async (
    { companyId }: IFetchCompanyBrandingQuery,
    signal?: AbortSignal | null
): Promise<ICompanyBranding | null> => {
    const response = await API.fetch(`/companies/${companyId}/branding`, {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (response.status === 204) {
        return null;
    }

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot load company branding.`);
};

export const updateCompanyBranding = async ({ companyId, companyBranding }: { companyId: number; companyBranding: IUpdateCompanyBranding }) => {
    const formData = await companyBrandingToFormData(companyBranding);

    const response = await API.fetch(`/companies/${companyId}/branding`, {
        method: 'put',
        body: formData,
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return;
    }

    throw new Error(respBody?.error?.message || `Cannot update company branding!`);
};

const companyBrandingToFormData = async (companyBranding: IUpdateCompanyBranding) => {
    const formData = new FormData();

    for await (let [key, value] of Object.entries(companyBranding)) {
        if (key === 'logoFile' && value?.status !== 'done') {
            const blob = await readAsBlob(companyBranding.logoFile as any);
            formData.append('logoFile', blob, companyBranding.logoFile!.name);
            continue;
        }

        formData.append(key, value);
    }

    return formData;
};
