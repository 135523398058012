import EditOutlined from '@ant-design/icons/EditOutlined';
import App from 'antd/lib/app';
import Button from 'antd/lib/button';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Modal from 'antd/lib/modal';
import Select from 'antd/lib/select';
import Typography from 'antd/lib/typography';
import orderBy from 'lodash/orderBy';
import { useEffect, useState } from 'react';
import { useAuth } from '../../domain/auth/useAuth';
import { IProduct } from '../../domain/product/interface';
import { IProgram, IUpdateProgram } from '../../domain/program/interface';
import { ProgramUtilityAutomationOptions } from '../../domain/program/programUtilityAutomationOptions';
import { useProgramCreateMutation, useProgramUpdateMutation } from '../../domain/program/queries';
import { AccessPartnerSelector } from '../selectors/AccessPartnerSelector/AccessPartnerSelector';
import { ProgramTypeSelector } from '../selectors/ProgramTypeSelector/ProgramTypeSelector';
import { ProgramUtilityOrIsoSelector } from '../selectors/ProgramUtilityOrIsoSelector/ProgramUtilityOrIsoSelector';
import { CreateAccessPartnerAction } from './CreateAccessPartnerAction';
import { CreateProgramTypeAction } from './CreateProgramTypeAction';
import { ManageProductModal } from './ManageProductModal';
import { ProductList } from './ProductList';
import { IProgramFormData, ProgramFormData } from './ProgramFormData';
import { COMPANY_TYPE, ICompany } from 'src/domain/company/interface';
import { CompanySelector } from '../selectors/CompanySelector/CompanySelector';
import { useCompanies } from '../selectors/GlobalCompanySelector/CompanyContext';
import './ProgramFormModal.css';

interface IProgramFormModalProps {
    program: IProgram | Partial<IProgram>;
    onClose: Function;
}

export function ProgramFormModal({ program, onClose }: IProgramFormModalProps) {
    const auth = useAuth()!;
    const { notification } = App.useApp();
    const { companies } = useCompanies()!;

    const [form] = Form.useForm<IProgramFormData>();
    const [selectedUtilityCustomer, setSelectedUtilityCustomer] = useState<ICompany | undefined>(program.utility_customer);

    const isEditing = !!program?.program_id;

    const programCreateMutation = useProgramCreateMutation();
    const programUpdateMutation = useProgramUpdateMutation();

    const isLoading = programCreateMutation.isLoading || programUpdateMutation.isLoading;
    const [isManageProductsModalOpen, setIsManageProductsModalOpen] = useState<boolean>(false);

    const [selectedProducts, setSelectedProducts] = useState<IProduct[]>(program.products || []);

    const closeManageProductsModal = () => {
        setIsManageProductsModalOpen(false);
    };

    const openManageProductsModal = () => {
        setIsManageProductsModalOpen(true);
    };

    const handleManageProductsFinish = (updatedProducts: IProduct[]) => {
        const updatedProductsIds = updatedProducts.map(product => product.salesforce_id);

        setSelectedProducts(updatedProducts);
        form.setFieldValue('product_ids', updatedProductsIds);
        setIsManageProductsModalOpen(false);
    };

    const handleUnlinkProduct = (salesforceId: string) => {
        const updatedProducts = selectedProducts.filter(product => product.salesforce_id !== salesforceId);
        const updatedProductsIds = updatedProducts.map(product => product.salesforce_id);

        setSelectedProducts(updatedProducts);
        form.setFieldValue('product_ids', updatedProductsIds);
    };

    async function onProgramModalFormFinish(programFormData: IProgramFormData) {
        const program = ProgramFormData.toEntity(programFormData);

        try {
            if ('program_id' in program && !!program.program_id) {
                const dto: IUpdateProgram = {
                    program_id: program.program_id,
                    name: program.name,
                    description: program.description,
                    iso: program.iso,
                    utility_id: program.utility_id,
                    access_partner_id: program.access_partner_id,
                    type_id: program.type_id,
                    utility_automation: program.utility_automation,
                    product_ids: program.product_ids,
                };
                await programUpdateMutation.mutateAsync(dto);
            } else {
                await programCreateMutation.mutateAsync(program);
            }

            onClose(program);
        } catch (err: any) {
            notification.error({ key: 'program-save-info', message: err?.message || 'Cannot save program!' });
        }
    }

    const closeProgramDialog = () => {
        onClose();
    };

    function onUtilityCustomerChange(id: number | undefined) {
        const selectedCompany = companies.find(company => company.company_id === id);

        form.setFieldValue('utility_customer_id', id || null);
        setSelectedUtilityCustomer(selectedCompany);

        if (selectedCompany) {
            const utilityIdOrIso = selectedCompany.iso || selectedCompany.utility_id;
            form.setFieldValue('utility_iso', utilityIdOrIso);
            form.setFieldValue('access_partner_id', 'd'); // if a utility customer is selected set Program Operator to Direct
        }
    }

    useEffect(() => {
        setSelectedProducts(orderBy(program.products, 'name') || []);
    }, [program.products]);

    return (
        <Modal
            open
            className="program-form-modal"
            title={`${isEditing ? 'Edit' : 'New'} Energy Market Program`}
            destroyOnClose
            onCancel={closeProgramDialog}
            footer={[
                <Button key="program-modal-cancel" onClick={closeProgramDialog}>
                    Cancel
                </Button>,
                <Button key="program-modal-submit" type="primary" loading={isLoading} onClick={form.submit}>
                    Save
                </Button>,
            ]}
        >
            <Form
                form={form}
                name="program-form"
                preserve={false}
                layout="vertical"
                onFinish={onProgramModalFormFinish}
                initialValues={ProgramFormData.fromEntity(program)}
            >
                <Form.Item name="program_id" hidden>
                    <Input />
                </Form.Item>

                <Form.Item
                    name="name"
                    label={<Typography.Text strong>Program Name</Typography.Text>}
                    hasFeedback
                    rules={[
                        { required: true, message: 'Please enter program name!' },
                        { max: 128, message: 'Number of characters should be less than 128' },
                    ]}
                >
                    <Input placeholder="Program Name" size="large" />
                </Form.Item>

                <Form.Item name="utility_customer_id" label={<Typography.Text strong>Utility Customer</Typography.Text>}>
                    <CompanySelector
                        onlyTypes={[COMPANY_TYPE.UTILITY_CUSTOMER]}
                        onChange={onUtilityCustomerChange}
                        allowClear
                        placeholder="Please select Utility Customer"
                        disabled={isEditing}
                    />
                </Form.Item>
                <Form.Item
                    required
                    hasFeedback
                    name="utility_iso"
                    tooltip="If a Utility Customer is selected, value for this field is inherited from the company. If you need to change this, clear the selected Utility Customer."
                    label={<Typography.Text strong>Utility/ISO </Typography.Text>}
                    rules={[{ required: true, message: 'Please select utility/iso!' }]}
                >
                    <ProgramUtilityOrIsoSelector disabled={!!selectedUtilityCustomer} />
                </Form.Item>

                <Form.Item
                    name="access_partner_id"
                    className="form-item-with-action"
                    label={
                        <>
                            <Typography.Text strong>Program Operator</Typography.Text>
                            {auth.user?.isAdmin() && <CreateAccessPartnerAction />}
                        </>
                    }
                >
                    <AccessPartnerSelector allowClear />
                </Form.Item>

                <Form.Item
                    name="type_id"
                    className="form-item-with-action"
                    label={
                        <>
                            <Typography.Text strong>Type</Typography.Text>
                            {auth.user?.isAdmin() && <CreateProgramTypeAction />}
                        </>
                    }
                >
                    <ProgramTypeSelector allowClear />
                </Form.Item>

                <Form.Item
                    name="product_ids"
                    label={
                        <>
                            <Typography.Text strong>Linked Salesforce Products</Typography.Text>
                            <Button size="small" type="link" onClick={openManageProductsModal}>
                                Manage <EditOutlined />
                            </Button>
                        </>
                    }
                >
                    <ProductList products={selectedProducts} onUnlinkProduct={handleUnlinkProduct} />
                </Form.Item>

                <Form.Item
                    name="description"
                    rules={[{ max: 512, message: 'Number of characters should be less than 512å' }]}
                    label={<Typography.Text strong>Additional Information</Typography.Text>}
                >
                    <Input placeholder="Additional Information" size="large" />
                </Form.Item>

                {auth.user?.isAdminRoleType() && (
                    <Form.Item name="utility_automation" label={<Typography.Text strong>Utility Automation</Typography.Text>}>
                        <Select allowClear placeholder="Select Utility Automation" size="large" options={ProgramUtilityAutomationOptions} />
                    </Form.Item>
                )}
            </Form>

            {isManageProductsModalOpen && (
                <ManageProductModal
                    program={program.program_id ? (program as IProgram) : null}
                    products={selectedProducts}
                    onClose={closeManageProductsModal}
                    onOk={handleManageProductsFinish}
                />
            )}
        </Modal>
    );
}
