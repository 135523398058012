import { useQuery } from '@tanstack/react-query';

import { fetchCaisoRegistrationFiltersList, fetchCaisoRegistrations, fetchCaisoRegistrationsLocations } from '.';
import { QueryKey } from 'src/query-client';
import { IFetchPageQuery } from 'src/domain/IFetchQueryOptions';

export const useCaisoRegistrationsListQuery = (query: IFetchPageQuery, options = {}) => {
    return useQuery({
        queryKey: [QueryKey.DRRS_REGISTRATIONS, query],
        queryFn: () => fetchCaisoRegistrations(query),
        ...options,
    });
};

export const useCaisoRegistrationLocationsListQuery = (registration_id: number, query: IFetchPageQuery, options = {}) => {
    return useQuery({
        queryKey: [QueryKey.DRRS_REGISTRATION_LOCATIONS, query],
        queryFn: () => fetchCaisoRegistrationsLocations(registration_id, query),
        ...options,
    });
};

export const useCaisoRegistrationStatusListQuery = () => {
    return useQuery({
        queryKey: [QueryKey.DRRS_REGISTRATION_STATUSES],
        queryFn: () => fetchCaisoRegistrationFiltersList('status'),
    });
};

export const useCaisoRegistrationUdcListQuery = () => {
    return useQuery({
        queryKey: [QueryKey.DRRS_REGISTRATION_UDCS],
        queryFn: () => fetchCaisoRegistrationFiltersList('udc'),
    });
};

export const useCaisoRegistrationSubLapListQuery = () => {
    return useQuery({
        queryKey: [QueryKey.DRRS_REGISTRATION_SUBLAPS],
        queryFn: () => fetchCaisoRegistrationFiltersList('sub_lap'),
    });
};

export const useCaisoRegistrationResourceIdsListQuery = () => {
    return useQuery({
        queryKey: [QueryKey.DRRS_REGISTRATION_RESOURCE_IDS],
        queryFn: () => fetchCaisoRegistrationFiltersList('resource_id'),
    });
};
