import Modal from 'antd/lib/modal/Modal';
import Button from 'antd/lib/button';
import Form from 'antd/lib/form';
import Select from 'antd/lib/select';
import Typography from 'antd/lib/typography';
import React from 'react';
import Radio from 'antd/lib/radio';
import Space from 'antd/lib/space';
import { useLocalStorage } from '../../domain/useLocalStorage';
import { useAuth } from '../../domain/auth/useAuth';

export interface IDownloadSiteCsvFormData {
    fileType: SiteCsvDownloadFileType;
    splitByEnrollments?: boolean;
    applyFilters?: boolean;
    columnsPreset?: 'default' | 'detailed' | 'limited';
}

interface IDownloadSiteCsvModal {
    onClose: Function;
    onSubmit: (data: IDownloadSiteCsvFormData) => void;
}

export type SiteCsvDownloadFileType = 'site' | 'meter' | 'meter-enrollment';

const STORAGE_KEY = 'site-csv-download-options';

const DEFAULT_FORM_VALUE: IDownloadSiteCsvFormData = {
    fileType: 'site',
    splitByEnrollments: true,
    applyFilters: false,
    columnsPreset: 'default',
};

export const DownloadSiteCsvModal = ({ onClose, onSubmit }: IDownloadSiteCsvModal) => {
    const [form] = Form.useForm<IDownloadSiteCsvFormData>();
    const auth = useAuth()!;
    const fileType = Form.useWatch('fileType', form);
    const [options, setOptions] = useLocalStorage<IDownloadSiteCsvFormData>(`${STORAGE_KEY}-${auth.user?.user_id}`, DEFAULT_FORM_VALUE);

    const handleClose = () => onClose();

    const handleConfirm = (formData: IDownloadSiteCsvFormData) => {
        setOptions({ ...options, ...formData });
        onSubmit(formData);
    };

    return (
        <Modal
            open
            title="Download CSV"
            destroyOnClose
            closeIcon
            onCancel={handleClose}
            styles={{
                footer: {
                    borderTop: 'none',
                    padding: '0 24px 24px 24px',
                },
            }}
            footer={[
                <Button key="modal-cancel" onClick={handleClose}>
                    Cancel
                </Button>,
                <Button key="download-csv-confirm" type="primary" onClick={form.submit}>
                    Download
                </Button>,
            ]}
        >
            <Form
                form={form}
                name="download-site-csv-form"
                preserve={false}
                layout="vertical"
                onFinish={handleConfirm}
                style={{ marginTop: '12px' }}
                initialValues={options}
            >
                <Form.Item name="fileType" label={<Typography.Text strong>File Type</Typography.Text>}>
                    <Select
                        size="large"
                        options={[
                            {
                                value: 'site',
                                label: 'Sites CSV',
                            },
                            ...(auth.user?.isAdminRoleType()
                                ? [
                                      {
                                          value: 'meter',
                                          label: 'Meters CSV',
                                      },
                                      {
                                          value: 'meter-enrollment',
                                          label: 'Meter Enrollments CSV',
                                      },
                                  ]
                                : []),
                        ]}
                    />
                </Form.Item>

                {fileType === 'site' && (
                    <>
                        <Space direction="vertical" size={0} style={{ marginBottom: '24px' }}>
                            <Typography.Text strong style={{ fontSize: '16px' }}>
                                CSV Download Options
                            </Typography.Text>
                            <Typography.Text>Options will be set as default</Typography.Text>
                        </Space>

                        <Form.Item name="splitByEnrollments">
                            <Radio.Group>
                                <Space direction="vertical" size={0}>
                                    <Typography.Text strong>Enrollment IDs</Typography.Text>
                                    <Radio value={false} type="default">
                                        <Typography.Text strong>One row per DRMS site</Typography.Text>
                                    </Radio>
                                    <Typography.Text type="secondary" style={{ marginLeft: '24px' }}>
                                        Enrollment IDs combined in one cell
                                    </Typography.Text>

                                    <Radio value={true}>
                                        <Typography.Text strong>One row per Enrollment ID</Typography.Text>
                                    </Radio>
                                    <Typography.Text type="secondary" style={{ marginLeft: '24px' }}>
                                        Multiple rows per DRMS site
                                    </Typography.Text>
                                </Space>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item name="applyFilters">
                            <Radio.Group>
                                <Space direction="vertical" size={0}>
                                    <Typography.Text strong>Filters</Typography.Text>
                                    <Radio value={true}>
                                        <Typography.Text strong>Apply Filters</Typography.Text>
                                    </Radio>
                                    <Typography.Text type="secondary" style={{ marginLeft: '24px' }}>
                                        Download will only include sites currently displayed
                                    </Typography.Text>

                                    <Radio value={false}>
                                        <Typography.Text strong>Download all Sites</Typography.Text>
                                    </Radio>
                                    <Typography.Text type="secondary" style={{ marginLeft: '24px' }}>
                                        Current filters will not be applied to CSV
                                    </Typography.Text>
                                    <Typography.Text type="secondary" style={{ marginLeft: '24px' }}>
                                        Download will not include sites marked as closed
                                    </Typography.Text>
                                </Space>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item name="columnsPreset">
                            <Radio.Group>
                                <Space direction="vertical" size={0}>
                                    <Typography.Text strong>Columns to Download</Typography.Text>
                                    <Radio value="detailed">
                                        <Typography.Text strong>Download All Sites Columns</Typography.Text>
                                    </Radio>
                                    <Typography.Text type="secondary" style={{ marginLeft: '24px' }}>
                                        This will increase load time
                                    </Typography.Text>

                                    <Radio value="default">
                                        <Typography.Text strong>Download Base Sites Columns</Typography.Text>
                                    </Radio>
                                    <Typography.Text type="secondary" style={{ marginLeft: '24px' }}>
                                        Download default sites list
                                    </Typography.Text>

                                    <Radio value="limited">
                                        <Typography.Text strong>Download Current View</Typography.Text>
                                    </Radio>
                                    <Typography.Text type="secondary" style={{ marginLeft: '24px' }}>
                                        Download columns shown in DRMS UI
                                    </Typography.Text>
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                    </>
                )}
            </Form>
        </Modal>
    );
};
