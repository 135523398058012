import Tooltip from 'antd/lib/tooltip';
import { ISite, PARTNER_ID_ERROR_STATUS } from '../../domain/site/interface';
import { PARTNER_ID_ERROR_STATUS_ICON } from '../../pages/sites/siteInterface';
import { keyBy } from 'lodash';
import { defaultDateFormat } from '../../domain/common/dateFormatters';
import { addDays, isAfter, max } from 'date-fns';

export const getLatestError = (errors: { error: string; received_date: string }[]) => {
    const errorMap = keyBy(errors, 'received_date');
    const errorsDate = Object.keys(errorMap).map(date => new Date(date));
    const closestDate = max(errorsDate).toISOString();
    return errorMap[closestDate];
};

export const checkWarningPeriod = (latestError: { error: string; received_date: string }, status: PARTNER_ID_ERROR_STATUS) => {
    if (status !== PARTNER_ID_ERROR_STATUS.WARNING) return latestError;
    const weekFromLastWarning = addDays(new Date(latestError.received_date), 7);

    return isAfter(weekFromLastWarning, new Date()) ? latestError : null;
};

export const PartnerIdStatus = ({ partner_id_status }: { partner_id_status: ISite['partner_id_status'] }) => {
    if (!partner_id_status) return null;

    const errors = partner_id_status?.errors;
    if (!errors?.length) return null;

    const latestError = checkWarningPeriod(getLatestError(errors), partner_id_status.status);

    return (
        latestError && (
            <Tooltip
                title={
                    <>
                        <p style={{ margin: 0 }}>{latestError.error}</p>
                        <p style={{ margin: 0 }}>{defaultDateFormat(latestError.received_date, '', 'MMM dd, yyyy HH:mm')}</p>
                    </>
                }
            >
                {PARTNER_ID_ERROR_STATUS_ICON[partner_id_status.status]}&nbsp;
            </Tooltip>
        )
    );
};
