import Form from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import Typography from 'antd/lib/typography';
import React, { useMemo } from 'react';
import App from 'antd/lib/app';
import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal/Modal';
import { closeSite } from '../../domain/site';
import { ISite } from '../../domain/site/interface';
import { useCompanies } from '../selectors/GlobalCompanySelector/CompanyContext';

interface ICloseSiteModalProps {
    site: ISite;
    onCancel: (site?: ISite) => void;
}

export interface ICloseSiteFormData {
    note: string;
}

export const CloseSiteModal: React.FC<ICloseSiteModalProps> = ({ site, onCancel }) => {
    const { notification } = App.useApp();
    const { companies = [] } = useCompanies()!;
    const [form] = Form.useForm<ICloseSiteFormData>();
    const [loading, setLoading] = React.useState<boolean>(false);

    const controlProviderName = useMemo(() => {
        if (!site.control_provider_id) return 'Control provider';

        const controlProviderCompany = companies.find(company => company.company_id === site.control_provider_id);

        return controlProviderCompany?.company_name;
    }, [companies, site.control_provider_id]);

    const handleConfirm = async (data: ICloseSiteFormData) => {
        setLoading(true);
        try {
            await closeSite(site.site_id, data);

            onCancel(site);
        } catch (error: any) {
            notification.error({ key: 'close-site-error', message: error.message || 'Cannot close site!' });
        }
        setLoading(false);
    };

    const handleClose = () => {
        onCancel();
    };

    return (
        <Modal
            title="Close Site"
            open
            destroyOnClose
            onCancel={handleClose}
            footer={[
                <Button key="enroll-program-modal-cancel" onClick={handleClose}>
                    Cancel
                </Button>,
                <Button key="enroll-program-modal-submit" type="primary" loading={loading} onClick={form.submit}>
                    Close Site
                </Button>,
            ]}
        >
            <Typography.Text>
                Are you sure you want to close site <strong>{site.site_name}</strong>? After closing a site it will no longer be able to receive
                events.
            </Typography.Text>

            {site.customer_ven && site.control_provider_id && (
                <Typography.Paragraph>
                    This site has a VEN connected. Please inform <strong>{controlProviderName}</strong>.
                </Typography.Paragraph>
            )}

            <Form form={form} name="close-site-form" preserve={false} layout="vertical" onFinish={handleConfirm} style={{ marginTop: '36px' }}>
                <Form.Item
                    name="note"
                    label={<Typography.Text strong>(optional) Comment or reason for closing</Typography.Text>}
                    rules={[{ max: 255, message: 'Number of characters should be less than 255' }]}
                >
                    <TextArea rows={4} placeholder="Closing comments (optional)" size="large" />
                </Form.Item>
            </Form>
        </Modal>
    );
};
