import { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import App from 'antd/lib/app';
import Typography from 'antd/lib/typography';
import Row from 'antd/lib/grid/row';
import Col from 'antd/lib/col';
import Divider from 'antd/lib/divider';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import Modal from 'antd/lib/modal';
import Form from 'antd/lib/form';
import Switch from 'antd/lib/switch';

import { updateUser } from '../../domain/user';
import { IMfaSetting, IConfirmationCode, IUser, MfaRequiredError, UserType } from '../../domain/user/interface';
import { disableMfa, enableMfa } from '../../domain/user/mfa';
import { RequiredConfirmationModal } from '../../components/modals/requiredConfirmationModal/requiredConfirmationModal';
import { WithControlledTooltip } from '../../components/withControlledTooltip';
import { NotificationsSettings } from '../../components/notificationsSettings/notificationsSettings';
import { ChangePasswordModal } from './changePasswordModal';
import './userProfile.css';

interface IUserProfile {
    onClose: any;
    user: IUser | undefined;
}

const ADMIN_ROLES = [UserType.ADMIN, UserType.ACCOUNT_MANAGER];

export const UserProfile = ({ onClose, user }: IUserProfile) => {
    const { notification } = App.useApp();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);

    const [userMfa, setUserMfa] = useState(user?.mfa_enabled);
    const [mfaSetting, setMfaSetting] = useState<IMfaSetting | Partial<IMfaSetting>>();
    const [mfaLoading, setMfaLoading] = useState<boolean>(false);
    const [changePassword, setChangePassword] = useState<boolean>(false);
    const enteredUserPhone = Form.useWatch('user_phone', form);
    const isAdminRole = user?.user_type && ADMIN_ROLES.includes(user?.user_type);
    const changePhoneDisabled = isAdminRole || userMfa;
    const changeMfaDisabled = !user?.user_phone || isAdminRole || enteredUserPhone !== user.user_phone;

    const closeUserModal = () => {
        const isMfaUpdated = userMfa !== user?.mfa_enabled;
        onClose(isMfaUpdated ? user : null);
    };
    const closeRequiredConfirmationModal = () => setMfaSetting({});

    const updateUserAction = async (updatedUser: IUser) => {
        try {
            setLoading(true);
            await updateUser(updatedUser);
            notification.info({ key: 'user-edit-info', message: 'User edited!' });
        } catch (err: any) {
            notification.error({ key: 'user-edit-error', message: err.message || 'Cannot edit user!' });
        }
        setLoading(false);
    };

    const onUserModalFormFinish = async (updatedUser: IUser) => {
        await updateUserAction({
            ...updatedUser,
            company_id: user!.company_id,
            mfa_enabled: userMfa!,
        });
        onClose(updatedUser);
    };

    const enableMfaAction = async (confirmationCode?: IConfirmationCode) => {
        await enableMfa(user!.user_id, confirmationCode);
        setUserMfa(true);
        setMfaSetting({});
    };

    const disableMfaAction = async (confirmationCode?: IConfirmationCode) => {
        await disableMfa(user!.user_id, confirmationCode);
        setUserMfa(false);
        setMfaSetting({});
    };

    const handleMfaSetting = async (confirmationCode?: IConfirmationCode) => {
        setMfaLoading(true);
        try {
            userMfa ? await disableMfaAction(confirmationCode) : await enableMfaAction(confirmationCode);
        } catch (error: any) {
            if (error instanceof MfaRequiredError) {
                setMfaSetting(error);
            } else {
                notification.error({
                    key: 'user-edit-error',
                    message: error?.message || 'Cannot update two-factor authentication!',
                });
            }
        }
        setMfaLoading(false);
    };

    const handleSubmitRequiredConfirmationModal = (confirmationCode?: IConfirmationCode) => {
        handleMfaSetting(confirmationCode);
        closeRequiredConfirmationModal();
    };

    const handleUpdateUuid = (uuid: string) => setMfaSetting({ ...mfaSetting, uuid: uuid });

    return (
        <Modal
            open
            destroyOnClose
            title="Edit profile"
            onCancel={closeUserModal}
            footer={[
                <Button key="cancel" onClick={closeUserModal}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" loading={loading} onClick={form.submit}>
                    Save
                </Button>,
            ]}
            className="user-profile"
        >
            <Form form={form} name="user-profile-form" preserve={false} layout="vertical" onFinish={onUserModalFormFinish} initialValues={user}>
                <Form.Item name="user_id" hidden>
                    <Input />
                </Form.Item>
                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item
                            name="user_fname"
                            label={<Typography.Text strong>First name</Typography.Text>}
                            rules={[
                                { required: true, message: 'First name is required!' },
                                { max: 20, message: 'Number of characters should be less than 20' },
                            ]}
                        >
                            <Input placeholder="John" size="large" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="user_lname"
                            label={<Typography.Text strong>Last name</Typography.Text>}
                            rules={[
                                { required: true, message: 'Last name is required!' },
                                { max: 20, message: 'Number of characters should be less than 20' },
                            ]}
                        >
                            <Input placeholder="Dow" size="large" />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    wrapperCol={{ span: 12 }}
                    name="user_phone"
                    tooltip={userMfa && !isAdminRole ? 'To change the phone number, you need to disable mfa first' : null}
                    label={<Typography.Text strong>Phone number</Typography.Text>}
                    rules={[
                        {
                            required: isAdminRole,
                            message: 'Please enter your phone!',
                        },
                        {
                            pattern: /^\+[1-9]\d{1,14}$/g,
                            message: 'Must be a number up to fifteen digits in length starting with a "+"',
                        },
                    ]}
                >
                    <Input disabled={changePhoneDisabled} placeholder="+19000000000" size="large" />
                </Form.Item>
                <Form.Item
                    name="user_email"
                    label={<Typography.Text strong>Email</Typography.Text>}
                    hasFeedback
                    rules={[{ required: true, message: 'Please enter your email!' }]}
                >
                    <Input placeholder="hello@example.com" size="large" disabled />
                </Form.Item>
                <Divider plain>Password</Divider>
                <Button type="primary" onClick={() => setChangePassword(true)}>
                    Change Password
                </Button>
                <NotificationsSettings />
                <Divider plain>2-Factor Authentication</Divider>
                <Row align="middle" justify="space-between" gutter={16}>
                    <Col span={18}>
                        <Typography.Paragraph>
                            A second step after entering your password verifies it's you signing in. With 2-Step Verification (also known as
                            two-factor authentication), you add an extra layer of security to your account in case your password is stolen.
                        </Typography.Paragraph>
                    </Col>
                    <Col>
                        <WithControlledTooltip
                            extraVisibleCondition={changeMfaDisabled}
                            title={
                                enteredUserPhone !== user?.user_phone
                                    ? 'Save the modified phone first'
                                    : !user?.user_phone
                                      ? 'Update your phone number first'
                                      : 'Admin group cannot disable mfa'
                            }
                        >
                            <Switch disabled={changeMfaDisabled} loading={mfaLoading} checked={userMfa} onChange={() => handleMfaSetting()} />
                        </WithControlledTooltip>
                    </Col>
                </Row>
            </Form>

            {!isEmpty(mfaSetting) && (
                <RequiredConfirmationModal
                    onCloseModal={closeRequiredConfirmationModal}
                    required={mfaSetting?.require || []}
                    onSubmit={handleSubmitRequiredConfirmationModal}
                    resendSettings={{
                        email: user?.user_email || '',
                        uuid: mfaSetting?.uuid || '',
                        infoMessage: mfaSetting?.infoMessage,
                        updateUuid: handleUpdateUuid,
                    }}
                    error={mfaSetting?.message}
                />
            )}
            {changePassword && <ChangePasswordModal onCloseModal={() => setChangePassword(false)} user={user} />}
        </Modal>
    );
};
